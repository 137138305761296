import { child, get, getDatabase, ref } from "firebase/database"
import moment from "moment"
import { useEffect, useMemo, useState } from "react"
import { useParams } from "react-router-dom"
import { ConnectionKind, ConnectionMap, EdgeInfo, TextPost } from "../../ReactContexts/PostContext"

const ConnectionAdminStats = () => {
  const { placeId } = useParams()
  const [allThoughts, setAllThoughts] = useState([])
  const [loadingThoughts, setLoadingThoughts] = useState("loading...")
  const [loadingPeople, setLoadingPeople] = useState("loading people...")

  useEffect(() => {
    const db = getDatabase()
    const domainRef = ref(db, "p/" + (placeId ?? "forum")) //nodes are posts

    const postsRef = child(domainRef, "nodes") //nodes are posts
    get(postsRef).then((snapshot) => {
      if (snapshot.exists()) {
        const value = snapshot.val()
        setAllThoughts(Object.values(value).reverse())
        setLoadingThoughts("loaded!")
      }
    })

    const peopleRef = child(domainRef, "people") //nodes are posts
    get(peopleRef).then((snapshot) => {
      if (snapshot.exists()) {
        setLoadingPeople("loaded people!")
      }
    })
  }, [placeId])

  const allConnections = useMemo(() => {
    return getAllPosConnectionsFromThoughtArr(allThoughts)
  }, [allThoughts])

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <button style={{ width: "fit-content", margin: "1em" }}>
        find and store suggestions for past thoughts (inactive)
      </button>

      <div>{loadingThoughts}</div>
      <div>{loadingPeople}</div>
      <hr></hr>
      {/* {allThoughts.map((e) => (
        <div>{e.text}</div>
      ))} */}
      <h3> {allConnections?.length} CONNECTION Connections </h3>
      {allConnections.map((e) => (
        <div>
          <div>{moment(e.timestamp).fromNow()}</div>
          <div>authorId: {e.authorId}</div>
          <div>sourceThoughtId: {e.sourceId}</div>
          <div>type: {e.edgeKind}</div>

          <hr></hr>
        </div>
      ))}
      <hr></hr>
    </div>
  )
}

export default ConnectionAdminStats

export const getAllPosConnectionsFromThoughtArr = (thoughtArr: TextPost[]): EdgeInfo[] => {
  let connections: EdgeInfo[] = []
  thoughtArr.forEach((thought: TextPost) => {
    Object.entries(thought.connections?.inbound ?? {}).forEach(
      ([_otherThoughtId, map]: [string, ConnectionMap]) => {
        //each of these connections, add it
        const edges = Object.values(map)
        const posConnectEdges = edges.filter(
          (e) => e.edgeKind === ConnectionKind.CONNECTION && !("anti" in e)
        )
        if (posConnectEdges.length > 0) {
          connections.push(posConnectEdges[0])
        }
      }
    )
  })
  const sorted = connections.sort((a, b) => b.timestamp - a.timestamp)

  //filtered by time window

  return sorted
}

/**
 * Function for getting all the connections in the network, given an array of all the thoughts
 * @param allThoughts
 * @param startTime //@Micah for you to fill these in, I figured it could be an number represending seconds since epoch, but figured you might prefer a different format, too.
 * @param endTime //@Micah for you to fill these in, I figured it could be an number represending seconds since epoch, but figured you might prefer a different format, too.
 * @returns
 */
export const getAllConnectionsInTimeWindow = (
  allThoughts: TextPost[],
  startTime: number,
  endTime: number
) => {
  const allConnections = getAllPosConnectionsFromThoughtArr(allThoughts)
  const windowedConnections = filterConnectionsByTimeWindow(allConnections, startTime, endTime)
  return windowedConnections
}

/**
 * @param edges
 * @param startTime
 * @param endTime
 * @returns
 */
export function filterConnectionsByTimeWindow(
  edges: EdgeInfo[],
  startTime: number,
  endTime: number
): EdgeInfo[] {
  return edges.filter((edge) => {
    //check whether timestamp is in desired window.
    return edge.timestamp < endTime && edge.timestamp > startTime
  })
}
