import { useContext, useEffect, useState } from "react"
import { Descendant } from "slate"
import "./ExpandablePost.css"
import RelatedThoughtsSectionInPost from "./RelatedThoughtsSectionInPost/RelatedThoughtsSectionInPost"
import FeedItem from "./FeedItem"
import { ConnectionKind, EdgeInfo, TextPost } from "../../../ReactContexts/PostContext"
import SimpleAppPostContext from "../SimpleAppPostContext"
import { findDirectedEdgeBetweenPosts, getUniqueThoughts } from "../../../Logic/ConnectionLogic"
import FirebaseWriter from "../../../Firebase/FirebaseWriter"
import { useParams, useSearchParams } from "react-router-dom"
import ReplyThoughtsSectionInPost from "./ReplyThoughtsSectionInPost/ReplyThoughtsSectionInPost"
import AuthContext from "../../../ReactContexts/AuthContext"
import { Play } from "@phosphor-icons/react"

const ExpandablePost = ({
  post,
  id,
  text,
  timestamp,
  fullPage,
}: {
  post: TextPost
  id: string
  text: string
  slateValue: Descendant[] | undefined
  timestamp: number
  fullPage?: true
}) => {
  const { person } = useContext(AuthContext)
  // TODO: Davey you can pick up from here. FirebaseWriter needs to be initialized
  // for fastest shortcut to our logic, suggestions, post cache storage, etc. and otherwise
  const [expanded, setExpanded] = useState(fullPage ? true : false)
  const { thoughtIdToExpand, setThoughtIdToExpand } = useContext(SimpleAppPostContext)
  const { thoughtId } = useParams()
  const [updates, setUpdates] = useState(0)
  const [isNewThought, setIsNewThought] = useState<boolean>(false)

  //set expanded if it's a new thought
  useEffect(() => {
    if (thoughtIdToExpand === post.id) {
      setExpanded(true)
      setThoughtIdToExpand(post.id)
    }
  }, [thoughtIdToExpand])

  // These are related thoughts
  const [suggestedPosts, setSuggestedPosts] = useState([])
  //these are reply thought
  const [replyPosts, setReplyPosts] = useState<TextPost[]>([])

  const [justClickedPodcastButton, setJustClickedPodcastButton] = useState<boolean>(false)

  //query for replies, use a different use effect that queries more often than ^, cuz we want new replies to render immediately
  useEffect(() => {
    //set the reply thought ids
    const inboundReplyConnections: EdgeInfo[] = Object.values(post?.connections?.inbound || {})
      .flatMap(Object.values)
      .filter((obj) => obj.edgeKind === ConnectionKind.REPLY)
    //reply edges point from the reply thought to the prompt thought
    const inboundReplyIds = inboundReplyConnections.map((conn) => conn?.sourceId)
    FirebaseWriter.queryByIds(inboundReplyIds).then((result) => {
      const uniques = getUniqueThoughts(result)

      const sorter = (a: TextPost, b: TextPost) => {
        //see which has a more recent connection with post
        const edgeA = findDirectedEdgeBetweenPosts(a, post, ConnectionKind.REPLY)
        const edgeB = findDirectedEdgeBetweenPosts(b, post, ConnectionKind.CONNECTION)
        const aTime = edgeA?.timestamp ?? 0
        const bTime = edgeB?.timestamp ?? 0
        return bTime - aTime
      }
      const sortedCorrectly = uniques.sort(sorter)
      setReplyPosts(sortedCorrectly)
    })
  }, [post?.connections?.inbound])

  const podcastButtonEnabled =
    suggestedPosts?.length > 0 &&
    !justClickedPodcastButton &&
    post.authorId === person?.uid &&
    (isNewThought || updates > 0)

  const podcastButtonClickHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    const relatedThoughtsParent = event.currentTarget.parentElement
    const relatedThought = relatedThoughtsParent?.querySelector("div.textPost")
    const relatedThoughtPlayButton: HTMLButtonElement =
      relatedThought?.querySelector(".play-button")
    if (relatedThoughtPlayButton) {
      relatedThoughtPlayButton.click()
      setJustClickedPodcastButton(true)
    }
  }

  return (
    <>
      <div
        className={
          "expandable-feed-item-container " +
          (expanded ? "expanded" : "") +
          (fullPage ? " fullPage" : "")
        }
        key={post?.id + "-" + (thoughtId ?? "") + "related-thoughts"}
      >
        <FeedItem
          {...{
            id,
            text,
            topLevel: "topLevel",
            expanded,
            setExpanded,
            timestamp,
            depth: 0,
            authorName: post.authorName,
            audioUrl: post?.audioUrl,
            post: post,
            updates,
          }}
        />

        {expanded ? (
          <div className="associated-thoughts-section-in-post">
            {/* to make sure there are thoughts to play */}
            {suggestedPosts.length > 0 ? (
              <button
                className={"textPost podcast-button" + (podcastButtonEnabled ? " " : " disabled")}
                onClick={podcastButtonClickHandler}
              >
                <Play weight="fill" size={20}></Play>&nbsp; Play Related Voices
              </button>
            ) : (
              <></>
            )}

            {/* <div style={{ opacity: podcastButtonEnabled ? ".2" : "1" }}> */}
            {/* <ReplyThoughtsSectionInPost {...{ parentPost: post, replyPosts, setUpdates }} /> */}
            <RelatedThoughtsSectionInPost
              {...{
                expanded,
                suggestedPosts,
                setSuggestedPosts,
                post,
                fullPage,
                setUpdates,
                setIsNewThought,
              }}
            />
            {/* </div> */}
          </div>
        ) : (
          <></>
        )}
      </div>
      {/* if full page, there's nothing to block */}
      {expanded && !fullPage ? (
        <div className="expandable-background-blocker" onClick={() => setExpanded(false)}></div>
      ) : (
        <></>
      )}
    </>
  )
}

export default ExpandablePost
