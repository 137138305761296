import React, { useContext, useEffect, useState } from "react"
import plexusAnimationFolding from "../../assets/plexusAnimateClosing.gif"
import { Navigate, useLocation, useNavigate, useParams } from "react-router-dom" //useNavigate, useParams
import "./LoginPage.css"

//auth 1
import AuthContext from "../../ReactContexts/AuthContext"
import { logEvent } from "firebase/analytics"
import { analytics, auth, provider } from "../App"
import { sendSignInLinkToEmail, signInWithEmailLink, signInWithPopup } from "firebase/auth"
import VoicesOrientation from "../VoicesOrientation/VoicesOrientation"

const LoginPage = () => {
  const { emailURLEncodedParams } = useParams()
  const navigate = useNavigate()
  const url = useLocation()
  const { person } = useContext(AuthContext)
  const { placeId } = useParams()
  const [email, setEmail] = useState("")
  const [isOrienting, setIsOrienting] = useState(true)
  const [emailSent, setEmailSent] = useState(false)
  const [isSignInError, setIsSignInError] = useState(false)
  const checkIfRedirectedFromEmail = () => window.location.href.search("apiKey") > -1
  const [isSigningIn, setIsSigningIn] = useState(checkIfRedirectedFromEmail())
  //If on the home page
  if (checkIfRedirectedFromEmail()) {
    //check if redirected from email
    const emailURL = decodeURI(emailURLEncodedParams)
    if (emailURL) {
      console.log(`emailURL: ${emailURL}`)
      //sign in with email
      signInWithEmailLink(auth, emailURL, window.location.href)
        .then((result) => {
          handleSuccessSignIn(result)
        })
        .catch((error) => {
          console.log(error)
          setIsSignInError(true)
        })
    }
  }

  const handleSuccessSignIn = (val) => {
    //signed in
    const person = val?.user
    logEvent(analytics, "testinvite-page-sign-in", {
      timestamp: Date.now(),
      personEmail: person?.email,
      personGoogleDisplayName: person?.displayName,
      personId: person?.uid,
      person: person,
      inviteCode: "test",
    })

    navigate("/p/" + (placeId ?? "forum") + "/authenticating")
  }

  const googleLogin = () => {
    signInWithPopup(auth, provider).then(handleSuccessSignIn)
  }

  const emailLogin = () => {
    if (email && email.includes("@") && email.includes(".")) {
      setEmailSent(true)
      window.localStorage.setItem("emailForSignIn", email)

      const actionCodeSettings = {
        url: `${window.location.origin}/${encodeURI(email)}/login`,
        handleCodeInApp: true,
      }

      sendSignInLinkToEmail(auth, email, actionCodeSettings)
    } else {
      window.alert("Please input a valid email!")
    }
  }

  useEffect(() => {
    if (person) {
      navigate("/")
    }
  }, [person])

  return (
    <div>
      {isSignInError && (
        <div>
          The link has expired. Please try <a href="www.plexus.earth"> logging in again. </a>{" "}
        </div>
      )}
      {isOrienting && (
        <>
          <VoicesOrientation
            onCompletion={() => {
              setIsOrienting(false)
            }}
          />
        </>
      )}
      {!isOrienting && !isSignInError && (
        <div className="login-container">
          {/* <img
            src={plexusAnimationFolding}
            style={{ maxWidth: "50vw", width: "100pt", marginTop: "-20%", marginBottom: "5%" }}
          /> */}
          {/* <p>Express yourself</p> */}

          {isSigningIn && <div className="signing-in">Signing in...</div>}
          {!isSigningIn && (
            <div className="login-form">
              {!emailSent && (
                <>
                  {/*@ts-ignore */}
                  {!window.navigator.standalone && (
                    <>
                      <input
                        type="text"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        autoFocus
                        placeholder="Enter your email"
                        className="login-input"
                        onKeyDown={(e) => {
                          if (e?.key === "Enter") emailLogin()
                        }}
                      />
                      <button onClick={emailLogin} className="login-button email">
                        Login with Email
                      </button>
                    </>
                  )}
                  <br></br>
                  {/* @ts-ignore */}
                  {/* {navigator.standalone ? ( */}
                  <button onClick={googleLogin} className="login-button google">
                    Login with Google
                  </button>
                </>
              )}

              {emailSent && (
                <p>"Email sent. Check your email (and spam folder) for verification link."</p>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default LoginPage
