const examples: { text: string }[] = [
  {
    text: "I read an essay that challenged Dunbar's number. Whether or not ~150 is the threshold for human community size, I like their more nuanced conclusion: we have finite surface area for social connection.",
  },
  {
    text: 'I want to explore the feelings computers can evoke, on the fringes of mainstream software design. Haven\'t found many people pursuing truly "organic tech."',
  },
  {
    text: "I'm a community-builder, a yoga teacher, an oil painter in-training, and an aspiring fiction writer. I'm wrestling with my identity as someone who doesn't have strictly one job. ",
  },
]
const ExampleThoughts = ({ i }: { i: number }) => {
  return <div className="example-thought">{examples[i]?.text}</div>
}

export default ExampleThoughts
