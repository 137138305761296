import { TextPost } from "./ReactContexts/PostContext"
import { ConnectionUpdatesForAPerson } from "./ReactContexts/PostContext"

export const abbreviate = (text: string, maxChars: number) =>
  text?.length > maxChars ? text.slice(0, maxChars) + "..." : text

export function getTwitterLikeAbbreviation(date: Date) {
  const now = new Date()
  const seconds = Math.floor((+now - +date) / 1000)
  const minutes = Math.floor(seconds / 60)
  const hours = Math.floor(minutes / 60)
  const days = Math.floor(hours / 24)

  if (seconds < 60) {
    return "Just now"
  } else if (minutes < 60) {
    return `${minutes}m`
  } else if (hours < 24) {
    return `${hours}h`
  } else if (days < 7) {
    return `${days}d`
  } else {
    // Check if the year of the date is different from the current year
    if (date.getFullYear() !== now.getFullYear()) {
      // If different, format the date as "MMM d, yyyy"
      return date.toLocaleDateString("en-US", { month: "short", day: "numeric", year: "numeric" })
    } else {
      // If the same, format the date as "MMM d"
      return date.toLocaleDateString("en-US", { month: "short", day: "numeric" })
    }
  }
}

export const blobToBase64 = (blob: Blob) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onerror = reject
    reader.onload = () => {
      resolve(reader.result)
    }
    reader.readAsDataURL(blob)
  })
}

export const base64ToBlob = (base64) => {
  let byteString
  if (base64.split(",")[0].indexOf("base64") >= 0) {
    byteString = atob(base64.split(",")[1])
  } else {
    byteString = unescape(base64.split(",")[1])
  }
  const mimeString = base64.split(",")[0].split(":")[1].split(";")[0]
  let content = []
  for (let i = 0; i < byteString.length; i++) {
    content.push(byteString.charCodeAt(i))
  }
  return new Blob([new Uint8Array(content)], { type: mimeString })
}

export const isAuthorOnConnectionOfType = (
  authorIdToCheck: string,
  post: TextPost,
  inboundOrOutbound: "inbound" | "outbound"
) => {
  if (!post || !post.connections) return false

  const connectionsOfType = post.connections[inboundOrOutbound]
  if (!connectionsOfType) return false

  // Iterate through the connections of the specified type
  return Object.values(connectionsOfType).some((connection) =>
    Object.values(connection).some((edge) => edge.authorId === authorIdToCheck)
  )
}

export const isAuthorConnectedWithPost = (
  personBucketConnections: ConnectionUpdatesForAPerson,
  postId: string
) => {
  return Object.values(personBucketConnections ?? {}).some(
    (connection) => connection?.targetThoughtId === postId
  )
}

export const isSafariMobile = () => {
  const userAgent = window.navigator.userAgent
  const isIOS = /iPad|iPhone|iPod/.test(userAgent)
  const isSafari = /^((?!chrome|android).)*safari/i.test(userAgent)
  return isIOS && isSafari
}

export const isStandaloneMode = () => {
  //@ts-ignore
  return "standalone" in window.navigator && window.navigator.standalone
}

export const isEmbeddedBrowser = () => {
  // Checks for iOS embedded browsers
  const userAgent = window.navigator.userAgent.toLowerCase()
  const isIOS = /iphone|ipad|ipod/.test(userAgent)
  const isStandalone = isStandaloneMode()
  const isSafari = /safari/.test(userAgent)
  const isInAppBrowser = isIOS && !isStandalone

  return isInAppBrowser
}
export const countNumberOfConnectionsFromPost = (sourcePost: TextPost, targetPost: TextPost) => {
  if (!sourcePost || !sourcePost.connections) return 0
  const outboundConnections = sourcePost?.connections?.outbound
  if (!outboundConnections) return 0
  let count = 0
  for (const [key, value] of Object.entries(outboundConnections)) {
    if (outboundConnections[key][Object.keys(value)[0]].targetThoughtId === targetPost?.id) {
      count++
    }
  }
  return count
}

// export const base64ToBlob = (base64) => {
//   let byteString
//   if (base64.split(",")[0].indexOf("base64") >= 0) {
//     byteString = atob(base64.split(",")[1])
//   } else {
//     byteString = unescape(base64.split(",")[1])
//   }
//   const mimeString = base64.split(",")[0].split(":")[1].split(";")[0]
//   let content = []
//   for (let i = 0; i < byteString.length; i++) {
//     content.push(byteString.charCodeAt(i))
//   }
//   return new Blob([new Uint8Array(content)], { type: mimeString })
// }
