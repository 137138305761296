import { child, getDatabase, onValue } from "firebase/database"
import { useState, useEffect } from "react"
import FirebaseWriter from "../../Firebase/FirebaseWriter"

function usePostTitle(backendWriter: FirebaseWriter, postId: string, providedTitle?: string) {
  const [title, setTitle] = useState<string>(providedTitle)

  useEffect(() => {
    const dbRef = backendWriter?.databaseRef
    if (!dbRef) return
    const postTitleRef = child(dbRef, `nodes/${postId}/title`)

    const unsub = onValue(postTitleRef, (snapshot) => {
      if (snapshot.exists()) {
        setTitle(snapshot.val())
      }
    })

    // Cleanup function
    return unsub
  }, [postId]) // Empty dependency array means this effect runs once on mount and cleanup on unmount

  return title
}

export default usePostTitle
