import { Dispatch, KeyboardEventHandler, SetStateAction, useMemo, useState } from "react"
import { Descendant, Editor, Node, Point, Text, Transforms } from "slate"
import { Slate } from "slate-react"
import { Editable } from "slate-react"
import { dict } from "./database"
import "./EditorContainer.css"

const initialValue: Descendant[] = [
  {
    type: "paragraph",
    children: [{ text: "" }],
  },
]
const EditorContainer = ({
  editor,
  handleEnter,
  setIsEmpty,
}: {
  editor: Editor
  handleEnter: Function
  setIsEmpty: Dispatch<SetStateAction<boolean>>
}) => {
  const [value, setValue] = useState(initialValue)
  const handleChange = (children: Descendant[]) => {
    const string = Node.string(editor)
    const words = string.split(" ")
    if (children.length > 0 && Node.string(children[0]).length > 0) {
      setIsEmpty(false)
    } else {
      setIsEmpty(true)
    }
  }
  const decorate = ([node, path]: any): any => {
    const search = " "
    const ranges: { anchor: Point; focus: Point; highlight: boolean; id: string }[] = []

    if (search && Text.isText(node)) {
      const { text } = node
      const words = text.split(search)
      let offset = 0
      words.forEach((part) => {
        ranges.push({
          anchor: { path, offset },
          focus: { path, offset: offset + part.length },
          highlight: part.toLocaleLowerCase() in dict,
          id: "hello",
        })
        offset = offset + part.length + search.length
      })
    }

    //return ranges
    return []
  }
  const renderLeaf = (props: any) => {
    return <Leaf {...props} />
  }
  const handleKeyDown: KeyboardEventHandler = (e: any) => {
    if (e.key.toLocaleLowerCase() === "enter" && e.metaKey) {
      e.preventDefault()
      handleEnter()
      Transforms.delete(editor, {
        at: {
          anchor: Editor.start(editor, []),
          focus: Editor.end(editor, []),
        },
      })
    }
  }
  return (
    <div className="editor-container">
      <Slate editor={editor} value={value} onChange={handleChange}>
        <Editable
          decorate={decorate}
          renderLeaf={renderLeaf}
          onKeyDown={handleKeyDown}
          placeholder="Express anything..."
          autoFocus={true}
        />
      </Slate>
    </div>
  )
}

const Leaf = ({ attributes, children, leaf }: any) => {
  return (
    <span
      {...attributes}
      {...(leaf.highlight && { "data-cy": "search-highlighted" })}
      style={{
        color: leaf.highlight && "black",
      }}
      onClick={() => {
        if (leaf.highlight) {
          window.alert("hello highlighted")
        }
      }}
    >
      {children}
    </span>
  )
}

export default EditorContainer
