import { child, get, onValue } from "firebase/database"
import { useState, useEffect } from "react"
import FirebaseWriter from "../../../Firebase/FirebaseWriter"

function useTotalThoughts(backendWriter: FirebaseWriter, personId: string) {
  const [thoughtCount, setThoughtCount] = useState<number>(0)

  useEffect(() => {
    const dbRef = backendWriter?.databaseRef
    if (!dbRef) return
    const thoughtCountRef = child(dbRef, `people/${personId}/thoughtCount`)
    get(thoughtCountRef).then((snapshot) => {
      if (snapshot.exists()) {
        setThoughtCount(snapshot.val())
      }
    })
  }, [])

  return thoughtCount
}

export default useTotalThoughts
