import { Dispatch, SetStateAction, useContext, useEffect, useMemo, useState } from "react"
import { getEdgeAuthor, getUniqueThoughts } from "../../../../Logic/ConnectionLogic"
import { ConnectionKind, TextPost } from "../../../../ReactContexts/PostContext"
import SimpleAppPostContext from "../../SimpleAppPostContext"
import ExpandedRelatedThoughts from "./ExpandedRelatedThoughts"
import { useParams } from "react-router-dom"
import AuthContext from "../../../../ReactContexts/AuthContext"
import { postIsListenedTo } from "../../../CustomReactHooks/usePostIsListened"
import { getFullEdgeMapArr } from "../../../../Firebase/ReplyUtilities"
import FirebaseWriter from "../../../../Firebase/FirebaseWriter"
import { getSuggestionsAndAddRelatedEdges } from "../../../Feed/GetSuggestedThoughts"
import { useAudioPlayer } from "../../AudioPlayerProvider"

const RelatedThoughtsSectionInPost = ({
  expanded,
  suggestedPosts,
  post,
  setSuggestedPosts,
  fullPage,
  setUpdates,
  setIsNewThought,
}: {
  expanded: boolean
  suggestedPosts: TextPost[]
  setSuggestedPosts: Dispatch<SetStateAction<TextPost[]>>
  post: TextPost
  fullPage?: boolean //if we're showing a full page thought
  setUpdates: Dispatch<SetStateAction<number>>,
  setIsNewThought: Dispatch<SetStateAction<boolean>>
}) => {
  const { newThoughtEmbeddingsLoading } = useContext(SimpleAppPostContext)
  const { listenedPosts } = useAudioPlayer()
  const { person } = useContext(AuthContext)
  const { thoughtId } = useParams()
  const [inboundIds, setInboundIds] = useState<string[]>([])

  useEffect(() => {
    if (expanded) {
      const relatedOutboundIds = getFullEdgeMapArr(
          post?.connections?.outbound ?? {},
          ConnectionKind.RELATED
        )
        // We put old outbounds first to maintain order of relatedness returned by Pinecone
        // See GetSuggestedThoughts.ts#getSuggestionsAndAddRelatedEdges
        .sort((a, b) => a.timestamp - b.timestamp)
        .map((e) => e.targetThoughtId)

      const relatedInbound = getFullEdgeMapArr(
          post?.connections?.inbound ?? {},
          ConnectionKind.RELATED
        )
      const replyInbound = getFullEdgeMapArr(
          post?.connections?.inbound ?? {},
          ConnectionKind.REPLY
        )
      const inboundIds = [...relatedInbound, ...replyInbound]
        .sort((a, b) => b.timestamp - a.timestamp)
        .map((e) => e.sourceId)
      setInboundIds(inboundIds)
  
      // If this thought hasn't generated any RELATED edges yet, do so
      if (relatedOutboundIds.length > 0) {
        // We put inbounds first as those are the most recently created edges after the post was created
        const relatedIds = [...inboundIds, ...relatedOutboundIds]

        FirebaseWriter.queryByIds(relatedIds)
          .then((relatedThoughts) => {
            setSuggestedPosts(relatedThoughts)
          })
      } else {
        // In case we are generating new Related edges, also fetch replies and inbound related
        Promise.all([
          FirebaseWriter.queryByIds(inboundIds),
          getSuggestionsAndAddRelatedEdges(post)
        ]).then(([inboundThoughts, relatedThoughts]) => {
          setSuggestedPosts([...inboundThoughts, ...relatedThoughts])
          setIsNewThought(true)
        })
      }
    }
  }, [expanded, post.id, newThoughtEmbeddingsLoading])

  const getListenEdgeFromPost = (post: TextPost, personId: string) => {
    const listenEdge = getFullEdgeMapArr(
      post?.connections?.inbound ?? {},
      ConnectionKind.CONNECTION
    ).find((e) => getEdgeAuthor(e) === personId)
    return listenEdge
  }

  const postListenTimestampComparator =
    (personId: string) =>
    (postA: TextPost, postB: TextPost): number => {
      const [edgeA, edgeB] = [postA, postB].map((post) => getListenEdgeFromPost(post, personId))
      const [timestampA, timestampB] = [edgeA, edgeB].map((edge) => edge?.timestamp ?? 0)
      return timestampB - timestampA
    }

  const combinedThoughts = useMemo(() => {
    const uniqueThoughts = getUniqueThoughts([...suggestedPosts])

    const comparator = postListenTimestampComparator(person?.uid)
    const listened = uniqueThoughts
      .filter((x) =>
        postIsListenedTo(x?.connections?.inbound, person?.uid, x.audioUrl, x.authorId, x.title)
      )
      .sort(comparator)

    //seems like top unlistened posts here appear listened by usePostIsListened
    const unlistened = uniqueThoughts
      .filter(
        (e) =>
          !postIsListenedTo(e?.connections?.inbound, person?.uid, e.audioUrl, e.authorId, e?.title)
      )
      .sort(comparator)

    let prioritizied = [...unlistened, ...listened]

    const withoutLineage = prioritizied.filter(
      (relatedPost) => !post.lineage?.find((x) => x.id === relatedPost.id)
    )
    return withoutLineage
  }, [suggestedPosts])

  useEffect(() => {
    if (person?.uid !== post.authorId) return
    // Calculates number of inbound related thoughts that haven't been listened to yet
    const unlistenedThoughts = combinedThoughts.filter((thought) => {
      return inboundIds.includes(thought.id) &&
        !postIsListenedTo(
          thought?.connections?.inbound,
          person?.uid,
          thought.audioUrl,
          thought.authorId
        ) &&
        !listenedPosts?.has(thought.id)
    })
    setUpdates(unlistenedThoughts.length)
  }, [combinedThoughts, inboundIds, listenedPosts])

  return (
    <div className="related-thoughts-section-in-post" key={"related-posts-for-" + thoughtId}>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {!expanded ? (
          <></>
        ) : (
          <ExpandedRelatedThoughts
            {...{
              combinedThoughts,
              post,
              expanded,
              disableConnect: (fullPage ?? undefined) as true,
            }}
          />
        )}
      </div>
    </div>
  )
}

export default RelatedThoughtsSectionInPost
