import { useState, useEffect } from "react"
import { getReplyThoughtIdsFromParent } from "../../Firebase/ReplyUtilities"
import { TextPost } from "../../ReactContexts/PostContext"

function useReplyThoughtIds(thought: TextPost) {
  const [replyIds, setReplyIds] = useState<string[]>([])

  useEffect(() => {
    if (!thought) return undefined
    const newIds = getReplyThoughtIdsFromParent(thought)
    setReplyIds(newIds)
  }, [thought?.connections?.inbound])

  return { replyIds, setReplyIds }
}

export default useReplyThoughtIds
