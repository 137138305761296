import { useEffect } from "react"
import { useNavigate, useParams } from "react-router-dom"
import loading from "../../assets/plexusLoader2.gif"
import { User } from "firebase/auth"

const Authenticating = ({ person }: { person: User }) => {
  const navigate = useNavigate()
  const { placeId } = useParams()

  useEffect(() => {
    if (person) {
      // Get pre-auth URL
      const preAuthURL = sessionStorage.getItem("preAuthURL")

      // If a pre-auth URL was stored, navigate to that URL
      if (preAuthURL) {
        window.location.assign(preAuthURL)
      } else {
        // If no pre-auth URL was stored, navigate to a default location
        navigate("/p/" + (placeId ?? ""), { replace: true })
      }

      // Clear the stored URL
      sessionStorage.removeItem("preAuthURL")
    }
  }, [person, navigate, placeId])

  return person ? null : (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100vw",
      }}
    >
      <img alt="loader" style={{ width: "60px", margin: "4em 1em" }} src={loading}></img>
      waking up...
    </div>
  )
}

export default Authenticating
