import { useContext, useEffect, useMemo, useRef } from "react"
import { TextPost } from "../../../../ReactContexts/PostContext"
import FeedItem from "../FeedItem"
import { isAuthorConnectedWithPost } from "../../../../util"
import SimpleAppPostContext from "../../SimpleAppPostContext"
import { filterArrayToJustHaveUniqueItems } from "../PostList"
import { useAudioPlayer } from "../../AudioPlayerProvider"

/**
 * this is the entire section that shows up when a thought is focused, underneath that thought's particular feed itme
 *
 * (ie all replies, related thoughts, etc)
 * @param param0
 * @returns
 */

const ExpandedRelatedThoughts = ({
  combinedThoughts,
  post,
}: {
  combinedThoughts: TextPost[]
  post: TextPost
  disableConnect?: true
}) => {
  const { personBucket } = useContext(SimpleAppPostContext)
  const { currentlyPlayingId, stopAudio } = useAudioPlayer()
  const audioRef = useRef(new Audio())
  const sortedPosts = useMemo(() => {
    return filterArrayToJustHaveUniqueItems(combinedThoughts, (a, b) => a?.id === b?.id)
  }, [combinedThoughts])

  // Requires currentlyPlayingId and sortedPosts in dependency array so handler has latest values
  useEffect(() => {
    const audio = audioRef.current
    if (audio && sortedPosts.length > 0) {
      audio.addEventListener("ended", handleAudioEnd)

      return () => {
        audio.removeEventListener("ended", handleAudioEnd)
      }
    }
  }, [sortedPosts, currentlyPlayingId, stopAudio])

  const handleAudioEnd = () => {
    const allFeedItems = document.querySelectorAll(".sorted-posts-container > .textPost")
    const currentlyPlayingIndex = sortedPosts.findIndex((p) => p.id === currentlyPlayingId)
    const nextFeedItem = allFeedItems[currentlyPlayingIndex + 1]
    const nextFeedItemPlayButton: HTMLButtonElement = nextFeedItem?.querySelector(".play-button")
    if (nextFeedItemPlayButton) {
      nextFeedItem.scrollIntoView({ behavior: "smooth", block: "center" })
      nextFeedItemPlayButton.click()
    } else {
      stopAudio()
    }
  }

  return (
    <div
      className="sorted-posts-container-outer"
      style={{
        width: "auto",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {sortedPosts.length == 0 ? (
        <div className="expanded-related-thoughts-header">loading related Voices...</div>
      ) : (
        ""
      )}

      {sortedPosts.length > 0 ? (
        <div className="sorted-posts-container">
          {sortedPosts.map((suggestedPost) => {
            if (!suggestedPost) return <></>
            return (
              <FeedItem
                key={suggestedPost.id}
                {...{
                  id: suggestedPost.id,
                  text: suggestedPost.text,
                  timestamp: suggestedPost.timestamp,
                  authorName: suggestedPost?.authorName,
                  fromPost: post,
                  post: suggestedPost,
                  audioUrl: suggestedPost.audioUrl,
                  alreadyConnected: isAuthorConnectedWithPost(
                    personBucket?.connections?.outbound,
                    suggestedPost?.id
                  ),
                  audioRef,
                }}
              />
            )
          })}
        </div>
      ) : (
        <></>
      )}
    </div>
  )
}

export default ExpandedRelatedThoughts
