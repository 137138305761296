import { onValue, ref } from "firebase/database"
import { initializeApp } from "firebase/app"
import firebaseConfig from "../../../Firebase/FirebaseConfig"
import { getDatabase } from "firebase/database"
import { useContext, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import AdminStatistics from "../components/AdminMatchesMadeViewer"
import "./AdminPanel.css"
import AuthContext from "../../../ReactContexts/AuthContext"
import AdminSendEmails from "./AdminSendEmails"

// Loading down data from firebase
// @ts-ignore
const app = initializeApp(firebaseConfig)
const db = getDatabase()

// Initializes main admin page
const AdminPanel = () => {
  const { placeId } = useParams()
  const { person } = useContext(AuthContext)
  const [allThoughts, setAllThoughts] = useState([])
  const [conversations, setConversations] = useState([])
  const [viewer, setViewer] = useState<string>("WalkStatistics")

  // Loads data after placeId is identified
  useEffect(() => {
    const domainId = placeId ?? "forum"
    const postsRef = ref(db, "p/" + domainId + "/nodes") //nodes are posts
    const conversationRef = ref(db, "p/" + domainId + "/conversations")
    onValue(postsRef, (snapshot) => {
      if (snapshot.exists()) {
        const value = snapshot.val()
        // Convert to array
        setAllThoughts(Object.values(value).reverse())
      }
    })
    onValue(conversationRef, (snapshot) => {
      if (snapshot.exists()) {
        const value = snapshot.val()
        setConversations(Object.values(value).reverse())
      }
    })
  }, [placeId])
  if (!person?.email?.includes("@plexus")) {
    return <div>permission denied</div>
  }
  return (
    <div className="privateAdminContainer">
      {/* Nav bar, to choose what kind of p[anel] */}
      <div className="privateAdminContainerNavbar" style={{ display: "inline-flex" }}>
        <span onClick={() => setViewer("Statistics")}>Main Stats</span>
        <span onClick={() => setViewer("AdminSendEmails")}>Email Sender</span>
      </div>

      {viewer === "Statistics" ? (
        <AdminStatistics conversationsAndThoughts={[conversations, allThoughts]} />
      ) : (
        ""
      )}
      {viewer === "AdminSendEmails" ? <AdminSendEmails /> : ""}
    </div>
  )
}

export default AdminPanel
