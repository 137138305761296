import { Dispatch, SetStateAction, useContext, useState } from "react"
import { createEditor, Descendant, Editor, Transforms } from "slate"
import { withHistory } from "slate-history"
import { withReact } from "slate-react"
import SimpleAppPostContext from "../SimpleAppPostContext"
import EditorContainer from "../EditorContainer"
import { getSlateEditorString } from "../SlateEditorString"
import PostButton from "./PostButton"
import "./Create.css"
import { simpleBackendWriter } from "../SimpleApp"
import AudioRecorder from "../../Editor/AudioRecorder"
import { TextPost } from "../../../ReactContexts/PostContext"
import { useNavigate } from "react-router-dom"

export type AppendsPost = (transcription: string, audioUrl: string) => Promise<any>
const Create = () => {
  const {
    editorIsEmpty,
    setEditorIsEmpty,
    setNewThoughtEmbeddingsLoading,
    editorIsFocused,
    setEditorIsFocused,
  } = useContext(SimpleAppPostContext)
  const [editor] = useState(() => withHistory(withReact(createEditor())))

  const navigate = useNavigate()

  const appendPost = (transcribedText?: string, audioUrl?: string) => {
    //loading embeddings still
    setNewThoughtEmbeddingsLoading(true)
    // Allows audio transcriptions to be used along side slate editor
    const textOfNewPost = transcribedText ? transcribedText : getSlateEditorString(editor)
    const children: Descendant[] = transcribedText
      ? transcribedText
          .split("\n")
          .map((e: string) => ({ type: "paragraph", children: [{ type: "text", text: e }] }))
      : editor.children
    //there's a chance editor.children is undefined... check this here
    const { postWithId, embeddingsPromise, addPostPromise } = simpleBackendWriter.addPost(
      children ?? editor.children,
      textOfNewPost,
      "forum",
      undefined,
      undefined,
      audioUrl
    )

    //only expand thought after embeddings loaded?
    //this is deprecated, now we just go directly to the page
    // setThoughtIdToExpand(postWithId.id)

    navigate(`/idea/${postWithId.id}?updated=true`)

    embeddingsPromise.then(() => {
      //set the thought to be expanded
      setNewThoughtEmbeddingsLoading(false)
    })
    if (!audioUrl) {
      clearEditor(editor)
      setEditorIsFocused(false)
    }
    return addPostPromise
  }

  return (
    <>
      {true ? (
        // If showAudio is true, display the AudioRecorder
        <div>
          <AudioRecorder appendPost={appendPost} />
        </div>
      ) : (
        // If showAudio is false, display the text editor and other components
        <>
          <div
            className={
              "create-container row textPost topLevel " + (editorIsFocused ? " focused" : "")
            }
          >
            <EditorContainer
              {...{ editor, handleEnter: appendPost, setIsEmpty: setEditorIsEmpty }}
            />
            <PostButton {...{ appendPost, disabled: editorIsEmpty }} />
          </div>
          {/* block empty space */}
          {editorIsFocused ? <div className="expandable-background-blocker"></div> : null}
        </>
      )}
      {/* Button to toggle between audio and text */}
    </>
  )
}

export default Create

const clearEditor = (editor: Editor) => {
  Transforms.delete(editor, {
    at: {
      anchor: Editor.start(editor, []),
      focus: Editor.end(editor, []),
    },
  })
}

export const frontendAppendPost = (
  parentThought?: TextPost,
  transcribedText?: string,
  audioUrl?: string,
  setNewThoughtEmbeddingsLoading?: Dispatch<SetStateAction<boolean>>,
  editor?: Editor,
  setThoughtIdToExpand?: Dispatch<SetStateAction<string>>,
  setEditorIsFocused?: Dispatch<SetStateAction<boolean>>,
  setAppView?: Dispatch<SetStateAction<string>>
) => {
  //loading embeddings still
  if (setNewThoughtEmbeddingsLoading) setNewThoughtEmbeddingsLoading(true)
  // Allows audio transcriptions to be used along side slate editor
  const textOfNewPost = transcribedText ? transcribedText : getSlateEditorString(editor)
  const children: Descendant[] = transcribedText
    ? transcribedText
        .split("\n")
        .map((e: string) => ({ type: "paragraph", children: [{ type: "text", text: e }] }))
    : editor.children
  //there's a chance editor.children is undefined... check this here

  const { newReplyThought, embeddingsPromise, addPostPromise } =
    simpleBackendWriter.addReplyThought(
      textOfNewPost,
      parentThought,
      "forum",
      children ?? editor.children,
      audioUrl
    )

  //just for testing
  addPostPromise.then((e) => {
    console.log("Reply thought was successfully added", e, newReplyThought)
    return newReplyThought
  })

  //only expand thought after embeddings loaded?
  if (setThoughtIdToExpand) setThoughtIdToExpand(newReplyThought?.id)
  embeddingsPromise.then(() => {
    //set the thought to be expanded
    setNewThoughtEmbeddingsLoading(false)
  })
  if (!audioUrl) {
    clearEditor(editor)
    setEditorIsFocused(false)
  }
  if (setAppView) setAppView("thoughts")

  return addPostPromise
}
