import { useContext, useEffect, useState } from "react"
import FirebaseWriter from "../../../../Firebase/FirebaseWriter"
import AuthContext from "../../../../ReactContexts/AuthContext"
import { SingleConnectionUpdateForAPerson } from "../../../../ReactContexts/PostContext"
import { simpleBackendWriter } from "../../SimpleApp"
import SimpleAppPostContext from "../../SimpleAppPostContext"
import Inbox, { InboxItemInterface } from "../Inbox"
import "./ConnectionInbox.css"
import { filterArrayToJustHaveUniqueItems } from "../../PostList/PostList"

/**
 * This is where we query for the connections
 * @returns
 */
const ConnectionInbox = () => {
  const { inboundEdges, setEdgeCount, personBucket } = useContext(SimpleAppPostContext)
  const { person } = useContext(AuthContext)

  //Get the correct inbox items
  const [inboxItems, setInboxItems] = useState<InboxItemInterface[]>()

  //record peak
  useEffect(() => {
    //after two seconds, peak inbox every ten seconds
    setTimeout(() => simpleBackendWriter.recordNotifsPeak(Date.now()), 1000)
  }, [])

  useEffect(() => {
    const incomingReplies = Object.values(inboundEdges?.REPLY ?? {})
    const incomingListens = Object.values(inboundEdges?.CONNECTION ?? {})
    const incomingRelated = []
    // Object.values(inboundEdges?.RELATED ?? {})

    const withoutSelfActions = [...incomingReplies, ...incomingRelated].filter(
      (e) => e?.edgeAuthorId !== person?.uid
    )
    const allIncoming = filterArrayToJustHaveUniqueItems(
      withoutSelfActions,
      (a, b) => a?.sourceId === b?.sourceId && a?.targetThoughtId === b?.targetThoughtId
    )

    const edgeTimestampComparator = (
      edgeA: SingleConnectionUpdateForAPerson,
      edgeB: SingleConnectionUpdateForAPerson
    ): number => {
      return (edgeB?.timestamp ?? 0) - (edgeA?.timestamp ?? 0)
    }

    // Sort the edges by timestamp, so the thought ids end up sorted by timestamp
    const sortedIncoming = allIncoming.sort(edgeTimestampComparator)

    // Create arrays of source and target thought IDs
    const sourceThoughtIds = sortedIncoming.map((edge) => edge.sourceId)
    const targetThoughtIds = sortedIncoming.map((edge) => edge.targetThoughtId)

    // Combine all IDs in one array for a single query
    const allThoughtIds = [...sourceThoughtIds, ...targetThoughtIds]

    // This promise will retrieve all thoughts in one go
    const allThoughtsPromise = FirebaseWriter.queryByIds(allThoughtIds)

    allThoughtsPromise.then((allThoughts) => {
      // separate the thoughts back into their respective categories
      // Note: This assumes that 'allThoughts' maintains the order of 'allThoughtIds' <-- BAD ASSUMPTION
      const newSourceThoughts = allThoughts.slice(0, sourceThoughtIds.length)
      const newTargetThoughts = allThoughts.slice(sourceThoughtIds.length)

      const newInboxItems = sortedIncoming.map((edge) => {
        const sourceThought = newSourceThoughts.find((thought) => thought.id === edge.sourceId)
        const targetThought = newTargetThoughts.find(
          (thought) => thought.id === edge.targetThoughtId
        )
        return {
          sourceThought,
          targetThought,
          edge,
          primaryAction: (e) => console.log(e),
          primaryActionTaken: false,
        }
      })

      const filteredInboxItems = newInboxItems.filter(
        (e) =>
          e.sourceThought?.text &&
          e.targetThought?.text &&
          e.edge?.timestamp &&
          e.edge.authorId !== simpleBackendWriter.personId
      )

      const uniqueFiltered = filterArrayToJustHaveUniqueItems(
        filteredInboxItems,
        (a: InboxItemInterface, b: InboxItemInterface) =>
          a?.sourceThought?.id === b?.sourceThought?.id
        // &&
        // a?.targetThought?.id === b?.targetThought.id
      )

      setInboxItems(uniqueFiltered)
    })
  }, [inboundEdges?.CONNECTION, inboundEdges?.REPLY])

  return (
    <div className="connection-inbox-container">
      {inboundEdges && Object.values(inboundEdges).length > 0 ? (
        <Inbox items={inboxItems} />
      ) : typeof inboundEdges !== "undefined" ? (
        <div>
          nobody's replied or listened to you yet.<br></br>
          <br></br> to begin, try expressing a few Voices.
        </div>
      ) : (
        <div>loading connections...</div>
      )}
    </div>
  )
}

export default ConnectionInbox
