import { DatabaseReference, child, onValue } from "firebase/database"
import FirebaseWriter from "../../../Firebase/FirebaseWriter"
import { FriendMap } from "../../../Types/types"
import { useState, useEffect } from "react"

export function getFriendMapRef(dbRef: DatabaseReference, personId: string) {
  if (!dbRef) return
  return child(dbRef, `friendmap/${personId}`)
}

export function getFriendMap(backendWriter: FirebaseWriter, personId: string) {
  return new Promise<FriendMap>((resolve) => {
    const dbRef = backendWriter?.databaseRef
    if (!dbRef) return
    const friendMapRef = getFriendMapRef(dbRef, personId)
    onValue(friendMapRef, (snapshot) => {
      if (snapshot.exists()) {
        resolve(snapshot.val())
      }
    })
  })
}

export function getFriendMapRecord(backendWriter: FirebaseWriter, personId: string, targetPersonId: string) {
  return getFriendMap(backendWriter, personId)
    .then((friendMap) => {
      return friendMap[targetPersonId]
    })
}

export function useGetFriendMap(backendWriter: FirebaseWriter, personId: string) {
  const [friendMap, setFriendMap] = useState<FriendMap>()

  useEffect(() => {
    const dbRef = backendWriter?.databaseRef
    if (!dbRef) return
    const friendMapRef = getFriendMapRef(dbRef, personId)
    const unsub = onValue(friendMapRef, (snapshot) => {
      if (snapshot.exists()) {
        setFriendMap(snapshot.val())
      }
    })

    return unsub
  }, [backendWriter, personId])

  return friendMap
}