import "./VoicesOrientation.css"
import "../App.css"
import React, { useContext, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import ExampleThoughts from "./ExampleThoughts"
import { backendWriter } from "../App"
import PostContext from "../../ReactContexts/PostContext"
import FeedItem from "../SimplePlexusComponents/PostList/FeedItem"

const slides = (): {
  text: string
  style?: "italic"
  delay?: number
  examples?: true
  exNumber?: number
  voices?: true
}[] => [
  {
    text: "Welcome to Voices.",
  },
  {
    text: "Voices is a social network for life transitions, big & small",
  },
  {
    text: "For when you change homes, jobs, hobbies, friends, relationships, interests, style, books, or tooth brushes.",
  },
  {
    text: "There are two steps.",
  },
  {
    text: "(1) You say what you're going through.",
  },
  {
    text: "(2) You hear real people going through it too.",
  },
  { text: "Everyone will be glad you're here." },
  { text: "When you're ready: ", delay: 1000 },
]

interface Props {
  onCompletion?: () => void
}

const VoicesOrientation = ({ onCompletion }: Props) => {
  const { placeId } = useParams()
  const navigate = useNavigate()
  const { personBucket } = useContext(PostContext)
  const [i, setI] = useState(0)
  const [loading, setLoading] = useState(false)

  const theSlides = slides()
  const isNextButtonEnabled = i + 1 < theSlides.length

  const onFinish = () => {
    localStorage.setItem("oriented-already-1", "true")
    if (onCompletion) {
      onCompletion()
    }
  }

  const onContainerClick = () => {
    setLoading(true)
    //if first click
    if (i == 0) {
      backendWriter.recordOnboardingStart()
    }

    setTimeout(() => {
      setLoading(false)
      if (isNextButtonEnabled && !loading) {
        setI((prev) => prev + 1)
      } else {
        onFinish()
      }
    }, theSlides[i].delay ?? 600) //aligned with the css
  }

  return (
    <div
      className={"orientation-container" + (loading ? " loading" : "")}
      onClick={onContainerClick}
      onDoubleClick={onFinish}
    >
      <div
        className="slide-text"
        style={{
          fontStyle: theSlides[i].style ?? "normal",
        }}
      >
        {theSlides[i].text}
      </div>
      <br></br>
      <div className="nav-buttons">
        <div className={"next-button"}>
          {i + 1 < theSlides.length ? (
            <span style={{ fontSize: "2em" }} className={i == 0 ? " pulsing" : ""}>
              ◉
            </span>
          ) : (
            <span style={{ fontSize: "2em", paddingTop: "50px" }}>🚪</span>
          )}
        </div>
      </div>
    </div>
  )
}

export default VoicesOrientation
