import { TextPost } from "../../../ReactContexts/PostContext"

// Shortcut function to lookup (inefficiently) author emails by ID
export const retrieveAuthorEmail = (thoughts: TextPost[], authorId: string): string => {
  for (let i = 0; i < thoughts.length; i++) {
    if (thoughts[i].authorId === authorId) {
      return thoughts[i].authorEmail
    }
  }
  return ""
}
