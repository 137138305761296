import { useState, useEffect } from "react"
import { TextPost } from "../../ReactContexts/PostContext"
import { getParentThought } from "../PublicPages/PublicThoughtPage/PublicThoughtPage"

function UseParentThought(thought: TextPost) {
  const [parentThought, setParentThought] = useState<TextPost>()

  useEffect(() => {
    if (!thought) return undefined
    const parentThoughtPromise = getParentThought(thought)
    parentThoughtPromise.then((theParentThought) => {
      setParentThought(theParentThought)
    })
  }, [thought?.connections?.outbound])

  return parentThought
}

export default UseParentThought
