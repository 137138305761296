import { useContext, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import ExampleThoughts from "./ExampleThoughts"
import "./Orientation.css"
import "../App.css"
import { backendWriter } from "../App"
import PostContext from "../../ReactContexts/PostContext"

const slides = (
  firstName: string
): {
  text: string
  style?: "italic"
  delay?: number
  examples?: true
  exNumber?: number
}[] => [
  //NOT USED SNYMORE
  { text: "Welcome" + (firstName ? ", " + firstName : " to Voices") + "." },
  {
    text: "Voices is a cathartic audio space.",
  },

  {
    text: "When something's on your mind...",
  },
  {
    text: "...you can talk it through here.",
  },
  {
    text: "In return, you'll receive a custom playlist:",
  },
  {
    text: "real people's voice notes, related to the thing on your mind.",
  },
  {
    text: "It's an alternative to music / podcasts.",
  },
  {
    text: "A kind of deeply human magic.",
  },
  { text: "When you're ready, here's the door.", delay: 1000 },
]

const Orientation = () => {
  const { placeId } = useParams()
  const { personBucket } = useContext(PostContext)
  const [i, setI] = useState(0)
  const [loading, setLoading] = useState(false)
  const displayName =
    personBucket && "personName" in personBucket ? personBucket.personName : undefined

  const theSlides = slides(displayName)

  const nextButtonEnabled = i + 1 < theSlides.length
  const navigate = useNavigate()
  const doubleClickAction = () => {
    navigate("/p/" + (placeId ?? ""))
    localStorage.setItem("oriented-already-1", "true")
    backendWriter?.markPersonAsOnboarded()
    navigate("/")
  }
  return (
    <div
      className={"orientation-container" + (loading ? " loading" : "")}
      onClick={() => {
        setLoading(true)
        //if first click
        if (i == 0) {
          backendWriter.recordOnboardingStart()
        }

        setTimeout(() => {
          setLoading(false)
          if (nextButtonEnabled && !loading) {
            setI((prev) => prev + 1)
          } else {
            doubleClickAction()
          }
        }, theSlides[i].delay ?? 600) //aligned with the css
      }}
      onDoubleClick={doubleClickAction}
    >
      <div
        className="slide-text"
        style={{
          fontStyle: theSlides[i].style ?? "normal",
          padding: "1em 1em 0em",
          maxWidth: "24em",
          lineHeight: "1.6em",
        }}
      >
        {theSlides[i].text}
      </div>

      <div style={{}}>
        {theSlides[i].examples ? [0, 1, 2].map((_example, i) => <ExampleThoughts i={i} />) : <></>}
      </div>
      <br></br>

      <div className="nav-buttons">
        <div className={"next-button"}>
          {i + 1 < theSlides.length ? (
            <span style={{ fontSize: "1.2em" }}>◉</span>
          ) : (
            <span style={{ fontSize: "2em", paddingTop: "50px" }}>🚪</span>
          )}
          {i === 0 ? (
            <div style={{ color: "gray", fontSize: ".8em" }}>click to continue!</div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  )
}

export default Orientation
