// @ts-nocheck
// No type checking on this outdated stuff, this should eventually be purged.
import moment from "moment"
import { useEffect, useState } from "react"
import { TextPost } from "../../../ReactContexts/PostContext"
import { getAllConnectionsInTimeWindow } from "../ConnectionAdminStats"
import { retrieveAuthorEmail } from "../functions/sortData"
import "./AdminMatchesMadeViewer.css"
import { Conversation } from "../../../Firebase/FirebaseDatabaseInterfaces"

export default function AdminStatistics({ conversationsAndThoughts }: any) {
  const [minInputTime, setMinInputTime] = useState<number>(0)
  const [maxInputTime, setMaxInputTime] = useState<number>(moment().valueOf())
  const [showContributorEmails, setShowContributorEmails] = useState<boolean>(false)
  const [showConversationContributorEmails, setShowConversationContributorEmails] =
    useState<boolean>(false)
  const [showThoughtContributorEmails, setShowThoughtContributorEmails] = useState<boolean>(false)

  const [showInactiveEmails, setShowInactiveEmails] = useState<boolean>(false)
  const [showInactiveThoughtEmails, setShowInactiveThoughtEmails] = useState<boolean>(false)
  const [showInactiveConversationEmails, setShowInactiveConversationEmails] =
    useState<boolean>(false)

  const [showActiveEmails, setShowActiveEmails] = useState<boolean>(false)
  const [showActiveThoughtEmails, setShowActiveThoughtEmails] = useState<boolean>(false)
  const [showActiveConversationEmails, setShowActiveConversationEmails] = useState<boolean>(false)

  const [weeksAgo, setWeeksAgo] = useState<number>(0)
  const [weeksAgoTimestamp, setWeeksAgoTimestamp] = useState<number>(604800000 * weeksAgo)

  useEffect(() => {
    const oneWeekEpoch = 604800000
    setWeeksAgoTimestamp(oneWeekEpoch * weeksAgo)
  }, [weeksAgo])

  const conversations = conversationsAndThoughts[0]
  const thoughts = conversationsAndThoughts[1]

  const today = moment().valueOf()
  const lastSaturday = moment().startOf("isoWeek").subtract(2, "d").valueOf()
  const twoSaturdaysAgo = moment().startOf("isoWeek").subtract(9, "d").valueOf()

  const getTotalUniqueContributors = (thoughts: TextPost[]): string[] => {
    const unique: string[] = [...new Set(thoughts.map((thought: TextPost) => thought.authorId))] // [ 'A', 'B']
    return unique
  }

  // Counts number of unique author IDs that have sent a conversation within a time range
  const getTotalConversationContributors = (
    conversations: Conversation,
    minTimestamp: number = 0,
    maxTimestamp: number = 999999999999999
  ) => {
    let conversationAuthorIds = []
    // Loop through entire conversation object
    for (const conversation in conversations) {
      // Checks that messages object exists
      if ("messages" in conversations[conversation]) {
        // Loops through all messages within a conversation
        for (const message in conversations[conversation]?.messages) {
          // Shorthand var for grabbing timestamp
          const timestamp = conversations[conversation]?.messages[message].timestamp
          // Checks that timestamp falls within the given period
          if (timestamp > minTimestamp && timestamp < maxTimestamp) {
            // If falls within period, adds authorId to array
            conversationAuthorIds.push(conversations[conversation]?.messages[message]?.authorId)
          }
        }
      }
    }
    // Finds unique Ids
    return [...new Set(conversationAuthorIds)]
  }

  // Returns the combined (outer-joined) contributors from both conversations and thought contributors
  const getAllContributors = (thoughts, conversations, minTimestamp, maxTimestamp) => {
    // Collecting array of unique authorIds of conversation contributors
    const conversationContributors = getTotalConversationContributors(
      conversations,
      minTimestamp,
      maxTimestamp
    )
    // Collecting array of unique authorIds of thought contributors
    const thoughtContributors = getTotalUniqueContributors(
      thoughts.filter((thought) => {
        return thought.timestamp > minTimestamp && thought.timestamp < maxTimestamp
      })
    )
    // Combined unique author Ids
    return [...new Set(conversationContributors.concat(thoughtContributors))]
  }

  const getAllMessages = (conversations, minTimestamp, maxTimestamp) => {
    let messages: string[] = []
    for (const conversation in conversations) {
      if ("messages" in conversations[conversation]) {
        for (const message in conversations[conversation]?.messages) {
          const timestamp = conversations[conversation]?.messages[message].timestamp
          if (timestamp > minTimestamp && timestamp < maxTimestamp) {
            messages.push(conversations[conversation]?.messages[message]?.message)
          }
        }
      }
    }
    return messages
  }

  const getActiveContributors = (getInactive?: boolean, weeksAgoModifier: number = 0) => {
    let todayStartDate = today
    if (weeksAgoModifier) {
      todayStartDate = moment()
        .startOf("isoWeek")
        .subtract(2, "d")
        .subtract(weeksAgo - 1, "w")
        .valueOf()
    }

    const thisWeekContributors = getTotalUniqueContributors(
      thoughts.filter((thought: TextPost) => {
        return (
          thought.timestamp > lastSaturday - weeksAgoModifier && thought.timestamp < todayStartDate
        )
      })
    )

    const lastWeekContributors = getTotalUniqueContributors(
      thoughts.filter((thought: TextPost) => {
        return (
          thought.timestamp > twoSaturdaysAgo - weeksAgoModifier &&
          thought.timestamp < lastSaturday - weeksAgoModifier
        )
      })
    )

    // Removes all people from lastWeekContributors that exist in this thisWeekContributors
    // Thus keeping all people who were active last week but not this week.
    if (getInactive) {
      return lastWeekContributors.filter((element) => !thisWeekContributors.includes(element))
    }

    // Only returns elements that are present in both arrays
    return thisWeekContributors.filter((element) => lastWeekContributors.includes(element))
  }

  const getActiveMessageContributors = (getInactive?: boolean, weeksAgoModifier: number = 0) => {
    let todayStartDate = today
    if (weeksAgoModifier) {
      todayStartDate = moment()
        .startOf("isoWeek")
        .subtract(2, "d")
        .subtract(weeksAgo - 1, "w")
        .valueOf()
    }
    const thisWeekContributors = getTotalConversationContributors(
      conversations,
      lastSaturday - weeksAgoModifier,
      todayStartDate
    )
    const lastWeekContributors = getTotalConversationContributors(
      conversations,
      twoSaturdaysAgo - weeksAgoModifier,
      lastSaturday - weeksAgoModifier
    )
    if (getInactive) {
      return lastWeekContributors.filter((element) => !thisWeekContributors.includes(element))
    }
    return thisWeekContributors.filter((element) => lastWeekContributors.includes(element))
  }

  // Returns all active contributors inclusive of both conversators and thought producers
  const getTotalActiveUsersAcrossBoth = (weeksAgoModifier: number = 0) => {
    let todayStartDate = today
    if (weeksAgoModifier) {
      todayStartDate = moment()
        .startOf("isoWeek")
        .subtract(2, "d")
        .subtract(weeksAgo - 1, "w")
        .valueOf()
    }
    let allContributorsThisWeek = getAllContributors(
      thoughts,
      conversations,
      lastSaturday - weeksAgoModifier,
      todayStartDate
    )
    let allContributorsLastWeek = getAllContributors(
      thoughts,
      conversations,
      twoSaturdaysAgo - weeksAgoModifier,
      lastSaturday - weeksAgoModifier
    )

    return allContributorsThisWeek.filter((contributor) => {
      return allContributorsLastWeek.includes(contributor)
    })
  }

  const getTotalActiveContributors = (getInactive?: boolean, weeksAgoModifier: number = 0) => {
    if (getInactive) {
      // All people who contributed by message this week but did not last week
      const activeConversationContributors = getActiveMessageContributors(
        getInactive,
        weeksAgoModifier
      )
      // All people who contributed by thought this week but did not last week
      const activeThoughtContributors = getActiveContributors(getInactive, weeksAgoModifier)
      let activeThisWeek = getAllContributors(
        thoughts,
        conversations,
        lastSaturday - weeksAgoModifier,
        today - weeksAgoModifier
      )

      // The unique values of authorIds who did not contribute in conversations
      // We do not return this value yet because we need to check if any of the authorIds contributed through a different medium
      // than they did the week prior
      let inactives: string[] = [
        ...new Set(activeConversationContributors.concat(activeThoughtContributors)),
      ]

      // Removes anybody who was active this week by removing all ids present in the above array if they are present in any of the net active people from last week
      inactives = inactives.filter((authorId) => {
        return !activeThisWeek.includes(authorId)
      })
      return inactives
    }
    return [...new Set(getTotalActiveUsersAcrossBoth(weeksAgoModifier))]
  }

  return (
    <>
      <div>
        <h2> Dynamic: </h2>
        <span>Start Date: </span>
        <input
          type="date"
          id="startDateInput"
          onChange={(event) => setMinInputTime(moment(event.target.value).valueOf())}
        />
        <br></br>
        <span>End Date: </span>
        <input
          type="date"
          id="endDateInput"
          onChange={(event) => setMaxInputTime(moment(event.target.value).valueOf())}
        ></input>
        <br></br>
        <br></br>
        <span
          className="interactableAdminElement"
          onClick={() => {
            setShowThoughtContributorEmails(!showThoughtContributorEmails)
          }}
        >
          {" "}
          Total number of thought contributors:{" "}
        </span>
        {
          getTotalUniqueContributors(
            thoughts.filter((thought) => {
              return thought.timestamp > minInputTime && thought.timestamp < maxInputTime
            })
          ).length
        }{" "}
        {showThoughtContributorEmails ? (
          getTotalUniqueContributors(
            thoughts.filter((thought) => {
              return thought.timestamp > minInputTime && thought.timestamp < maxInputTime
            })
          ).map((authorId, i) => {
            return (
              <div>
                {i + 1}. {retrieveAuthorEmail(thoughts, authorId)}
              </div>
            )
          })
        ) : (
          <></>
        )}
        <br></br>
        <span
          className="interactableAdminElement"
          onClick={() => {
            setShowConversationContributorEmails(!showConversationContributorEmails)
          }}
        >
          Total number of conversation contributors:{" "}
        </span>
        {getTotalConversationContributors(conversations, minInputTime, maxInputTime).length}
        {showConversationContributorEmails ? (
          getTotalConversationContributors(conversations, minInputTime, maxInputTime).map(
            (authorId, i) => {
              return (
                <div>
                  {i + 1}. {retrieveAuthorEmail(thoughts, authorId)}
                </div>
              )
            }
          )
        ) : (
          <></>
        )}
        <br></br>
        <span
          className="interactableAdminElement"
          onClick={() => {
            setShowContributorEmails(!showContributorEmails)
          }}
        >
          Total number of all contributors:{" "}
        </span>
        {getAllContributors(thoughts, conversations, minInputTime, maxInputTime).length}
        {showContributorEmails ? (
          getAllContributors(thoughts, conversations, minInputTime, maxInputTime).map(
            (authorId, i) => {
              return (
                <div>
                  {i + 1}. {retrieveAuthorEmail(thoughts, authorId)}
                </div>
              )
            }
          )
        ) : (
          <></>
        )}
        <br></br>
        Total number of messages: {getAllMessages(conversations, minInputTime, maxInputTime).length}
        <br></br>
        Total number of thoughts:{" "}
        {
          thoughts.filter((thought) => {
            return thought.timestamp > minInputTime && thought.timestamp < maxInputTime
          }).length
        }{" "}
        <br></br>
        Total number of connections:{" "}
        {getAllConnectionsInTimeWindow(thoughts, minInputTime, maxInputTime).length}
        <h2> Active over 2 weeks (Weeks start on Saturday)</h2>
        <b>
          {" "}
          Showing activity from{" "}
          {weeksAgo === 0 ? " this week." : weeksAgo + ` week${weeksAgo !== 1 ? "s" : ""} ago.`}
        </b>
        <div
          className="interactableAdminElement"
          onClick={() => {
            setShowActiveThoughtEmails(!showActiveThoughtEmails)
          }}
        >
          Total number of thought contributors:
          {getActiveContributors(false, weeksAgoTimestamp).length}
        </div>
        {showActiveThoughtEmails
          ? getActiveContributors(false, weeksAgoTimestamp).map((authorId, i) => {
              return (
                <div key={"thots-active-" + i}> {retrieveAuthorEmail(thoughts, authorId)} </div>
              )
            })
          : ""}
        <div
          className="interactableAdminElement"
          onClick={() => {
            setShowActiveConversationEmails(!showActiveConversationEmails)
          }}
        >
          Total number of conversation contributors:
          {getActiveMessageContributors(false, weeksAgoTimestamp).length}
        </div>
        {showActiveConversationEmails
          ? getActiveMessageContributors(false, weeksAgoTimestamp).map((authorId, i) => {
              return (
                <div key={"convo-active-" + i}> {retrieveAuthorEmail(thoughts, authorId)} </div>
              )
            })
          : ""}
        <div
          className="interactableAdminElement"
          onClick={() => {
            setShowActiveEmails(!showActiveEmails)
          }}
        >
          Total number of contributors (both / either):
          {getTotalActiveContributors(false, weeksAgoTimestamp).length}
        </div>
        {showActiveEmails
          ? getTotalActiveContributors(false, weeksAgoTimestamp).map((authorId, i) => {
              return <div key={"all-" + i}> {retrieveAuthorEmail(thoughts, authorId)} </div>
            })
          : ""}
        <button
          onClick={() => {
            setWeeksAgo(weeksAgo + 1)
          }}
        >
          {" "}
          prev{" "}
        </button>{" "}
        <button
          onClick={() => {
            setWeeksAgo(weeksAgo - 1)
          }}
        >
          {" "}
          next{" "}
        </button>
        <h2> Thought Contributors Who Contributed Last Week But Not this Week</h2>
        <div
          className="interactableAdminElement"
          onClick={() => {
            setShowInactiveThoughtEmails(!showInactiveThoughtEmails)
          }}
        >
          {" "}
          Total number of thought contributors:
          {getActiveContributors(true).length}
        </div>
        {showInactiveThoughtEmails
          ? getActiveContributors(true).map((authorId, i) => {
              return <div key={"thots-" + i}>{retrieveAuthorEmail(thoughts, authorId)} </div>
            })
          : ""}
        <div
          className="interactableAdminElement"
          onClick={() => {
            setShowInactiveConversationEmails(!showInactiveConversationEmails)
          }}
        >
          Total number of conversation contributors:
          {getActiveMessageContributors(true).length}
        </div>
        {showInactiveConversationEmails
          ? getActiveMessageContributors(true).map((authorId, i) => {
              return <div key={"convo-" + i}>{retrieveAuthorEmail(thoughts, authorId)}</div>
            })
          : ""}
        <div
          className="interactableAdminElement"
          onClick={() => {
            setShowInactiveEmails(!showInactiveEmails)
          }}
        >
          Total number of contributors (both / either):
          {getTotalActiveContributors(true).length}
        </div>
        {showInactiveEmails
          ? getTotalActiveContributors(true).map((authorId, i) => {
              return <div key={"all-" + i}>{retrieveAuthorEmail(thoughts, authorId)}</div>
            })
          : ""}
      </div>
    </>
  )
}
