import { child, getDatabase, onValue } from "firebase/database"
import { useState, useEffect } from "react"
import FirebaseWriter from "../../Firebase/FirebaseWriter"

function usePostConfirmedDoesntExist(backendWriter: FirebaseWriter, postId: string) {
  const [exists, setExists] = useState<boolean>(false)

  useEffect(() => {
    const dbRef = backendWriter?.databaseRef
    if (!dbRef) return
    const postRef = child(dbRef, `nodes/${postId}`)

    const unsub = onValue(postRef, (snapshot) => {
      setExists(!snapshot.exists())
    })

    // Cleanup function
    return unsub
  }, []) // Empty dependency array means this effect runs once on mount and cleanup on unmount

  return exists
}

export default usePostConfirmedDoesntExist
