import { TextPost } from "../../../ReactContexts/PostContext"
import UseParentThought from "../../CustomReactHooks/UseParentThought"
import FeedItem from "../../SimplePlexusComponents/PostList/FeedItem"

const MAX_BREADCRUMB_DEPTH = 4
const Lineage = ({
  rootPost,
  post,
  depth,
}: {
  rootPost: TextPost
  post: TextPost
  depth: number
}) => {
  const updatedParent = UseParentThought(post)

  return post ? (
    <div key={"lineage-post-" + post.id + depth}>
      {updatedParent && depth < MAX_BREADCRUMB_DEPTH ? (
        <Lineage {...{ rootPost, post: updatedParent, depth: depth + 1 }} />
      ) : depth >= MAX_BREADCRUMB_DEPTH ? (
        <>...</>
      ) : (
        <></>
      )}
      <FeedItem
        {...{
          post: post,
          id: post.id,
          text: post.text,
          timestamp: post.timestamp,
          isBreadcrumb: true,
          fromPost: rootPost,
          audioUrl: post.audioUrl,
        }}
      />
    </div>
  ) : (
    <></>
  )
}

export default Lineage
