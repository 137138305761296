import { useContext } from "react"
import { Navigate, useLocation, useNavigate, useParams } from "react-router-dom"
import "./LandingPage.css"
import plexusAnimationFolding from "../../assets/plexusAnimateClosing.gif"
import voicesGuy from "./VoicesLogo.png"

//auth 1
import AuthContext from "../../ReactContexts/AuthContext"
import { logEvent } from "firebase/analytics"
import { analytics, auth, provider } from "../App"
import { signInWithPopup } from "firebase/auth"
import { ArrowRight } from "@phosphor-icons/react"
const audio = new Audio("./voicesthing.mp3")

const LandingPage = () => {
  const navigate = useNavigate()
  const url = useLocation()
  const { person } = useContext(AuthContext)
  const { placeId } = useParams()
  //If on the home page

  const handleLogin = () => {
    const code = "test"
    if (!person) {
      logEvent(analytics, code + "-invite-page-click", {
        timestamp: Date.now(),
        personEmail: person?.email,
        personGoogleDisplayName: person?.displayName,
        personId: person?.uid,
        inviteCode: code,
      })

      //go to loading page
      navigate("/p/" + (placeId ?? "forum") + "/authenticating")
      //used to redirect
      signInWithPopup(auth, provider).then((val) => {
        //signed in
        const person = val?.user
        logEvent(analytics, code + "invite-page-sign-in", {
          timestamp: Date.now(),
          personEmail: person?.email,
          personGoogleDisplayName: person?.displayName,
          personId: person?.uid,
          person: person,
          inviteCode: code,
        })
      })
      //email verification link
    } else {
      navigate("/")
      logEvent(analytics, code + "-invite-page-click-but-already-logged-in", {
        timestamp: Date.now(),
        personEmail: person?.email,
        personGoogleDisplayName: person?.displayName,
        personId: person?.uid,
        inviteCode: code,
      })
    }
  }

  return person && url.pathname !== "/landing" ? (
    <Navigate to="/p" />
  ) : (
    <div className="landing-page">
      <div className="landing-page-text">
        <div className="top-part">
          {/* <img
            src={voicesGuy}
            style={{ maxWidth: "80vw", width: "200pt", marginLeft: "30pt" }}
          ></img> */}
          <img
            src={plexusAnimationFolding}
            style={{ maxWidth: "50vw", width: "80pt", marginTop: "-120pt", margin: "20pt" }}
          ></img>
          <div
            style={{ alignSelf: "stretch" }}
            // onClick={() => audio.play()} style={{ zIndex: 3 }}
          >
            {/* there's much more <br></br>to each of us<br></br> than we know */}
            <br></br>a social network <br></br>for life transitions<br></br>(moves, jobs,
            relationships)
          </div>
          <br></br>
          <br></br>

          <button
            className="voices-button"
            onClick={(e) => {
              e.preventDefault()
              navigate("/login")
            }}
          >
            try Voices &nbsp;
            <ArrowRight />
          </button>
          <br></br>

          {/* <button
            className="secondary-enter-button"
            onClick={() => {
              window.location.href = "https://walk.plexus.earth"
            }}
          >
            Join Walk &nbsp;
            <ArrowRight />
          </button> */}
        </div>

        <br></br>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          <div className="link-row">
            <a href={window?.location?.origin}>home</a>
            <a href="https://plexus.substack.com/">blog</a>
            <a href="https://twitter.com/plexusearth">twttr</a>
            <a href="https://docs.google.com/document/d/1BoqTmmcaNUlaur7tG4o7Pdm8rRCoHLNZ/edit?usp=sharing&ouid=112785312491031312241&rtpof=true&sd=true">
              privacy
            </a>
            <a href="https://docs.google.com/document/d/1B8n2TaviaGY7knglzkk1OSezraFzpS9p/edit?usp=sharing&ouid=112785312491031312241&rtpof=true&sd=true">
              terms
            </a>
          </div>
        </div>
      </div>
      <span></span>
    </div>
  )
}

export default LandingPage
