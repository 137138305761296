import { getFullEdgeMapArr } from "../Firebase/ReplyUtilities"
import { ConnectionKind, EdgeInfo, TextPost } from "../ReactContexts/PostContext"

/**
 * Returns an array of all the unique thoughts only
 * Wrote draft of this, haven't tested yet
 * @param thoughts
 */
export const getUniqueThoughts = (thoughts: TextPost[]): TextPost[] => {
  const uniques = thoughts.reduce((uniqueThoughtsSoFar: TextPost[], nextThought: TextPost) => {
    const uniqueThoughtsSoFarIds = uniqueThoughtsSoFar.map((thought) => thought.id)
    if (uniqueThoughtsSoFarIds.includes(nextThought.id)) {
      return uniqueThoughtsSoFar
    } else return [...uniqueThoughtsSoFar, nextThought]
  }, [])
  return uniques
}

/**
 * Function to get the Id of the edge's actual author
 *  Before this, we were assuming that the edge's author is the author of the source thought, which is no longer always true now that rabbit holing / vicarious connection-making is possible.
 * TODO write this function with new logic
 * @param e
 */
export function getEdgeAuthor(e: EdgeInfo): string {
  return "edgeAuthorId" in e ? e.edgeAuthorId : e.authorId
}

export function findDirectedEdgeBetweenPosts(
  postA: TextPost,
  postB: TextPost,
  connectionKind: ConnectionKind
): EdgeInfo | undefined {
  const outbound = getFullEdgeMapArr(postA?.connections?.outbound ?? {}, connectionKind)
  const edgesToB = outbound.filter((e) => e.targetThoughtId === postB.id)
  const firstToB = edgesToB.length > 0 ? edgesToB[0] : undefined
  return firstToB
}
