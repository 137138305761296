//reply utilities
//this is functionality for queries related to threading/replying (written 2/8 in the context of threading)

import {
  ConnectionKind,
  ConnectionMap,
  DirectionalEdgeMap,
  EdgeInfoWithConnectionDataAndId,
  PostMap,
  TextPost,
} from "../ReactContexts/PostContext"

/**
 * Get all the thought ids for replies to the given parent thought
 * @param thought
 * @returns
 */
export function getReplyThoughtIdsFromParent(thought: TextPost): string[] {
  return getIncomingThoughtIdsFromParent(thought, ConnectionKind.REPLY)
}

const getIncomingThoughtIdsFromParent = (thought: TextPost, edgeKind: ConnectionKind): string[] => {
  // all outbound connections, (outbound is direction toward the replies/children)
  const inbound: DirectionalEdgeMap = thought?.connections?.inbound ?? {}

  // for all the outbound edges, get all the ones of type reply.
  const thoughtIdArr = Object.entries(inbound)
    .map((nextThoughtEntry: [string, ConnectionMap]): string | null => {
      const [nextThoughtId, nextThoughtConnections] = nextThoughtEntry
      // only return nextThoughtId if nextThoughtConnections contains replies
      // set up a testing environment
      if (connectionMapContainsEdgeKind(nextThoughtConnections, edgeKind)) {
        return nextThoughtId
      }
      return null
    })
    .filter((e) => e)

  return thoughtIdArr
}

/**
 * Get textposts from thought id arr
 * @param ids
 * @param posts
 * @returns
 */
const getThoughtsFromIds = (ids: string[], posts: PostMap): TextPost[] => {
  return ids.map((e) => getThoughtFromId(e, posts)).filter((e): e is TextPost => e !== undefined)
}

const getThoughtFromId = (id: string, posts: PostMap) => (id in posts ? posts[id] : undefined)

/**
 * This function is used to get all of the reply thoughts to a given thought
 *  Written, for first use, in the deleteThoughtsAndReplies function
 * @param thought the parent thought, for which we want to retrieve replies
 * @param posts a list of all the posts in the whole shebang (helpful for ensuring each id corresponds to a real post still)
 * @returns an arr of replies
 */
export function getReplyThoughtsFromParent(thought: TextPost, posts: PostMap): TextPost[] {
  return getThoughtsFromIds(getReplyThoughtIdsFromParent(thought) ?? [], posts).reverse()
}

export function thoughtIsReply(thought: TextPost): boolean {
  const outbound = thought?.connections?.outbound ?? {}
  let isReply = false
  if (outbound) {
    //then see if any of the inbound ones are replies
    Object.values(outbound).forEach((connectionMap: any) => {
      //if one of these maps has a reply
      if (connectionMapContainsEdgeKind(connectionMap, ConnectionKind.REPLY)) {
        isReply = true
      }
    })
  }
  return isReply || thought?.isReply
}

const connectionMapContainsEdgeKind = (
  map: ConnectionMap,
  edgeKind: ConnectionKind = ConnectionKind.REPLY
) => {
  return Object.values(map ?? {}).filter((edge) => edge.edgeKind === edgeKind).length > 0
}

const getFlatEdgeMapFull = (
  dirEdgeMap: DirectionalEdgeMap,
  edgeKind?: ConnectionKind
): {
  [otherThoughtId: string]: EdgeInfoWithConnectionDataAndId
} => {
  const flatOne: { [otherThoughtId: string]: EdgeInfoWithConnectionDataAndId } = Object.entries(
    dirEdgeMap
  ).reduce((obj, otherThoughtEntry) => {
    //see if there's a reply in there
    const [, otherConnectionMap] = otherThoughtEntry

    //get all edges from the map
    const edgesOfDirThoughtPair = Object.entries(otherConnectionMap)

    const repliesOfDirThoughtPair = edgesOfDirThoughtPair.filter(
      (e) => !edgeKind || e[1].edgeKind === edgeKind
    )
    const allReplies: { [key: string]: EdgeInfoWithConnectionDataAndId } =
      repliesOfDirThoughtPair.reduce((map, next) => {
        return { ...map, [next[0]]: { ...next[1], id: next[0] } }
      }, {})
    // const firstReply: EdgeInfo =
    //   repliesOfDirThoughtPair.length > 0 ? repliesOfDirThoughtPair[0][1] : undefined //get the first
    // const mapForTheseReplies = convertArrToObj(repliesOfDirThoughtPair)
    const result: { [key: string]: EdgeInfoWithConnectionDataAndId } = { ...obj, ...allReplies }
    return result
  }, {})
  return flatOne
}

export const getFullEdgeMapArr = (dirEdgeMap: DirectionalEdgeMap, edgeKind?: ConnectionKind) =>
  Object.values(getFlatEdgeMapFull(dirEdgeMap, edgeKind))
