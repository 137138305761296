import { child, onValue } from "firebase/database"
import { useState, useEffect } from "react"
import FirebaseWriter from "../../Firebase/FirebaseWriter"

function usePersonName(backendWriter: FirebaseWriter, personId: string, providedName?: string) {
  const [name, setName] = useState<string>(providedName)

  useEffect(() => {
    // const handleResize = () => {
    //   setSize({ width: window.innerWidth, height: window.innerHeight });
    // };

    const dbRef = backendWriter?.databaseRef
    if (!dbRef) return
    const personNameRef = child(dbRef, `people/${personId}/personName`)
    const unsub = onValue(personNameRef, (snapshot) => {
      if (snapshot.exists()) {
        setName(snapshot.val())
      }
    })

    // Cleanup function
    return unsub
  }, []) // Empty dependency array means this effect runs once on mount and cleanup on unmount

  return name
}

export default usePersonName
