import React, { Dispatch, SetStateAction } from "react"
import { Descendant } from "slate"
import { PersonFirebaseBucket } from "../../Firebase/FirebaseDatabaseInterfaces"
import {
  ConnectionKind,
  SingleConnectionUpdateForAPerson,
  TextPost,
} from "../../ReactContexts/PostContext"
import { FriendMap } from "../../Types/types"

export type SimpleAppViewOptions = "thoughts" | "people" | "write" | "inbox" | "settings"
//inbox meant for notifications

export type InboundEdgesType = {
  [ConnectionKind.CONNECTION]?: SingleConnectionUpdateForAPerson[]
  [ConnectionKind.REPLY]?: SingleConnectionUpdateForAPerson[]
  [ConnectionKind.RELATED]?: SingleConnectionUpdateForAPerson[]
}
let SimpleAppPostContext = React.createContext<{
  rootPosts: TextPost[]
  setRootPosts: Dispatch<SetStateAction<TextPost[]>>
  allMyPosts: TextPost[]
  postsLoading: boolean
  myPostsLoading: boolean
  setMyPostsLoading: Dispatch<SetStateAction<boolean>>
  editorIsEmpty: boolean
  setEditorIsEmpty: Dispatch<SetStateAction<boolean>>
  editorIsFocused: boolean
  setEditorIsFocused: Dispatch<SetStateAction<boolean>>
  thoughtIdToExpand: string
  setThoughtIdToExpand: Dispatch<SetStateAction<string>>
  newThoughtEmbeddingsLoading: boolean
  setNewThoughtEmbeddingsLoading: Dispatch<SetStateAction<boolean>>
  appView: SimpleAppViewOptions
  setAppView: Dispatch<SetStateAction<SimpleAppViewOptions>>
  personBucket: PersonFirebaseBucket
  setPersonBucket: Dispatch<SetStateAction<PersonFirebaseBucket>>
  bookmarkedPosts: TextPost[]
  setBookmarkedPosts: Dispatch<SetStateAction<TextPost[]>>
  inboundEdges: InboundEdgesType
  setInboundEdges: Dispatch<SetStateAction<InboundEdgesType>>
  edgeCount: number
  setEdgeCount: Dispatch<SetStateAction<number>>
  friendMap: FriendMap,
  newFriendStatusCount: number,
}>({
  bookmarkedPosts: [],
  setBookmarkedPosts: () => {},
  rootPosts: [],
  setRootPosts: () => {},
  allMyPosts: [],
  editorIsEmpty: true,
  setEditorIsEmpty: () => undefined,
  editorIsFocused: true,
  setEditorIsFocused: () => undefined,
  thoughtIdToExpand: undefined,
  setThoughtIdToExpand: () => undefined,
  newThoughtEmbeddingsLoading: false,
  setNewThoughtEmbeddingsLoading: () => {},
  appView: "thoughts",
  setAppView: () => undefined,
  personBucket: undefined,
  setPersonBucket: () => undefined,
  postsLoading: true,
  myPostsLoading: true,
  setMyPostsLoading: () => undefined,
  inboundEdges: {},
  setInboundEdges: () => {},
  edgeCount: undefined,
  setEdgeCount: () => {},
  friendMap: {},
  newFriendStatusCount: undefined,
})
export default SimpleAppPostContext

export interface PostMap {
  [postId: string]: TextPost
}

export interface SimpleTextPost {
  id: string
  timestamp: number
  text: string
  authorId: string
  slateValue?: Descendant[]
}
