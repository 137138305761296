import { useContext, useEffect, useState } from "react"
import { useParams, useNavigate, Navigate } from "react-router-dom"
import AuthContext from "../../../ReactContexts/AuthContext"
import "./PersonDetailPage.css"
import { ArrowLeft, House, UserPlus } from "@phosphor-icons/react"
import useConnectionMap from "./GetConnectionMap"
import { TextPost } from "../../../ReactContexts/PostContext"
import FirebaseWriter from "../../../Firebase/FirebaseWriter"
import FeedItem from "../PostList/FeedItem"
import useAllMyThoughts from "../../CustomReactHooks/UseAllMyThoughts"
import usePersonName from "../../CustomReactHooks/UsePersonName"
import { simpleBackendWriter } from "../SimpleApp"
import { FRIEND_STATUS, FriendStatusObject } from "../../../Types/types"
import Button from "../Button/Button"
import { useGetFriendMap } from "./GetFriendMap"

const PersonDetailPage = () => {
  const { personId } = useParams()
  const { person } = useContext(AuthContext)
  const personName = usePersonName(simpleBackendWriter, personId)
  const navigate = useNavigate()
  const { allMyThoughts, postsLoading } = useAllMyThoughts(person?.uid)
  const connectionMap = useConnectionMap(allMyThoughts)
  const [unlockedThoughts, setUnlockedThoughts] = useState<TextPost[]>([])
  const friendMap = useGetFriendMap(simpleBackendWriter, person?.uid)
  const [friendStatus, setFriendStatus] = useState<FriendStatusObject>()

  useEffect(() => {
    const unlockedThoughtIds = Array.from(connectionMap?.get(personId) ?? [])
    FirebaseWriter.queryByIds(unlockedThoughtIds).then((thoughts) => {
      setUnlockedThoughts(thoughts)
    })
  }, [connectionMap, personId, postsLoading])

  useEffect(() => {
    const friendStatus = friendMap?.[personId]
    setFriendStatus(friendStatus)
  }, [friendMap])

  if (!person || !personId) return <Navigate to="/" />

  return (
    <div className="person-detail-page">
      <div
        style={{
          maxWidth: "500pt",
          width: "95%",
          height: "100%",
          padding: "1ex",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          className="simple-button-row-voices"
          style={{
            position: "relative",
            margin: "1ex",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            gap: ".5ex",
          }}
        >
          <ArrowLeft
            className="clickable-icon"
            size={28}
            onClick={() => {
              navigate(-1)
            }}
          />
          {/* <House
              className="clickable-icon"
              size={28}
              onClick={() => {
                navigate(`/p`)
              }}
            /> */}
          <div className="person-detail-name-container">
            <h1 className="person-detail-name">{personName}</h1>
            <Button
              onClick={(event) => {
                event.stopPropagation()
                simpleBackendWriter?.setFriendMapRecord(
                  person?.uid,
                  personId,
                  FRIEND_STATUS.OUTGOING_REQUEST
                )
              }}
              disabled={
                friendStatus?.status === FRIEND_STATUS.OUTGOING_REQUEST ||
                friendStatus?.status === FRIEND_STATUS.FRIENDS
              }
            >
              <UserPlus size={20} />
            </Button>
          </div>
        </div>
        {unlockedThoughts?.length > 0 ? (
          unlockedThoughts.map((thought) => {
            return (
              <FeedItem
                key={thought.id}
                {...{
                  id: thought.id,
                  text: thought.text,
                  timestamp: thought.timestamp,
                  authorName: thought?.authorName,
                  post: thought,
                  audioUrl: thought.audioUrl,
                  alreadyConnected: true,
                }}
              />
            )
          })
        ) : !postsLoading ? (
          <div>
            <p>you haven't unlocked any of {personName}'s thoughts yet</p>
          </div>
        ) : (
          <div>loading...</div>
        )}
      </div>
    </div>
  )
}

export default PersonDetailPage
