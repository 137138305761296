import { Dispatch, SetStateAction, useContext, useEffect, useState } from "react"
import "./SimpleApp.css"
import Create from "./Create/Create"
import PostList from "./PostList/PostList"
import SimpleAppPostContext, {
  InboundEdgesType,
  PostMap,
  SimpleAppViewOptions,
} from "./SimpleAppPostContext"

import {
  child,
  equalTo,
  get,
  getDatabase,
  limitToLast,
  onValue,
  orderByChild,
  query,
  ref,
  set,
} from "firebase/database"
import AuthContext from "../../ReactContexts/AuthContext"
import FirebaseWriter from "../../Firebase/FirebaseWriter"
import {
  ConnectionKind,
  SingleConnectionUpdateForAPerson,
  TextPost,
} from "../../ReactContexts/PostContext"
import { thoughtIsReply } from "../../Firebase/ReplyUtilities"
import { PersonFirebaseBucket } from "../../Firebase/FirebaseDatabaseInterfaces"
import { filterArrayToJustHaveUniqueItems } from "./PostList/PostList"
import { appShortcutInstructionsKey, auth } from "../App"
import { isAppShortcut } from "../.."
import LogRocket from "logrocket"
import { getEdgeAuthor } from "../../Logic/ConnectionLogic"
import ConnectionInbox from "./Inbox/ConnectionInbox/ConnectionInbox"
import AudioRecorder, { buttonStyle, WhatsAppInvite } from "../Editor/AudioRecorder"
import People from "./People/People"
import { FRIEND_STATUS, FriendMap, FriendStatusObject } from "../../Types/types"
import { getFriendMapRef } from "./People/GetFriendMap"
import useAllMyThoughts from "../CustomReactHooks/UseAllMyThoughts"
import { Gear, Microphone, Playlist } from "@phosphor-icons/react"
import { Stack } from "@phosphor-icons/react/dist/ssr"

// Attempt to parse posts from local storage
let parsedPosts: PostMap | undefined
const stringPosts = localStorage.getItem("posts")
if (stringPosts) {
  try {
    parsedPosts = JSON.parse(stringPosts)
  } catch (error) {
    console.error("Failed to parse posts from local storage", error)
  }
}

// Define local storage keys
const appViewLocalStorageKey = "voices-app-view"
const lastActiveLocalStorageKey = "voices-last-active"

// Attempt to retrieve view options from local storage
let providedAppView: SimpleAppViewOptions | undefined
const storedAppView = localStorage.getItem(appViewLocalStorageKey)
if (storedAppView) {
  providedAppView = storedAppView as SimpleAppViewOptions
}

// Attempt to retrieve last active time from local storage
let providedLastActive: number | undefined
const storedLastActive = localStorage.getItem(lastActiveLocalStorageKey)
if (storedLastActive) {
  providedLastActive = Number(storedLastActive)
}

// Initialize Firebase writer
export let simpleBackendWriter: FirebaseWriter = new FirebaseWriter()

const countNewerEdgesInternal = (
  edgeArr: SingleConnectionUpdateForAPerson[],
  lastPeakedAllUpdates: number
): number => {
  if (typeof lastPeakedAllUpdates !== "number" || !edgeArr) {
    return 0
  }

  return edgeArr.filter((edge) => edge.timestamp > lastPeakedAllUpdates).length
}

function SimpleApp() {
  const [rootPosts, setRootPosts] = useState<TextPost[]>()
  const [friendMap, setFriendMap] = useState<FriendMap>()
  const { person } = useContext(AuthContext)
  const { allMyThoughts, postsLoading } = useAllMyThoughts(person?.uid)
  useEffect(() => {
    const db = getDatabase()
    const lastLoginRef = ref(db, "p/forum/people/" + person.uid + "/timestamps/lastLogin")
    set(lastLoginRef, Date.now())

    const newRootPosts = allMyThoughts.filter((node) => !thoughtIsReply(node as TextPost))
    setRootPosts(newRootPosts)
  }, [allMyThoughts, person])

  //initialize simple backend writer
  useEffect(() => {
    if (!simpleBackendWriter?.databaseRef && person) {
      const db = getDatabase()
      const databaseRef = ref(db, `p/forum/`)
      //const person name? //TODO come back to this , initialize personBucket if doesn't already exist, etc.
      simpleBackendWriter.initialize(databaseRef, person.uid, person.email, undefined)
    }

    if (person)
      LogRocket.identify(person?.uid, {
        name: person?.displayName,
        email: person?.email,

        // Add your own custom user variables here, ie:
        // subscriptionType: 'pro'
      })
  }, [person])

  //personBucket
  const [personBucket, setPersonBucket] = useState<PersonFirebaseBucket>()

  const [inboundEdges, setInboundEdges] = useState<InboundEdgesType>()
  //whenever inbound edges changes, calculate new number
  useEffect(() => {
    countNewEdges(
      inboundEdges,
      setEdgeCount,
      person.uid,
      personBucket?.timestamps?.lastPeakedAllUpdates
    )
  }, [inboundEdges, person?.uid, personBucket?.timestamps?.lastPeakedAllUpdates])
  const [edgeCount, setEdgeCount] = useState<number>(0)
  const [newThoughtCount, setNewThoughtCount] = useState<number>(0)

  const [newFriendStatusCount, setNewFriendStatusCount] = useState<number>(0)
  useEffect(() => {
    countNewFriendStatuses(
      friendMap,
      setNewFriendStatusCount,
      personBucket?.timestamps?.lastPeakedFriendMap
    )
  }, [friendMap, person?.uid, personBucket?.timestamps?.lastPeakedFriendMap])

  useEffect(() => {
    const inboundEdgesRef = child(
      simpleBackendWriter?.databaseRef,
      `/people/${person.uid}/connections/inbound`
    )
    const inboundConnectionsRef = query(
      inboundEdgesRef,
      orderByChild("edgeKind"),
      equalTo(ConnectionKind.CONNECTION),
      limitToLast(50)
    )

    const inboundRepliesRef = query(
      inboundEdgesRef,
      orderByChild("edgeKind"),
      equalTo(ConnectionKind.REPLY),
      limitToLast(50)
    )

    const inboundRelatedRef = query(
      inboundEdgesRef,
      orderByChild("edgeKind"),
      equalTo(ConnectionKind.RELATED),
      limitToLast(50)
    )

    //no more connections
    // get(inboundConnectionsRef)
    //   .then((snap) => {
    //     if (snap.exists()) {
    //       const theValue = snap.val()
    //       setInboundEdges((x) => {
    //         // Update the state with the new value
    //         const updatedEdges = { ...x, [ConnectionKind.CONNECTION]: theValue }
    //         // Now, with the updated value, set the edge count

    //         return updatedEdges
    //       })
    //     } else setInboundEdges({})
    //   })
    //   .catch((e) => console.error("error with inbound edges", e))

    get(inboundRelatedRef)
      .then((snap) => {
        if (snap.exists()) {
          const theValue = snap.val()
          setInboundEdges((x) => {
            // Update the state with the new value
            const updatedEdges = { ...x, [ConnectionKind.RELATED]: theValue }
            // Now, with the updated value, set the edge count

            return updatedEdges
          })
        } else setInboundEdges({})
      })
      .catch((e) => console.error("error with inbound edges", e))

    get(inboundRepliesRef)
      .then((snap) => {
        if (snap.exists()) {
          const theValue = snap.val()
          setInboundEdges((x) => {
            // Update the state with the new value
            const updatedEdges = { ...x, [ConnectionKind.REPLY]: theValue }
            // Now, with the updated value, set the edge count

            return updatedEdges
          })
        }
      })
      .catch((e) => console.error("error with inbound edges", e))

    get(inboundRelatedRef)
      .then((snap) => {
        if (snap.exists()) {
          const theValue = snap.val()
          setInboundEdges((x) => {
            // Update the state with the new value
            const updatedEdges = { ...x, [ConnectionKind.RELATED]: theValue }
            // Now, with the updated value, set the edge count

            return updatedEdges
          })
        }
      })
      .catch((e) => console.error("error with inbound edges", e))
  }, [person, personBucket?.connections?.inbound, personBucket?.timestamps?.lastPeakedAllUpdates])

  useEffect(() => {
    const db = getDatabase()
    if (!person || !("uid" in person)) return
    const personRef = ref(db, "p/" + "forum" + "/people/" + person?.uid) //nodes are posts
    //get person data on the front end
    onValue(personRef, (data) => {
      let personVal: PersonFirebaseBucket
      if (data.exists()) {
        personVal = data.val()
        setPersonBucket(personVal)
      } else {
        //otherwise, initialize
        const personBucket: PersonFirebaseBucket = {
          enteredDoor: null,
          personName: null,
          personEmail: person.email,
        }
        set(personRef, personBucket)
      }
    })

    //grab firebase display name etc.
  }, [person])

  //get person name if doesn't exist
  useEffect(() => {
    //check if name exists
    const nameDoesntExist = personBucket && !personBucket?.personName
    const db = getDatabase()
    if (!person || !("uid" in person)) return

    const personNameRef = ref(db, "p/" + "forum" + "/people/" + person?.uid + "/personName") //nodes are posts
    get(personNameRef).then((snap) => {
      if (snap.exists()) {
        simpleBackendWriter.setName(snap.val())
      } else {
        const requestPersonName = () => {
          let name = ""
          while (!name) {
            name =
              window.prompt("What should we call you? (We recommend your favorite emoji.)") ?? ""
          }
          return name
        }
        const theirName = requestPersonName()
        //update personBucket
        set(personNameRef, theirName)
      }
    })

    if (personBucket?.personName) simpleBackendWriter.setName(personBucket.personName)
  }, [personBucket])

  useEffect(() => {
    if (!person || !("uid" in person)) return
    const db = getDatabase()
    const dbRef = ref(db, "p/forum")
    const friendMapRef = getFriendMapRef(dbRef, person?.uid)
    const unsub = onValue(friendMapRef, (snapshot) => {
      if (snapshot.exists()) {
        setFriendMap(snapshot.val())
      }
    })

    return unsub
  }, [person])

  //initialize the connected/bookmarked posts
  const [bookmarkedPosts, setBookmarkedPosts] = useState<TextPost[]>()

  const [editorIsEmpty, setEditorIsEmpty] = useState(true)
  const [editorIsFocused, setEditorIsFocused] = useState(false)
  const [myPostsLoading, setMyPostsLoading] = useState(true)

  const [thoughtIdToExpand, setThoughtIdToExpand] = useState<string>()
  const [newThoughtEmbeddingsLoading, setNewThoughtEmbeddingsLoading] = useState(false)
  //1000 seconds threshold

  const [appView, setAppView] = useState<SimpleAppViewOptions>(
    Date.now() - Number(localStorage.getItem(lastActiveLocalStorageKey)) < 1000000
      ? (localStorage.getItem(appViewLocalStorageKey) as SimpleAppViewOptions) ?? "write"
      : "write"
  )
  useEffect(() => {
    localStorage.setItem(lastActiveLocalStorageKey, String(Date.now()))
    localStorage.setItem(appViewLocalStorageKey, appView)
  }, [appView])

  //PWA dialog
  // return <div id="divInstallApp"></div>

  //commenting out for now, not effective.
  // useEffect(() => {
  //   if (!isAppShortcut && !localStorage.getItem(appShortcutInstructionsKey)) {
  //     window.alert(`Plexus works best as an iPhone app! \n\nHere are three steps to get it...`)
  //     window.alert(
  //       `On your iPhone...\n\n1. Go to plexus.earth on Safari\n2. Click Safari's share icon\n 3. Click "Add to Home Screen"`
  //     )
  //     window.alert("That's it :)")
  //     localStorage.setItem(appShortcutInstructionsKey, "done!!")
  //   }
  // }, [])
  return (
    <SimpleAppPostContext.Provider
      value={{
        rootPosts,
        setRootPosts,
        editorIsEmpty,
        setEditorIsEmpty,
        thoughtIdToExpand,
        setThoughtIdToExpand,
        newThoughtEmbeddingsLoading,
        setNewThoughtEmbeddingsLoading,
        appView,
        setAppView,
        personBucket,
        setPersonBucket,
        editorIsFocused,
        setEditorIsFocused,
        bookmarkedPosts,
        setBookmarkedPosts,
        postsLoading,
        myPostsLoading,
        setMyPostsLoading,
        inboundEdges,
        setInboundEdges,
        edgeCount,
        setEdgeCount,
        allMyPosts: allMyThoughts,
        friendMap,
        newFriendStatusCount,
      }}
    >
      <div className="App">
        <div className="panel-container">
          <div className="panel">
            <div className="app-view-toggle row">
              {["write", "thoughts", "settings"].map((view: SimpleAppViewOptions) => (
                <div
                  key={view}
                  className={`toggle-option ${appView === view ? "selected" : ""} ${
                    view === "people" && newFriendStatusCount > 0 ? "bold-view" : ""
                  }`}
                  onClick={() => setAppView(view)}
                >
                  {view === "write" ? (
                    <Microphone />
                  ) : view === "thoughts" ? (
                    <Playlist />
                  ) : view === "settings" ? (
                    <Gear />
                  ) : (
                    view.toUpperCase()
                  )}
                  {view === "people" && newFriendStatusCount > 0 ? ` ${newFriendStatusCount}` : ""}
                </div>
              ))}
            </div>
            {appView === "thoughts" && <PostList />}
            {appView === "write" && <Create />}

            {appView === "inbox" && <ConnectionInbox />}
            {appView === "people" && <People />}

            {appView === "settings" && (
              <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                <br></br>
                <br></br>
                <br></br>
                More Features
                <button
                  className="slow-spinner"
                  style={buttonStyle}
                  onClick={() => {
                    window.alert(
                      `Plexus is best as an iPhone app! Here's four steps to download it--`
                    )
                    window.alert(
                      `1. Open Safari on your iPhone. \n2. Go to plexus.earth\n3. Click the Safari share icon (arrow pointing up)\n 4. Click "Add to Home Screen"\n\nThat's it.`
                    )
                  }}
                >
                  Get iPhone App
                </button>
                <button
                  style={buttonStyle}
                  className="slow-spinner"
                  onClick={() => setAppView("people")}
                >
                  Go to People section
                </button>
                <button
                  style={buttonStyle}
                  className="slow-spinner"
                  onClick={() => setAppView("inbox")}
                >
                  Go to Replies Inbox
                </button>
                <br></br> Account Info
                <button
                  className="slow-spinner"
                  style={buttonStyle}
                  onClick={() => {
                    if (window.confirm("log out of " + person.email + "?")) {
                      auth
                        .signOut()
                        .then(() => console.log(person.uid, person.email, "signed out"))
                        .catch(() => console.warn(person.uid, person.email, "error signing out"))
                      window.location.reload()
                    }
                  }}
                >
                  Log out of <u>{person?.email}</u>
                </button>
                <button
                  className="slow-spinner"
                  style={buttonStyle}
                  onClick={() => {
                    const newNameX = window.prompt(
                      "What would you like your new display name to be?",
                      personBucket?.personName
                    )
                    if (newNameX) {
                      simpleBackendWriter
                        .setName(newNameX)
                        .then(() => {
                          window.alert(
                            "On all your future steps, " +
                              newNameX +
                              " will be your new display name. Past steps will still have the same name."
                          )
                        })
                        .catch((e) => window.alert("An error! Send this to Davey... " + e))
                    }
                  }}
                >
                  Change display name (currently "{personBucket?.personName ?? "..."}")
                </button>
                <br></br>More Links
                <WhatsAppInvite />
                <a className="slow-spinner" style={buttonStyle} href="plexus.earth/landing">
                  Go to landing page
                </a>
              </div>
            )}
            {/* <div className="post-list-footer">
              <Create />
            </div> */}
          </div>
        </div>
      </div>
    </SimpleAppPostContext.Provider>
  )
}

export default SimpleApp

function countNewEdges(
  inboundEdges: InboundEdgesType,
  setNumber: Dispatch<SetStateAction<number>>,
  thisPersonId: string,
  lastPeakedTime: number
): number {
  const incomingReplies = Object.values(inboundEdges?.REPLY ?? {})
  const incomingRelated = Object.values(inboundEdges?.RELATED ?? {})

  const withoutSelfActions = [...incomingReplies, ...incomingRelated].filter(
    (e) => getEdgeAuthor(e) !== thisPersonId
  )
  const allIncoming = filterArrayToJustHaveUniqueItems(
    withoutSelfActions,
    (a, b) => a?.sourceId === b?.sourceId
    // && a?.targetThoughtId === b?.targetThoughtId
    //only count the new thoughts
  )
  const numUnseen = countNewerEdgesInternal(allIncoming, lastPeakedTime)
  setNumber(numUnseen)
  return numUnseen
}

const countNewerFriendStatuses = (
  friendStatuses: FriendStatusObject[],
  lastPeakedTime: number
): number => {
  if (typeof lastPeakedTime !== "number" || !friendStatuses) {
    return 0
  }

  return friendStatuses.filter((friendMap) => friendMap.timestamp > lastPeakedTime).length
}

function countNewFriendStatuses(
  friendMap: FriendMap,
  setNumber: Dispatch<SetStateAction<number>>,
  lastPeakedTime: number
): number {
  const incomingFriendStatuses = Object.values(friendMap ?? {}).filter((friendStatus) => {
    return (
      friendStatus.status === FRIEND_STATUS.INCOMING_REQUEST ||
      friendStatus.status === FRIEND_STATUS.UNLOCKED ||
      friendStatus.status === FRIEND_STATUS.FRIENDS
    )
  })

  const numUnseen = countNewerFriendStatuses(incomingFriendStatuses, lastPeakedTime)
  setNumber(numUnseen)
  return numUnseen
}
