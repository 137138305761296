import { Editor, Node } from "slate"

export const getSlateEditorString = (editor: Editor) => {
  const iterator = Node.texts(editor)
  let nextString = iterator.next()
  let result = ""
  while (!nextString.done) {
    //@ts-ignore
    result += nextString.value[0].text + "\n"
    nextString = iterator.next()
  }

  return result
}
