import { getDatabase, onValue, ref } from "firebase/database"
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { PostMap, TextPost } from "../../../ReactContexts/PostContext"

function sameDay(d1: any, d2: any) {
  return (
    d1.getFullYear() === d2.getFullYear() &&
    d1.getMonth() === d2.getMonth() &&
    d1.getDate() === d2.getDate()
  )
}

const HackyAdminLastWeek = () => {
  const { placeId } = useParams()
  const [allThoughts, setAllThoughts] = useState([])

  useEffect(() => {
    if (!placeId) return
    const db = getDatabase()
    const postsRef = ref(db, "p/" + placeId + "/nodes") //nodes are posts
    onValue(postsRef, (snapshot) => {
      if (snapshot.exists()) {
        const value = snapshot.val()
        setAllThoughts(Object.values(value).reverse())
      }
    })
  }, [placeId])
  const NewAppReleaseDate = () => new Date(2022, 10, 28)
  const thoughtsThisWeek = allThoughts.filter((e) => e.timestamp > NewAppReleaseDate().getTime())

  const getThoughtsPerPerson = (thoughts: TextPost[]) => {
    const thoughtsPerPerson: { [authorEmail: string]: PostMap } = thoughts.reduce(
      (soFarPerPerson: { [authorEmail: string]: PostMap }, nextThought: TextPost) => {
        if (!nextThought.authorEmail) return soFarPerPerson
        const existingEntry =
          nextThought.authorEmail in soFarPerPerson ? soFarPerPerson[nextThought?.authorEmail] : {}
        const newPersonEntry = { ...existingEntry, [nextThought.id]: nextThought }

        return {
          ...soFarPerPerson,
          [nextThought.authorEmail]: newPersonEntry,
        }
      },
      {}
    )
    return thoughtsPerPerson
  }
  const thoughtsPerPerson = getThoughtsPerPerson(thoughtsThisWeek)

  //per author
  const sortedPersonDays = getDaysPerPerson(thoughtsPerPerson)

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      People who contributed since nov 28th (new app release date): {sortedPersonDays.length} people
      {sortedPersonDays
        .filter(([_, days]: [_: any, days: any]) => days.length > 0) //used to be >1
        .map(([authorEmail, _days]: [authorEmail: string, days: any]) => {
          return (
            <div>
              {/* contributed on {days.length} of the days:  */}
              {authorEmail}
            </div>
          )
        })}
      <br></br>
      {thoughtsThisWeek.length} thoughts since 11/28.
    </div>
  )
}

export default HackyAdminLastWeek

const getDaysPerPerson: any = (thoughtsPerPerson: { [authorEmail: string]: PostMap }) => {
  const daysPerPerson: { [authorEmail: string]: Date[] } = Object.entries(thoughtsPerPerson).reduce(
    (mapSoFar, entry) => {
      const [personEmail, postMap] = entry
      //for each postMap, find number of days
      let representativeDateDays: Date[] = []
      Object.values(postMap).forEach((post) => {
        if (!post.timestamp) return
        if (
          representativeDateDays.filter((date) => date && sameDay(date, new Date(post.timestamp)))
            .length === 0
        )
          representativeDateDays.push(new Date(post.timestamp))
      })
      return { ...mapSoFar, [personEmail]: representativeDateDays }
    },
    {}
  )
  return Object.entries(daysPerPerson).sort((a, b) => b[1].length - a[1].length)
}

//Functions for finding authors other than others last week
