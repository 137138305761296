import { ConnectionMap, TextPost } from "../../ReactContexts/PostContext"

// Returns total number of connections for inbound and outbound edges
export const getNumberOfConnectedThoughts = (
  thought: TextPost,
  includeAnti?: true,
  minTime: number = 0,
  maxTime: number = Infinity
) => {
  const edgeCount = {
    outbound: 0,
    inbound: 0,
  }
  // Ensure edgeList exists
  if (!("edgeList" in thought)) return edgeCount
  // Saving all nodes with their edges of a given thought
  const edges = Object.entries(thought["edgeList"])
  // Loops through each node and increments if inbound, outbound, or both
  for (let i = 0; i < edges.length; i++) {
    const theEdges = edges[i][1]
    if ("outbound" in theEdges && (includeAnti || !("anti" in theEdges.outbound))) {
      if (theEdges?.outbound?.timestamp >= minTime && theEdges?.outbound?.timestamp <= maxTime) {
        edgeCount.outbound++
      }
    }
    if ("inbound" in theEdges && (includeAnti || !("anti" in theEdges.inbound))) {
      if (theEdges?.inbound?.timestamp >= minTime && theEdges?.inbound?.timestamp <= maxTime) {
        edgeCount.inbound++
      }
    }
  }
  return edgeCount
}

// Collects every reply of a given thought
export const getAllReplies = (thought: TextPost) => {
  console.log(thought)
  let replies: ConnectionMap[] = []
  if (!("connections" in thought)) {
    return replies
  }
  if (!("inbound" in thought.connections)) {
    return replies
  }
  let inboundConnections = thought.connections.inbound
  for (const connection in inboundConnections) {
    if (Object.values(inboundConnections[connection])[0]?.edgeKind === "REPLY") {
      replies.push(inboundConnections[connection])
    }
  }
  return replies
}
