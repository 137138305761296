import { useNavigate } from "react-router-dom"
import { ConnectionKind } from "../../../../ReactContexts/PostContext"
import { getTwitterLikeAbbreviation } from "../../../../util"
import FeedItem, { abbreviateByWords } from "../../PostList/FeedItem"
import { simpleBackendWriter } from "../../SimpleApp"
import { InboxItemInterface } from "../Inbox"
import "./InboxItem.css"

const InboxItem = ({ item }: { item: InboxItemInterface }) => {
  const navigate = useNavigate()

  const myThought = item.targetThought
  return (
    <div className="inbox-item-container">
      <span style={{ margin: "0" }}>
        {/* {item.caption} */}
        <div style={{ fontSize: ".9em", fontStyle: "italic", color: "gray" }}>
          {item?.sourceThought?.authorName +
            (item.edge.edgeKind === ConnectionKind.RELATED ? " related to " : " replied to ")}
          <span
            onClick={() => {
              navigate(`/idea/${item?.targetThought.id}`)
            }}
            className={"thought-title-link"}
          >
            ({abbreviateByWords(myThought?.title ?? myThought?.text, 4).trim()})
          </span>
          {/* {item.edge.edgeKind === ConnectionKind.CONNECTION ? " via " : ""} */}
          &nbsp;
          <span style={{ color: "gray" }}>
            ({getTwitterLikeAbbreviation(new Date(item.edge.timestamp))})
          </span>
        </div>
      </span>

      <div style={{ opacity: 1 }}>
        {true || item.edge.edgeKind === ConnectionKind.REPLY ? (
          item.sourceThought ? (
            <FeedItem
              {...{
                id: item.sourceThought.id,
                text: item.sourceThought.text,
                timestamp: item.sourceThought.timestamp,
                writtenByMe: item.sourceThought.authorId === simpleBackendWriter.personId,
                depth: 0,
                audioUrl: item?.sourceThought?.audioUrl,
                authorName: item?.sourceThought.authorName,
                post: item?.sourceThought,
                fromPost: item?.targetThought,
              }}
            />
          ) : (
            <></>
          )
        ) : (
          <></>
        )}
      </div>
    </div>
  )
}

export default InboxItem
