import { getDatabase, limitToLast, onValue, orderByChild, query, ref } from "firebase/database"
import { useContext, useEffect, useState } from "react"
import AuthContext from "../../../ReactContexts/AuthContext"
import {
  ConnectionKind,
  EdgeInfoWithConnectionDataAndId,
  TextPost,
} from "../../../ReactContexts/PostContext"
import { getTwitterLikeAbbreviation } from "../../../util"
import { filterArrayToJustHaveUniqueItems } from "../../../Components/SimplePlexusComponents/PostList/PostList"

interface PersonInfo {
  personEmail: string
  personId: string
  personName?: string
  thoughtCount?: number
  wordCount?: number
}
const VoicesDashboard = () => {
  const { person } = useContext(AuthContext)
  const hasSecurityClearance =
    person?.email?.includes("@plexus") || person?.email?.includes("aeliasnet@gmail.com")
  const [allRawThoughts, setAllRawThoughts] = useState<TextPost[]>([])
  const [allThoughts, setAllThoughts] = useState<TextPost[]>([])
  const [allPeople, setAllPeople] = useState<{ [personId: string]: PersonInfo }>()
  const [allListens, setAllListens] = useState<EdgeInfoWithConnectionDataAndId[]>([])
  const [allReplies, setAllReplies] = useState<EdgeInfoWithConnectionDataAndId[]>([])
  const [hideStats, setHideStats] = useState<boolean>(false)
  const [selectedPerson, setSelectedPerson] = useState(null)
  const [howManySaturdaysAgo, setHowManySaturdaysAgo] = useState(0)
  const cutoffTime = getPastSaturday(howManySaturdaysAgo)
  const [howManyThoughtsMax, setHowManyThoughtsMax] = useState(15000)

  // Loads data after placeId is identified
  useEffect(() => {
    if (!hasSecurityClearance) return
    const db = getDatabase()
    const domainId = "forum"

    const postsRef = ref(db, "p/" + domainId + "/nodes") // nodes are posts
    const recentPostsQuery = query(
      postsRef,
      orderByChild("timestamp"),
      limitToLast(howManyThoughtsMax)
    )
    onValue(recentPostsQuery, (snapshot) => {
      if (snapshot.exists()) {
        const value = snapshot.val()
        // Convert to array
        const newThoughts = Object.values(value ?? []).reverse() as TextPost[]

        setAllRawThoughts(newThoughts)
      }
    })
    document.title = "Voices Admin - Plexus"
  }, [howManyThoughtsMax, hasSecurityClearance])

  useEffect(() => {
    const filteredNewThoughts = (allRawThoughts ?? []).filter((e) => e.timestamp > cutoffTime)

    setAllThoughts(filteredNewThoughts)

    const newPeopleMap = filteredNewThoughts
      .filter((e) => e.authorId) // Ensure we have the authorId
      .reduce(
        (
          peopleSoFar: {
            [personId: string]: PersonInfo & { thoughtCount: number; wordCount: number }
          },
          nextThought
        ) => {
          const currentPerson = peopleSoFar[nextThought.authorId] || {
            wordCount: 0,
            thoughtCount: 0,
          }
          const wordCount = nextThought.text ? nextThought.text.split(/\s+/).length : 0
          return {
            ...peopleSoFar,
            [nextThought.authorId]: {
              personEmail: nextThought.authorEmail,
              personId: nextThought.authorId,
              personName: nextThought.authorName,
              thoughtCount: currentPerson.thoughtCount + 1,
              wordCount: currentPerson.wordCount + wordCount, // Add the word count here
            },
          }
        },
        {}
      )
    setAllPeople(newPeopleMap)

    // Squashes all the connections of all thoughts in filteredNewThoughts into a single array
    const connections = filteredNewThoughts
      .map((thought) => [
        ...Object.values(thought?.connections?.inbound ?? {}),
        ...Object.values(thought?.connections?.outbound ?? {}),
      ])
      .flat()
      .map((edge) => [...Object.values(edge)])
      .flat()

    const uniqueConnections = filterArrayToJustHaveUniqueItems(
      connections,
      (a, b) => a?.sourceId === b?.sourceId && a?.targetThoughtId === b?.targetThoughtId
    )

    const listens = uniqueConnections.filter((edge) => edge.edgeKind === ConnectionKind.CONNECTION)
    setAllListens(listens)
    const replies = uniqueConnections.filter((edge) => edge.edgeKind === ConnectionKind.REPLY)
    setAllReplies(replies)
  }, [allRawThoughts, howManySaturdaysAgo])

  const peopleArr = Object.entries(allPeople ?? {})
  const [expanded, setExpanded] = useState<{ thoughts: boolean; people: boolean }>({
    thoughts: true,
    people: true,
  })

  return hasSecurityClearance ? (
    <div>
      <h2>plexus voices dashboard</h2>
      <div>
        Since 12am{" "}
        <u
          onClick={() => {
            const howMany = window.prompt(
              "how many saturdays back to go?",
              String(howManySaturdaysAgo)
            )
            const number = Number(howMany)
            if (typeof number === "number") setHowManySaturdaysAgo(number)
          }}
        >
          {timestampToDate(cutoffTime)}
        </u>{" "}
        <u
          onClick={() => {
            const howMany = window.prompt(
              "how many thoughts to fetch (affects load time)?",
              String(howManyThoughtsMax)
            )
            const number = Number(howMany)
            if (typeof number === "number" && number >= 1) setHowManyThoughtsMax(number)
          }}
        >
          (max {howManyThoughtsMax} thoughts)
        </u>
      </div>
      <div>
        <h4 onClick={() => setExpanded((x) => ({ ...x, people: !x.people }))}>
          [{expanded.people ? "-" : "+"}] People {peopleArr?.length}
        </h4>
        {expanded.people ? (
          <>
            <i onClick={() => setHideStats(!hideStats)}>Toggle Stats</i>
            {!allPeople ? <div>loading people...</div> : <></>}
            {(expanded.people ? peopleArr : [])
              .sort(([, a], [, b]) => b.wordCount - a.wordCount)
              .map(([personId, personInfo]) => {
                const isPersonSelected = personId === selectedPerson

                return (
                  <div>
                    <div
                      style={{ margin: "0 1ex", cursor: "pointer" }}
                      onClick={() => setSelectedPerson(isPersonSelected ? null : personId)}
                    >
                      {personInfo.personEmail}
                      <br></br>
                      {!hideStats ? (
                        <>
                          &ensp; Words: {personInfo.wordCount}, &ensp; Thoughts:{" "}
                          {personInfo.thoughtCount}
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                    {isPersonSelected &&
                      allThoughts
                        .filter((thought) => thought.authorId === personId)
                        .map((thought) => (
                          <div style={{ margin: "2ex" }}>
                            {/* Display thought details */}
                            {thought.id} |
                            <a href={"https://plexus.earth/idea/" + thought.id} target="_blank">
                              link
                            </a>{" "}
                            |{getTwitterLikeAbbreviation(new Date(thought.timestamp ?? 0))}{" "}
                            <br></br>
                            {thought.text}
                          </div>
                        ))}
                  </div>
                )
              })}
          </>
        ) : (
          <></>
        )}
      </div>

      <h4 onClick={() => setExpanded((x) => ({ ...x, thoughts: !x.thoughts }))}>
        {" "}
        [{expanded.thoughts ? "-" : "+"}] Thoughts {allThoughts?.length}
      </h4>
      {!allThoughts ? <div>loading thoughts...</div> : <></>}
      {expanded.thoughts ? (
        (allThoughts ?? [])
          .filter((e) => e?.text && e?.authorEmail)
          .map((thought) => (
            <div style={{ margin: "2ex" }}>
              <i>
                {thought.authorName} - {thought.authorEmail} - {thought?.authorId} -{" "}
                <a href={"https://plexus.earth/idea/" + thought.id} target="_blank">
                  link
                </a>{" "}
                -{getTwitterLikeAbbreviation(new Date(thought.timestamp ?? 0))} - {thought?.id}
              </i>{" "}
              <br></br>
              {thought.text}
            </div>
          ))
      ) : (
        <></>
      )}

      <h4>Listens {allListens?.length}</h4>

      <h4>Replies {allReplies?.length}</h4>
    </div>
  ) : (
    <>admin panel only accessible to Plexus team</>
  )
}

export default VoicesDashboard

function getPastSaturday(saturdaysAgo = 0) {
  const now = new Date()
  const dayOfWeek = now.getDay()
  const numDaysToLastSaturday = (dayOfWeek === 0 ? 7 : dayOfWeek) + 1
  now.setDate(now.getDate() - numDaysToLastSaturday - saturdaysAgo * 7)
  now.setHours(0, 0, 0, 0)
  return now.getTime()
}
function timestampToDate(timestamp) {
  const date = new Date(timestamp)
  return date.toLocaleDateString()
}
function average(values) {
  const sum = values.reduce((a, b) => a + b, 0)
  return sum / values.length
}
function averageProperty(objects, property) {
  const values = objects.map((obj) => obj[property])
  const sum = values.reduce((a, b) => a + b, 0)
  return sum / values.length
}
