import { useState, useEffect } from "react"
import { PostMap, TextPost } from "../../ReactContexts/PostContext"
import {
  getDatabase,
  query,
  ref,
  orderByChild,
  equalTo,
  limitToLast,
  onValue,
} from "firebase/database"
import FirebaseWriter from "../../Firebase/FirebaseWriter"

function useAllMyThoughts(personId: string) {
  const [allMyThoughts, setAllMyThoughts] = useState<TextPost[]>([])
  const [postsLoading, setPostsLoading] = useState<boolean>(true)

  useEffect(() => {
    const db = getDatabase()
    const nodesRef = ref(db, "p/forum/nodes")
    const nodesByAuthor = query(
      nodesRef,
      orderByChild("authorId"),
      equalTo(personId),
      limitToLast(200)
    )
    onValue(nodesByAuthor, (snapshot) => {
      // Get an array of the node objects that match the query
      setPostsLoading(false)
      const nodesMap: PostMap = snapshot.val()

      // Add the nodes to the cache
      for (let nodeId in nodesMap) {
        FirebaseWriter.addToCache(nodeId, nodesMap[nodeId] as TextPost)
      }

      // Set the state with the array of nodes
      const definedPosts =
        Object.values(nodesMap ?? {})
          .filter((node) => node.text && node.audioUrl)
          .reverse() ?? [] //reverse so it's chronlogical

      setAllMyThoughts(definedPosts)
    })
  }, [personId])

  return { allMyThoughts, postsLoading }
}

export default useAllMyThoughts
