import { useState, CSSProperties, useContext } from "react"
import "./AudioRecorder.css"
import AuthContext from "../../ReactContexts/AuthContext"
import { auth } from "../App"
import { simpleBackendWriter } from "../SimplePlexusComponents/SimpleApp"
import SimpleAppPostContext from "../SimplePlexusComponents/SimpleAppPostContext"
import AudioRecorderButton from "./AudioRecorderButton"
import { AppendsPost } from "../SimplePlexusComponents/Create/Create"

const AudioRecorder: React.FC<{
  appendPost: AppendsPost
}> = ({ appendPost }) => {
  const { person } = useContext(AuthContext)
  const { personBucket } = useContext(SimpleAppPostContext)

  const [tutorialExpanded, setTutorialExpanded] = useState(false)

  const [promptsExpanded, setPromptsExpanded] = useState(false)
  const [whatsappInviteExpanded, setWhatappInviteExpanded] = useState(false)
  return (
    <div className={"recording-container"}>
      <AudioRecorderButton appendPost={appendPost} />

      {/* <br></br> */}

      {/* 
      <div
        className={"question-mark " + (promptsExpanded ? " expanded" : "pulse-slow")}
        // style={{ display: isRecording ? "none" : "default" }}
        onClick={() => setPromptsExpanded((x) => !x)}
        title="Get a spark of inspiration..."
      >
        ?
      </div> */}
      {/* {promptsExpanded ? (
        <div
          style={{
            textAlign: "center",
            fontFamily: "Optima",
            fontSize: "1em",
          }}
        >
          <span style={{ opacity: 1 }}>
            What kind of art's inspiring you?
            <br></br>
            <span style={{ opacity: 0.6 }}>2/12/24 Prompt</span>
          </span>{" "}
          <br></br> <br></br>
          <span style={{ opacity: 0.5 }}>
            Where do you find depth?
            <br></br>
            <span style={{ opacity: 0.6 }}>1/25/24 Prompt</span>
          </span>{" "}
          <br></br>
          <br></br>
          <span style={{ opacity: 0.5 }}>
            Calendars are society's clocks. What are <i>your</i> clocks?
            <br></br>
            <span style={{ opacity: 0.6 }}>1/5/24 Prompt</span>
          </span>{" "}
          <br></br>
          <br></br>
          <span style={{ opacity: 0.5 }}>
            Reflect on your traditions...<br></br>
            <span style={{ opacity: 0.6 }}>12/22/23 Prompt</span>
          </span>{" "}
          <br></br>
          <br></br>
          <span style={{ opacity: 0.5 }}>
            How would you get along with your eight-yr-old self?<br></br>
            <span style={{ opacity: 0.6 }}>12/15/23 Prompt</span>
          </span>{" "}
          <br></br>
          <br></br>
          <span style={{ opacity: 0.45 }}>
            What would you title this chapter in your life?<br></br>
            <span style={{ opacity: 0.6 }}>11/30/23 Prompt</span>
          </span>{" "}
          <br></br>
          <br></br>
          <span style={{ opacity: 0.4 }}>
            What are you grateful for?<br></br>
            <span style={{ opacity: 0.6 }}>11/23/23 Prompt</span>
          </span>{" "}
          <br></br>
          <br></br>
          <span style={{ opacity: 0.3 }}>
            What childhood memory strikes you today?<br></br>
            <span style={{ opacity: 0.6 }}>11/16/23 Prompt</span>
          </span>{" "}
          <br></br>
          <br></br>
          <span style={{ opacity: 0.2 }}>
            What do you dream for?<br></br>11/9/23 Prompt
          </span>{" "}
          <br></br>
          <br></br>
          <span style={{ opacity: 0.2 }}>
            What's a habit you'd like to change? <br></br>11/2/23 Prompt
          </span>{" "}
          <br></br>
          <br></br>
          <span style={{ opacity: 0.2 }}>
            How do you feel about where you live? <br></br>10/25/23 Prompt
          </span>{" "}
          <br></br>
          <br></br>
          <br></br>
          <div
            className={"question-mark " + (tutorialExpanded ? " expanded" : "pulse-slow")}
            onClick={() => setTutorialExpanded((x) => !x)}
            title="Learn more about how to use Plexus..."
          >
            ?
          </div>
          {tutorialExpanded ? (
            <>
              what voices is...
              <div
                style={{
                  position: "relative",
                  paddingBottom: "62.5%",
                  // height: 0,
                  width: "70vw",
                }}
              >
                <iframe
                  src="https://www.loom.com/embed/f6b74aa26c3d45d9864d985ea904f2ea?sid=682ebf94-8e46-4b4c-a349-d64bcabe1b4b"
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: " 100%",
                  }}
                ></iframe>
              </div>
              <br></br>
              davey & micah being silly...
              <div
                style={{
                  position: "relative",
                  paddingBottom: "62.5%",
                  width: "70vw",
                }}
              >
                <iframe
                  src="https://www.loom.com/embed/14668268875d442e99117c8bbe8551d2?sid=a1c8a7c8-e393-463a-9b0a-aea6918b8590"
                  frameBorder="0"
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: " 100%",
                  }}
                ></iframe>
              </div>
              <br></br>
              <br></br>
              <br></br>
              <div
                className={"question-mark " + (whatsappInviteExpanded ? " expanded" : "pulse-slow")}
                onClick={() => setWhatappInviteExpanded((x) => !x)}
                title="Join our secret community group chat, for dedicated souls who've made it this far"
              >
                Settings?
              </div>
              {whatsappInviteExpanded ? (
                <>
                  <WhatsAppInvite />
                  <button
                    className="slow-spinner"
                    style={buttonStyle}
                    onClick={() => {
                      if (window.confirm("log out of " + person.email + "?")) {
                        auth
                          .signOut()
                          .then(() => console.log(person.uid, person.email, "signed out"))
                          .catch(() => console.warn(person.uid, person.email, "error signing out"))
                        window.location.reload()
                      }
                    }}
                  >
                    log out of <u>{person?.email}</u>
                  </button>
                  <button
                    className="slow-spinner"
                    style={buttonStyle}
                    onClick={() => {
                      window.alert(
                        `Plexus is best as an iPhone app! Here's four steps to download it--`
                      )
                      window.alert(
                        `1. Open Safari on your iPhone. \n2. Go to plexus.earth\n3. Click the Safari share icon (arrow pointing up)\n 4. Click "Add to Home Screen"\n\nThat's it.`
                      )
                    }}
                  >
                    Get iPhone App
                  </button>
                  <button
                    className="slow-spinner"
                    style={buttonStyle}
                    onClick={() => {
                      const newNameX = window.prompt(
                        "What would you like your new display name to be?",
                        personBucket?.personName
                      )
                      if (newNameX) {
                        simpleBackendWriter
                          .setName(newNameX)
                          .then(() => {
                            window.alert(
                              "On all your future steps, " +
                                newNameX +
                                " will be your new display name. Past steps will still have the same name."
                            )
                          })
                          .catch((e) => window.alert("An error! Send this to Davey... " + e))
                      }
                    }}
                  >
                    Change display name (Currently "{personBucket?.personName}")
                  </button>
                  <a className="slow-spinner" style={buttonStyle} href="plexus.earth/landing">
                    go to plexus landing page
                  </a>
                </>
              ) : (
                <></>
              )}
            </>
          ) : (
            <></>
          )}
        </div>
      ) : (
        <></>
      )} */}
    </div>
  )
}

export default AudioRecorder

export const WhatsAppInvite = () => {
  const inviteLink = "https://chat.whatsapp.com/LEFHwWzaSpvEmcSla35rhd" // replace with your invite link

  return (
    <a
      href={inviteLink}
      target="_blank"
      rel="noopener noreferrer"
      style={buttonStyle}
      className="slow-spinner"
    >
      join secret whatsapp group
    </a>
  )
}

export const buttonStyle: CSSProperties = {
  display: "inline-block",
  fontFamily: "copperplate",
  backgroundColor: "black",
  color: "white",
  padding: "5pt",
  margin: "2ex",
  textDecoration: "none",
  borderRadius: "5px",
  fontSize: ".85em",
  fontWeight: "bold",
  textAlign: "center",
}
