import { User } from "firebase/auth"
import { Navigate } from "react-router-dom"
import { backendWriter } from "../App"
import "./PrivateRoute.css"
import SimpleApp from "../SimplePlexusComponents/SimpleApp"

const usernameLocationLocal = "plexus-anon-name" // "place-username"
export let usernamex = localStorage.getItem(usernameLocationLocal) ?? ""

export const runOnboardingMessages = () => {
  while (!usernamex) {
    usernamex = window.prompt("What should we call you? (We recommend your favorite emoji.)") ?? ""
  }
  localStorage.setItem(usernameLocationLocal, usernamex)
  backendWriter.setName(usernamex)
  return usernamex
}

/**
 * A page that redirects to landing page if not logged in, but enters plexus app if person is logged in
 * @param param0
 * @returns
 */
const PrivateRoute = ({ person }: { person?: User }) => {
  //when is oriented is defined, run firebase function then delete it
  // switching out <FirebaseDataContainer /> for a new component

  return person ? <SimpleApp /> : <Navigate to="/" />
}

export default PrivateRoute
