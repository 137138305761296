import { ArrowLeft, House } from "@phosphor-icons/react"
import { getDatabase, onValue, ref } from "firebase/database"
import { useContext, useEffect, useState } from "react"
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom"
import { ConnectionKind, TextPost } from "../../../ReactContexts/PostContext"
import ExpandablePost from "../../SimplePlexusComponents/PostList/ExpandablePost"
import { simpleBackendWriter } from "../../SimplePlexusComponents/SimpleApp"
import "./PublicThoughtPage.css"
import AuthContext from "../../../ReactContexts/AuthContext"
import drEvil from "../../../assets/drEvil.gif"
import logo from "../../../assets/plexusAnimateClosing.gif"

import FirebaseWriter from "../../../Firebase/FirebaseWriter"
import { getFullEdgeMapArr } from "../../../Firebase/ReplyUtilities"
import UseParentThought from "../../CustomReactHooks/UseParentThought"
import Lineage from "./Lineage"
import usePostConfirmedDoesntExist from "../../CustomReactHooks/UsePostConfirmedDoesntExist"

//no simplepostcontext is accessible from here
const PublicThoughtPage = () => {
  const { thoughtId } = useParams()
  const { person } = useContext(AuthContext)
  const [thought, setThought] = useState<TextPost>()
  const navigate = useNavigate()
  const postConfirmedDoesntExist = usePostConfirmedDoesntExist(simpleBackendWriter, thoughtId)
  const parentThought = UseParentThought(thought)

  useEffect(() => {
    if (postConfirmedDoesntExist) {
      setTimeout(() => {
        navigate("/p")
      }, 5000)
    }
  }, [postConfirmedDoesntExist])
  //get the right thought
  useEffect(() => {
    //initialize simple backend writer
    const db = getDatabase()
    const dbRef = ref(db, "/p/forum/")

    const postRef = ref(db, `p/forum/nodes/${thoughtId}`)

    if (!thoughtId) return
    onValue(postRef, (snap) => {
      if (snap.exists()) {
        setThought(snap.val())
        document.title = snap.val()?.title ?? "" + " - Plexus"
      }
    })

    //get this person's bucket
    const personNameRef = ref(db, `p/forum/people/${person?.uid}/personName`)
    onValue(personNameRef, (snap) => {
      let newHackyPersonName
      if (snap.exists()) {
        newHackyPersonName = snap.val()

        //initialize simpleFirebaseWriter in here???
        //then initialize simpleBackendWriter
      }
      simpleBackendWriter.initialize(dbRef, person?.uid, person?.email, newHackyPersonName)
    })

    //then initialize simpleBackendWriter
  }, [thoughtId, person?.uid])

  if (postConfirmedDoesntExist)
    return (
      <div>
        The thought no longer exists :-((
        <br></br>
        <br></br>
        No one will ever again know its wonder.
        <br></br>
        <br></br>
        <img src={drEvil} style={{ width: "40vw", minWidth: "100pt", borderRadius: "10pt" }} />
        <br></br>
        <br></br>
        In a couple seconds, we'll bring you back to your <Link to="/p">plexus home</Link>
      </div>
    )
  else
    return (
      <div className="public-thought-page-container">
        <div
          style={{
            maxWidth: "500pt",
            width: "95%",
            height: "100%",
            padding: "1ex",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            className="simple-button-row-voices"
            style={{
              margin: "2ex",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              gap: ".5ex",
            }}
          >
            <ArrowLeft
              className="clickable-icon"
              size={24}
              // weight="fill"
              // onClick={() => {
              //   navigate(-1)
              // }}
              onClick={() => {
                navigate(`/p`)
              }}
            />
            {/* <House
              className="clickable-icon"
              size={28}
              onClick={() => {
                navigate(`/p`)
              }}
            /> */}
          </div>
          {parentThought ? (
            <Lineage {...{ rootPost: thought, post: parentThought, depth: 1 }} />
          ) : (
            <></>
          )}
          {thought?.text ? (
            <ExpandablePost
              {...{
                post: thought,
                id: thoughtId,
                text: thought.text,
                slateValue: thought.slateValue,
                timestamp: thought.timestamp,
                fullPage: true,
              }}
            />
          ) : (
            <></>
          )}
          <div>
            {thought?.text || postConfirmedDoesntExist ? (
              <></>
            ) : thoughtId ? (
              "Loading... "
            ) : (
              "No thought ID was provided in the URL!"
            )}
          </div>
        </div>

        {!person ? (
          <>
            <button
              className="audio-control-button-small"
              style={{
                display: "flex",
                flexDirection: "column",
                margin: "1em",
                zIndex: 20,
                fontSize: "12pt",
              }}
              onClick={() => {
                window.location.href = "https://plexus.earth/landing"
              }}
            >
              {" "}
              <img src={logo} style={{ width: "3em", marginTop: "-2em" }} />
              <br></br>
              <div>Join Plexus</div>
            </button>
          </>
        ) : (
          <></>
        )}
      </div>
    )
}

export default PublicThoughtPage

//Get the parent thought for a given post
export const getParentThought = async (
  post: TextPost
): Promise<TextPost | undefined> | undefined => {
  //get the parent id if it exists
  //first get all the outgoing reply connections from post
  const outgoingReplyConnections = getFullEdgeMapArr(
    post?.connections?.outbound ?? {},
    ConnectionKind.REPLY
  )
  const parentThoughtId = outgoingReplyConnections[0]?.targetThoughtId ?? undefined
  if (!parentThoughtId) return

  //then get the parent thought
  const parentThoughts = await FirebaseWriter.queryByIds(parentThoughtId)
  return parentThoughts ? parentThoughts[0] : undefined
}
