import { ReactNode, ButtonHTMLAttributes } from "react"
import "./Button.css"

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  isDeprioritised?: boolean
  isUnstyled?: boolean
  isSecondary?: boolean
  className?: string
  children: ReactNode
}

const Button = ({
  isDeprioritised = false,
  isUnstyled = false,
  isSecondary = false,
  className,
  children,
  ...props
}: ButtonProps) => {
  return (
    <button
      className={
        "button " +
        (isDeprioritised ? "button--deprioritised " : "") +
        (isUnstyled ? "button--unstyled " : "") +
        (isSecondary ? "button--secondary " : "") +
        (className || "")
      }
      {...props}
    >
      {children}
    </button>
  )
}

export default Button
