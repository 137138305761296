import {
  DotsThreeVertical,
  DownloadSimple,
  Link,
  Pause,
  PencilSimple,
  Play,
  Trash,
} from "@phosphor-icons/react"
import React, { useContext, useEffect, useRef, useState } from "react"
import { createEditor } from "slate"
import { withHistory } from "slate-history"
import { withReact } from "slate-react"
import "./FeedItem.css"
import { getTwitterLikeAbbreviation } from "../../../util"
import {
  ConnectionKind,
  SingleConnectionUpdateForAPerson,
  TextPost,
} from "../../../ReactContexts/PostContext"
import AuthContext from "../../../ReactContexts/AuthContext"
import { simpleBackendWriter } from "../SimpleApp"
import { NavigateFunction, useNavigate, useParams } from "react-router-dom"
import { useAudioPlayer } from "../AudioPlayerProvider"
import usePersonName from "../../CustomReactHooks/UsePersonName"
import usePostTitle from "../../CustomReactHooks/UsePostTitle"
import usePostConfirmedDoesntExist from "../../CustomReactHooks/UsePostConfirmedDoesntExist"
import useReplyThoughtIds from "../../CustomReactHooks/UseReplyThoughts"
import FirebaseWriter from "../../../Firebase/FirebaseWriter"
import usePostIsListened from "../../CustomReactHooks/usePostIsListened"
import Button from "../Button/Button"
import { frontendAppendPost, AppendsPost } from "../Create/Create"
import AudioRecorderButton from "../../Editor/AudioRecorderButton"
import { getFullEdgeMapArr } from "../../../Firebase/ReplyUtilities"

const MaxWordsToShow = 0

export const abbreviateByWords = (content: string, maxWords = MaxWordsToShow) => {
  if (maxWords == 0) return ""
  const words = content?.split(" ")
  if (words.length > maxWords) {
    return `${words.slice(0, maxWords).join(" ")}...`
  }
  return content
}
const FeedItem = ({
  id,
  text,
  expanded,
  alreadyConnected,
  timestamp,
  audioUrl,
  post,
  fromPost: fromPost,
  isBreadcrumb,
  updates,
  audioRef: audioRefProp,
  dontIncludeButtons,
}: {
  id: string
  text: string
  expanded?: boolean
  alreadyConnected?: boolean
  timestamp: number
  audioUrl?: string
  post?: TextPost
  fromPost?: TextPost
  isBreadcrumb?: true
  updates?: number
  audioRef?: React.RefObject<HTMLAudioElement>
  dontIncludeButtons?: true
}) => {
  const [isPlaying, setIsPlaying] = useState(false)
  const actionMenuRef = useRef(null)
  const { person } = useContext(AuthContext)
  const { placeId, thoughtId } = useParams()
  const { setListenedPosts, playAudio, currentlyPlayingId, setCurrentlyPlayingId } =
    useAudioPlayer()
  const defaultAudioRef = useRef<HTMLAudioElement>(null)
  const audioRef = audioRefProp || defaultAudioRef
  const [isExpanded, setIsExpanded] = useState(false)
  const [replyEmbeddingLoading, setReplyEmbeddingLoading] = useState(false)
  const [editor, _setEditor] = useState(() => withHistory(withReact(createEditor())))
  const navigate = useNavigate()
  const hasAudioURL = audioUrl ?? post?.audioUrl
  const { replyIds, setReplyIds } = useReplyThoughtIds(post)

  const isTruncatable = (content: string) => {
    if (!content) return
    const words = content.split(" ")
    return words.length > MaxWordsToShow
  }

  const setNewTitle = (newTitle: string) => {
    const trimmedTitle = newTitle.replace(/\s+$/, "")
    simpleBackendWriter.setSummaryTitle(trimmedTitle, post.id)
  }

  //Custom hooks
  const updatedAuthorName = usePersonName(simpleBackendWriter, post.authorId, post?.authorName)
  const updatedPostTitle = usePostTitle(simpleBackendWriter, post.id, post?.title)
  const postConfirmedDoesntExist = usePostConfirmedDoesntExist(simpleBackendWriter, post.id)

  const replyEdge = getFullEdgeMapArr(post?.connections?.outbound ?? {}, ConnectionKind.REPLY)
  const replyTargetAuthorId = replyEdge?.[0]?.targetAuthorId
  const replyAuthor = usePersonName(simpleBackendWriter, replyTargetAuthorId)

  //gets updated automatically
  const thoughtIsListened = usePostIsListened(
    post.id,
    person?.uid,
    simpleBackendWriter,
    post?.audioUrl,
    post?.authorId,
    post?.title
  )
  const listenedAlready = !post.audioUrl || thoughtIsListened || person?.uid === post?.authorId

  useEffect(() => {
    setIsPlaying(currentlyPlayingId === id)
  }, [currentlyPlayingId, id])

  useEffect(() => {
    setCurrentlyPlayingId(null)
  }, [thoughtId])

  useEffect(() => {
    const audio = audioRef.current
    if (audio) {
      audio.addEventListener("timeupdate", handleTimeUpdate)

      return () => {
        audio.pause()
        audio.removeEventListener("timeupdate", handleTimeUpdate)
      }
    }
  }, [])

  const stopAudio = () => {
    audioRef.current?.pause()
    setIsPlaying(false)
    if (currentlyPlayingId === id) {
      setCurrentlyPlayingId(null)
    }
  }

  const handleTimeUpdate = (event) => {
    const audio = audioRef.current
    if (audio && !listenedAlready) {
      const duration = audio.duration
      const currentTime = audio.currentTime
      const percentageListened = (currentTime / duration) * 100

      if (percentageListened >= 30) {
        if (person) {
          handleConnectionClick(event, fromPost, post, alreadyConnected, person?.uid).then(() => {
            setListenedPosts((prevListenedPosts) => {
              if (prevListenedPosts?.has(id)) return prevListenedPosts
              return new Set(prevListenedPosts).add(id)
            })
          })
          audio.removeEventListener("timeupdate", handleTimeUpdate)
        }
      }
    }
  }

  const toggleAudioPlay = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation()

    if (!audioRef.current) return
    if (isPlaying) {
      stopAudio()
    } else {
      if (audioRef.current.src !== audioUrl) {
        audioRef.current.src = audioUrl
      }
      playAudio(audioRef.current, id) // Pass the id to globally track this audio
    }
  }

  const handleAppendReply: AppendsPost = (transcription?: string, audioURL?: string) => {
    return frontendAppendPost(post, transcription, audioURL, setReplyEmbeddingLoading, editor).then(
      (newPost) => {
        setReplyIds((prev) => [...prev, newPost?.id])
        return newPost
      }
    )
  }

  const downloadAudio = () => {
    if (!post?.audioUrl) return

    fetch(post?.audioUrl)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement("a")
        a.href = url
        a.download = `plexus_${post?.id}.mp4`
        document.body.appendChild(a)
        a.click()
        window.URL.revokeObjectURL(url)
        document.body.removeChild(a)
      })
      .catch((error) => console.error("Error in downloading file:", error))
  }

  const content = text
  if (!content) return <></>
  const displayContent = isExpanded ? content : abbreviateByWords(content)?.trim()
  const contentIsTruncatable = isTruncatable(content)
  const numWords = content.split(" ").length + 1

  if (postConfirmedDoesntExist) return <span id={"post-doesnt-exist-" + post.id}></span>
  return (
    <div
      key={id + post.id + (fromPost?.id ?? "") + (thoughtId ?? "")}
      className={
        "textPost " +
        (expanded ? "expanded" : "") +
        (listenedAlready ? " listenedAlready " : "") +
        (isBreadcrumb ? " breadcrumb " : "") +
        (isPlaying ? " playing" : "")
      }
      onClick={(event) => {
        //record in local storage where we were before
        // otherPost is the previously focused one? what is otherPost?
        handlePostNavigationClick(event, navigate, simpleBackendWriter, post, fromPost)
      }}
    >
      <div className="thought-info-wrapper">
        <div className="thought-info">
          {updates > 0 ? (
            <div className="updates">
              <span className="updates-indicator"></span>
              {updates}
            </div>
          ) : null}

          {post?.authorName ? ( // Check if authorEmail is provided in the post object
            <div
              onClick={(event) => {
                event.stopPropagation()
                if (post?.authorId === person?.uid) {
                  const newName = window.prompt(
                    "Update display name for future posts?",
                    updatedAuthorName
                  )
                  if (newName) {
                    simpleBackendWriter.setName(newName).then(() => {
                      window.alert("Display name updated to " + newName + " for all future posts!")
                    })
                  }
                } else {
                  navigate(`/person/${post?.authorId}`)
                }
              }}
              className="thought-byline"
            >
              <span className="thought-author">{updatedAuthorName}</span>
              {post.isReply ? (
                <div className="thought-reply">
                  <span className="thought-reply-arrow">▸</span>
                  {replyAuthor}
                </div>
              ) : null}
            </div>
          ) : null}

          <div className="thought-timestamp">{getTwitterLikeAbbreviation(new Date(timestamp))}</div>

          <div className="more-actions" ref={actionMenuRef}>
            <Button
              className="more-actions-button"
              onClick={(event) => {
                event.stopPropagation()
                actionMenuRef.current?.classList.toggle("more-actions--expanded")
              }}
              isUnstyled={true}
            >
              <DotsThreeVertical size={18} />
            </Button>

            <div className="more-action-menu">
              {post?.authorId === person?.uid ? (
                <div
                  className="action-button"
                  onClick={(event) => {
                    event.stopPropagation()
                    // Call delete function
                    let response = window.confirm("Delete this post?")
                    if (response) {
                      simpleBackendWriter.deletePost(post, placeId)
                    }
                  }}
                >
                  <Trash className="action-button-icon" size={16} />
                  <span className="action-button-text">Delete</span>
                </div>
              ) : null}

              {person?.uid === post?.authorId ? (
                <div
                  className="action-button"
                  onClick={(e) => {
                    e.stopPropagation()
                    if (post?.authorId !== person?.uid) return
                    const theNewTitle = window.prompt(
                      "Title your thought something amazing.",
                      updatedPostTitle?.trim() ?? "untitled"
                    )
                    setNewTitle(theNewTitle)
                  }}
                >
                  <PencilSimple className="action-button-icon" size={16} />
                  <span className="action-button-text">Edit title</span>
                </div>
              ) : null}

              <div
                className="action-button"
                onClick={(event) => {
                  event.stopPropagation()
                  navigator.clipboard
                    .writeText(`https://plexus.earth/idea/${post.id}`)
                    .then(() =>
                      window.alert(
                        "Wooooo! Copied link to your clipboard.\n\nYou can send this to anyone, even if they're not on Plexus yet."
                      )
                    )
                }}
              >
                <Link className="action-button-icon" size={16} />
                <span className="action-button-text">Copy URL</span>
              </div>

              {person?.uid === post?.authorId ? (
                <div
                  className="action-button download-audio-button"
                  onClick={(event) => {
                    event.stopPropagation()
                    downloadAudio()
                  }}
                >
                  <DownloadSimple className="action-button-icon" size={16} />
                  <span className="action-button-text">Download</span>
                </div>
              ) : null}
            </div>
          </div>
        </div>

        <div className={"textpost-title" + (post.authorId !== person?.uid ? "" : " mine")}>
          {" "}
          {post.isReply && !post?.audioUrl
            ? ""
            : updatedPostTitle?.length > 1
            ? updatedPostTitle
            : abbreviateByWords(post.text, 5) ?? "Untitled"}
        </div>

        <div
          style={{ cursor: "pointer" }}
          className={"mini-truncated-display"}
          onClick={(event) => {
            event.stopPropagation()
            setIsExpanded((prev) => !prev)
          }}
        >
          {displayContent}
          <span>
            {contentIsTruncatable ? (
              isExpanded ? (
                ` [ - ]`
              ) : (
                <span style={{ textDecoration: "none", color: "rgb(200, 200, 200)" }}>
                  {`${numWords - MaxWordsToShow} words`}
                </span>
              )
            ) : null}
          </span>
        </div>

        {/* Hidden audio element */}
        {hasAudioURL && !audioRefProp && (
          <audio ref={audioRef} src={audioUrl} onEnded={stopAudio} />
        )}
      </div>

      {hasAudioURL && !dontIncludeButtons ? (
        <div className="button-column">
          {/* play button */}
          <Button
            className="play-button"
            key={post.id + "-playbutton"}
            onClick={(event) => {
              event.stopPropagation()
              toggleAudioPlay(event)
            }}
            isDeprioritised={listenedAlready}
          >
            {isPlaying ? (
              <Pause size={16} color="black" weight="fill" />
            ) : (
              <Play size={16} color="black" weight="fill" />
            )}
          </Button>

          {/* reply button */}
          {/* {person && (
            <AudioRecorderButton
              {...{
                appendPost: handleAppendReply,
                isReply: true,
                isSmall: true,
                isDeprioritised: !listenedAlready || !!replyIds?.length,
              }}
            />
          )} */}
        </div>
      ) : null}
    </div>
  )
}

export default FeedItem

/**
 * Whenever someone clicks on a post
 * @param event
 * @param navigate
 * @param backendWriter
 * @param toPost
 * @param fromPost
 */
const handlePostNavigationClick = (
  event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  navigate: NavigateFunction,
  backendWriter: FirebaseWriter,
  toPost: TextPost,
  fromPost?: TextPost
) => {
  //navigate to the post
  event.stopPropagation()
  console.log(navigate)
  navigate(`/idea/${toPost.id}`)
  console.log("setThoughtIdToExpand", toPost.id)

  //create a traversal edge
  if (fromPost) backendWriter.stepToExistingThought(toPost, fromPost, undefined, undefined)
}

export const handleConnectionClick = (
  e: React.MouseEvent, // Explicitly define the type of 'e'
  sourcePost: TextPost,
  targetPost: TextPost,
  alreadyConnected: boolean,
  edgeAuthorId?: string
) => {
  e.stopPropagation()
  // Ensures that the source and target posts are present

  //can draw another edge between two
  console.log(alreadyConnected)
  if (!targetPost) return
  //took away this condiiton--want to be able to draw edges that don't have a source
  //!sourcePost ||
  const connectionData: SingleConnectionUpdateForAPerson = {
    sourceId: sourcePost?.id ?? "fake-source-id-for-listen", //if no source provided, it's for a listen
    targetThoughtId: targetPost.id,
    targetAuthorId: targetPost.authorId,
    authorId: edgeAuthorId,
    edgeKind: ConnectionKind.CONNECTION,
    timestamp: Date.now(),
    edgeAuthorId: edgeAuthorId,
  }
  const connectionResult = simpleBackendWriter.addConnection(connectionData)
  return connectionResult.promise
}
