import { useContext, useEffect, useMemo, useState } from "react"
import "./People.css"
import SimpleAppPostContext from "../SimpleAppPostContext"
import Person from "./Person"
import { simpleBackendWriter } from "../SimpleApp"
import { AuthorInfo, FRIEND_STATUS } from "../../../Types/types"
import useConnectionMap from "./GetConnectionMap"

const People = () => {
  const { postsLoading, friendMap } = useContext(SimpleAppPostContext)
  const [people, setPeople] = useState<Record<string, AuthorInfo>>()
  const { allMyPosts } = useContext(SimpleAppPostContext)
  const connectionMap = useConnectionMap(allMyPosts)
  const [viewFriends, setViewFriends] = useState(false)

  //record peak
  useEffect(() => {
    //after two seconds, peak friendmap every ten seconds
    setTimeout(() => simpleBackendWriter.recordFriendMapPeak(Date.now()), 1000)
  }, [])

  const peopleSorter = (a: [string, Set<string>], b: [string, Set<string>]) => {
    const aFriendStatus = friendMap?.[a[0]]?.status
    const bFriendStatus = friendMap?.[b[0]]?.status
    if (aFriendStatus === bFriendStatus) {
      return friendMap?.[b[0]]?.timestamp - friendMap?.[a[0]]?.timestamp
    }
    if (aFriendStatus === FRIEND_STATUS.INCOMING_REQUEST) return -1
    if (bFriendStatus === FRIEND_STATUS.INCOMING_REQUEST) return 1
  }

  // Sort by amount of connections between the 2 people
  const peopleIds = useMemo(() => {
    return Array.from(connectionMap ?? [])
      .sort(peopleSorter)
      .map((entry) => entry[0])
  }, [connectionMap, friendMap])

  const potentialFriendIds = useMemo(() => {
    return peopleIds.filter((personId) => friendMap?.[personId]?.status !== FRIEND_STATUS.FRIENDS)
  }, [peopleIds, friendMap])

  const friendIds = useMemo(() => {
    return peopleIds.filter((personId) => friendMap?.[personId]?.status === FRIEND_STATUS.FRIENDS)
  }, [peopleIds, friendMap])

  useEffect(() => {
    simpleBackendWriter?.getAuthorInfo(peopleIds).then((people) => setPeople(people))
  }, [peopleIds, simpleBackendWriter])

  return (
    <div className="people-container">
      <div className="people-sorter">
        <span
          style={{ textDecoration: !viewFriends ? "underline" : "none" }}
          onClick={() => setViewFriends(false)}
        >
          potential
        </span>
        <span>/</span>
        <span
          style={{ textDecoration: viewFriends ? "underline" : "none" }}
          onClick={() => setViewFriends(true)}
        >
          friends
        </span>
      </div>
      {peopleIds?.length > 0 ? (
        (viewFriends ? friendIds : potentialFriendIds).map((authorId) => {
          return (
            <Person
              key={authorId}
              friendId={authorId}
              friendInfo={people?.[authorId]}
              edges={connectionMap.get(authorId)}
              friendStatus={friendMap?.[authorId]}
            />
          )
        })
      ) : !postsLoading ? (
        <div>
          <p>start expressing yourself to connect with others...</p>
        </div>
      ) : (
        <div>loading people...</div>
      )}
    </div>
  )
}

export default People
