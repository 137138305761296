import { useState, useEffect, useContext, useMemo } from "react"
import { getFullEdgeMapArr } from "../../../Firebase/ReplyUtilities"
import AuthContext from "../../../ReactContexts/AuthContext"
import { TextPost, ConnectionKind, SingleConnectionUpdateForAPerson } from "../../../ReactContexts/PostContext"

function useConnectionMap(allMyPosts: TextPost[]) {
  const { person } = useContext(AuthContext)
  const postArr: TextPost[] = useMemo(() => allMyPosts ?? [], [allMyPosts])
  const [connectionMap, setConnectionMap] = useState<Map<string, Set<string>>>()

  useEffect(() => {
    const inboundEdges = []
    const outboundEdges = []
    
    postArr.forEach(post => {
      const inboundRelated = getFullEdgeMapArr(
        post?.connections?.inbound ?? {},
        ConnectionKind.RELATED
      )
      const outboundRelated = getFullEdgeMapArr(
        post?.connections?.outbound ?? {},
        ConnectionKind.RELATED
      )
      // We don't fetch inbound connections as they can be thirdbound
      const outboundConnections = getFullEdgeMapArr(
        post?.connections?.outbound ?? {},
        ConnectionKind.CONNECTION
      )
      
      inboundEdges.push(...inboundRelated)
      outboundEdges.push(...outboundRelated, ...outboundConnections)
    })

    const connectionMap = mapConnections(inboundEdges, outboundEdges)
    connectionMap.delete(person?.uid)
    setConnectionMap(connectionMap)
  }, [postArr])

  return connectionMap
}

export default useConnectionMap

/* Maps all connections by the other author of the edge.
 * Inbound edges is the authorId. Outbound edges is the targetAuthorId.
 * The value should a unique array of edges for each author.
 */
function mapConnections(
  inboundConnections: SingleConnectionUpdateForAPerson[],
  outboundConnections: SingleConnectionUpdateForAPerson[]
) {
  const connectionMap = new Map()
  inboundConnections.forEach(edge => {
    if (edge.sourceId === "fake-source-id-for-listen") return
    const authorId = edge.authorId
    const edges = connectionMap.get(authorId) || new Set()
    edges.add(edge.sourceId)
    connectionMap.set(authorId, edges)
  })
  outboundConnections.forEach(edge => {
    const authorId = edge.targetAuthorId
    const edges = connectionMap.get(authorId) || new Set()
    edges.add(edge.targetThoughtId)
    connectionMap.set(authorId, edges)
  })
  return connectionMap
}
