import { child, onValue } from "firebase/database"
import { useState, useEffect } from "react"
import FirebaseWriter from "../../Firebase/FirebaseWriter"
import { getFullEdgeMapArr } from "../../Firebase/ReplyUtilities"
import { ConnectionKind, DirectionalEdgeMap } from "../../ReactContexts/PostContext"
import { getEdgeAuthor } from "../../Logic/ConnectionLogic"

function usePostIsListened(
  postId,
  personId: string,
  backendWriter: FirebaseWriter,
  audioURL?: string,
  authorId?: string,
  postTitle?: string
) {
  const [isListened, setIsListened] = useState<boolean>(false)

  useEffect(() => {
    const dbRef = backendWriter?.databaseRef
    if (!dbRef) return
    const postRef = child(dbRef, `nodes/${postId}/connections/inbound`)

    onValue(postRef, (snapshot) => {
      if (snapshot.exists()) {
        const connections = snapshot.val()
        const newIsListened = postIsListenedTo(connections, personId, audioURL, authorId, postTitle)
        setIsListened(newIsListened)
      }
    })
  }, [postId]) // Empty dependency array means this effect runs once on mount and cleanup on unmount

  return isListened
}

export const postIsListenedTo = (
  inbound: DirectionalEdgeMap,
  personId: string,
  audioUrl: string | undefined,
  authorId: string,
  postTitle?: string
) => {
  if (postTitle?.toLocaleLowerCase()?.includes("raceless world")) debugger
  const listenEdge = getFullEdgeMapArr(inbound ?? {}, ConnectionKind.CONNECTION).find(
    (e) => getEdgeAuthor(e) === personId
  )

  const isListened = listenEdge ? true : false

  const isEffectivelyListened = isListened || !audioUrl || authorId === personId

  return isEffectivelyListened
}

export default usePostIsListened
