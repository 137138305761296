import { useContext, useMemo } from "react"
import usePersonName from "../../CustomReactHooks/UsePersonName"
import { simpleBackendWriter } from "../SimpleApp"
import "./Person.css"
import useTotalThoughts from "./GetTotalThoughts"
import Button from "../Button/Button"
import { Check, Plus, UserPlus, X } from "@phosphor-icons/react"
import AuthContext from "../../../ReactContexts/AuthContext"
import { AuthorInfo, FRIEND_STATUS, FriendStatusObject } from "../../../Types/types"
import { sendEmailFriendsConnected, sendFriendRequestEmail } from "../../../SendEmail"
import { useNavigate } from "react-router-dom"

const Person = ({
  friendId,
  friendInfo,
  edges,
  friendStatus,
}: {
  friendId: string
  friendInfo: AuthorInfo
  edges: Set<string>
  friendStatus: FriendStatusObject
}) => {
  const navigate = useNavigate()
  const { person } = useContext(AuthContext)
  const totalThoughts = useTotalThoughts(simpleBackendWriter, friendId)
  const totalThoughtsMemo = useMemo(() => totalThoughts, [totalThoughts])
  const edgesArray = useMemo(() => Array.from(edges), [edges])

  function personClickHandler(event) {
    event.stopPropagation()
    navigate(`/person/${friendId}`)
  }

  return (
    <div
      className={
        "person-container " +
        (friendStatus?.status === FRIEND_STATUS.FRIENDS ? "person-container--friends" : "")
      }
      onClick={personClickHandler}
    >
      <div className="person-name">{friendInfo?.personName}</div>
      {/* <div className="person-right-container"> */}
      <div className="person-thought-count">
        <span className="person-unlocked-thoughts">{edgesArray.length}</span>
        {/* {totalThoughts > 0 ? (
          <>
            /<span className="person-total-thoughts">{totalThoughtsMemo}</span>
          </>
        ) : (
          <></>
        )} */}
        Voice{edgesArray.length > 1 ? "s" : ""} unlocked
      </div>
      {friendStatus?.status === FRIEND_STATUS.INCOMING_REQUEST ? (
        <div className="person-requested-buttons">
          <Button
            onClick={(event) => {
              event.stopPropagation()
              simpleBackendWriter
                ?.setFriendMapRecord(person?.uid, friendId, FRIEND_STATUS.FRIENDS)
                .then(() => {
                  sendEmailFriendsConnected(friendInfo, {
                    personName: person?.displayName,
                    personEmail: person?.email,
                  })
                  window.alert(
                    "congrats, you two are friends. you’ll receive an email with each other’s contact info!"
                  )
                })
            }}
          >
            <Check size={20} color="green" />
          </Button>
          <Button
            onClick={(event) => {
              event.stopPropagation()
              simpleBackendWriter?.setFriendMapRecord(person?.uid, friendId, FRIEND_STATUS.REJECTED)
            }}
          >
            <X size={20} color="red" />
          </Button>
        </div>
      ) : (
        <Button
          onClick={(event) => {
            event.stopPropagation()
            simpleBackendWriter?.setFriendMapRecord(
              person?.uid,
              friendId,
              FRIEND_STATUS.OUTGOING_REQUEST
            )
            sendFriendRequestEmail(friendInfo, {
              personName: person?.displayName,
              personEmail: person?.email,
            })
          }}
          disabled={
            friendStatus?.status === FRIEND_STATUS.OUTGOING_REQUEST ||
            friendStatus?.status === FRIEND_STATUS.FRIENDS
          }
        >
          <UserPlus size={20} />
        </Button>
      )}
    </div>
    // </div>
  )
}

export default Person
