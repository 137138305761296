import {
  child,
  DatabaseReference,
  equalTo,
  get,
  getDatabase,
  orderByChild,
  push,
  query,
  ref,
  remove,
  set,
  update,
} from "firebase/database"
import { Descendant, Node } from "slate"
import {
  BidirectionalEdgeMap,
  ConnectionKind,
  ConnectionMap,
  DirectionalEdgeMap,
  PersonThoughtInteractionType,
  PostMap,
  SingleConnectionUpdateForAPerson,
  SinglePersonThoughtInteraction,
  TextPost,
  TextPostWithoutId,
  EdgeInfoWithConnectionData,
  AncestorThought,
  PlexusVersion,
} from "../ReactContexts/PostContext"
import tokenizer from "wink-tokenizer"
//@ts-ignore
import { stemmer } from "stemmer"
import { getOpenAiTextEmbedding } from "./OpenAi"
import { getReplies } from "../Components/AdminStuff/OldHackyAdminPanel/HackyAdmin"
import {
  backendGetSummarization,
  backendUpsertEmbedding,
  deleteThoughtById,
} from "./FirebaseFunctionPointers"
import { getSlateValueFromText } from "../Components/AdminStuff/components/sendServerData"
import { getReplyThoughtsFromParent } from "./ReplyUtilities"
import { sendEmailIfFirstReply } from "../SendEmail"
import { getEdgeAuthor } from "../Logic/ConnectionLogic"
import {
  AddConnectionResult,
  AuthorInfo,
  strippedPost,
  FRIEND_STATUS,
  FriendStatusObject,
} from "../Types/types"
import {
  ThoughtWalkStep,
  ThoughtWalkStepWithId,
} from "../Components/WalkAppContainer/WalkInterfaces"
import { abbreviate } from "../util"
import { getSuggestionsAndAddRelatedEdges } from "../Components/Feed/GetSuggestedThoughts"

const getTextFromChildren = (children: Descendant[]) => {
  return children.reduce((text: string, nextChild: Descendant) => {
    return text + " " + Node.string(nextChild)
  }, "")
}

class FirebaseWriter {
  // NOTE: made all of these optional, not 100% sure this doesn't break type checking downstream
  databaseRef: any
  personId?: string
  personEmail?: string
  personName?: string
  todaysPrompt?: string
  constructor(
    databaseRef?: any,
    personId?: string,
    personEmail?: string,
    personName?: string,
    prompt?: string
  ) {
    this.databaseRef = databaseRef
    this.personId = personId
    this.personEmail = personEmail
    this.personName = personName
    this.todaysPrompt = prompt
  }

  initialize(
    databaseRef: any,
    personId?: string,
    personEmail?: string,
    personName?: string,
    prompt?: string
  ) {
    this.databaseRef = databaseRef
    this.personId = personId
    this.personEmail = personEmail
    this.personName = personName
    this.todaysPrompt = prompt
    if (personName) this.recordPersonName(personName)
    if (personEmail) this.recordPersonEmail(personEmail)
  }

  setName(name: string): Promise<any> {
    if (name) {
      this.personName = name
      return this.recordPersonName(name)
    } else return new Promise(() => 10)
  }
  // Helper function for adding posts into firebase, "the meat of adding posts"
  addPostInternal(
    post: TextPostWithoutId,
    placeId: string,
    id?: string
  ): addPostInternalResult | undefined {
    const postListRef = child(this.databaseRef, "nodes")
    const newPostRef = id ? child(postListRef, id) : push(postListRef)
    const newId = newPostRef.key
    if (newId) {
      console.log(post)
      //add the embedding in there
      const textWithoutBreaks = post.text
        .split("\n")
        .filter((e) => e)
        .join("\n")

      const textForEmbedding = `${post.prompt ? post.prompt.replace("?", ".") + " " : ""}${
        post.text
      }`
        .split("\n")
        .filter((e) => e)
        .join("\n")
      const postWithId: TextPost = {
        ...post,
        id: newId,
        text: textWithoutBreaks,
      }

      const addPostPromise = set(newPostRef, postWithId)

      // Also updates thoughtCount with latest number of posted thoughts
      addPostPromise.then(() => {
        this.getTotalThoughts(postWithId.authorId).then((voiceThoughts) => {
          this.setThoughtCount(postWithId.authorId, voiceThoughts.length)
        })
      })

      const embeddingsPromise = addPostPromise
        .then(() => {
          //set the title
          if (!postWithId?.title && postWithId?.text) {
            // console.log("title creation  triggered")
            this.setAndCreateSummaryTitle(
              "Find a 2-5 word phrase from the following text excerpt that captures the gist of the excerpt. Make sure the phrase is unique - eg unique enough to identify this text excerpt in particular in a social network with 100,000 posts. Here's the excerpt: " +
                postWithId?.text +
                ".",
              postWithId?.id
            )
          }
          //set the embeddings
          return this.ensureEmbeddings(textForEmbedding, post.authorId, newId, placeId, postWithId)
        })
        .then(() => {
          return getSuggestionsAndAddRelatedEdges(postWithId)
        })

      return { postWithId, newPostRef, addPostPromise, embeddingsPromise: embeddingsPromise }
    }
  }

  // Function to be called on frontend directly for adding a post
  addPost(
    children: Descendant[],
    text: string,
    placeId: string,
    isReply?: true,
    lineage?: AncestorThought[],
    audioUrl?: string
  ): { postWithId?: TextPost; addPostPromise?: Promise<any>; embeddingsPromise?: Promise<any> } {
    const personId: string = this.personId
    const textWithoutBreaks = text
      .split("\n")
      .filter((e) => e)
      .join("\n")
    debugger
    const newPost: TextPost = makePost(
      personId,
      this.personName,
      this.personEmail,
      textWithoutBreaks,
      Date.now(),
      undefined,
      children,
      undefined,
      isReply,
      lineage,
      audioUrl
    ) as TextPost
    const result = this.addPostInternal(newPost, placeId)

    if (result)
      return {
        postWithId: result.postWithId,
        addPostPromise: result.addPostPromise,
        embeddingsPromise: result.embeddingsPromise,
      }
    else return
  }

  async ensureEmbeddings(
    text: string,
    authId: string,
    thoughtId: string,
    placeId: string,
    postWithId: TextPost
  ): Promise<any> {
    this.personId = this.personId ? this.personId : authId
    if (!this.personId) throw new Error("No personId available")
    // console.log("assessing title")
    // Helper function to delay the retry
    const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms))

    const maxAttempts = 3
    let attempt = 0

    while (attempt < maxAttempts) {
      try {
        // Attempt to fetch the embedding
        const response = await getOpenAiTextEmbedding(text)
        const vector = response ? response : undefined

        if (!vector) {
          throw new Error("Received no valid vector from the embedding response.")
        }

        let strippedPost: strippedPost = {
          id: postWithId.id,
          authorName: postWithId.authorName,
          authorId: postWithId.authorId,
          authorEmail: postWithId.authorEmail,
          text: postWithId.text,
          timestamp: postWithId.timestamp,
        }

        if (postWithId.audioUrl) {
          strippedPost.audioUrl = postWithId.audioUrl
        }

        // Attempt to upsert the embedding
        const upsertResponse = await backendUpsertEmbedding(
          thoughtId,
          vector,
          placeId,
          strippedPost
        )
        if (!upsertResponse) {
          throw new Error()
        }

        // If successful, return the vector or any other appropriate data
        return vector
      } catch (error) {
        // Log the error (optional)
        console.error(`Attempt ${attempt + 1} failed`, error)

        // If it's the last attempt, throw the error
        if (attempt + 1 === maxAttempts) {
          throw error
        }

        // Increment the attempt
        attempt++
        await delay(500) // Delay half sec before retrying
      }
    }
  }

  /**
   * Function to add a reply under a given parent thought
   * @param text text of the reply to add
   * @param parentThoughtId id of the thought under which to add the reply
   * @param children slate editor value, if provided, the point is to include line breaks (hard to store in text), otherwise generated
   * @returns
   */
  addReplyThought(
    text: string,
    parentThought: TextPost, //if this is provided
    placeId: string,
    children?: Descendant[],
    audioUrl?: string
  ): { addPostPromise: Promise<any>; newReplyThought: TextPost; embeddingsPromise: Promise<any> } {
    const childrenToUse: Descendant[] = children ?? getSlateValueFromText(text)

    //add the new reply thought, with isReply tag = true
    const newLineageNode = [
      { id: parentThought?.id, textPreview: abbreviate(parentThought?.text, 30) },
    ]

    //get the lineage for this post
    const lineage: AncestorThought[] = parentThought
      ? parentThought.isReply
        ? parentThought.lineage
          ? [...parentThought.lineage, ...newLineageNode]
          : undefined
        : newLineageNode
      : []
    const { addPostPromise, postWithId, embeddingsPromise } = this.addPost(
      childrenToUse,
      text,
      placeId,
      true,
      lineage,
      audioUrl
    )

    //add the new reply edge
    addPostPromise.then(() => {
      const replyEdgePromise = this.addReplyConnection(
        parentThought.id,
        postWithId.id,
        parentThought.authorId,
        postWithId.authorId
      ).promise.then(() => {
        //see if should send first reply email
        sendEmailIfFirstReply(parentThought, postWithId)
        //add a connection
      })

      return Promise.all([replyEdgePromise])
    })

    return { addPostPromise, newReplyThought: postWithId, embeddingsPromise: embeddingsPromise }
  }

  /**
   * Adds an edge between two posts to indicate its a replly. From reply to parent, to stay consistent with direction of the notification
   * @param parentThoughtId id of the root thought, to which the thought is a reply
   * @param replyThoughtId id of the reply thought, which is being replied to the root thought
   * @param parentAuthorId author id of the root thought
   * @param replyAuthorId author id of the reply thought
   */
  addReplyConnection(
    parentThoughtId: string,
    replyThoughtId: string,
    parentAuthorId: string,
    replyAuthorId: string
  ) {
    const connectionData: SingleConnectionUpdateForAPerson = {
      sourceId: replyThoughtId,
      targetThoughtId: parentThoughtId,
      targetAuthorId: parentAuthorId,
      authorId: replyAuthorId,
      edgeKind: ConnectionKind.REPLY,
      timestamp: Date.now(),
    }
    return this.addConnection(connectionData)
  }

  /**
   * Generic function for adding a new edge
   *  Worth noting! Connections added here can be of any type, including "CONNECT" and "REPLY"
   * @param connectionObject
   * @returns
   */
  addConnection(connectionObject: SingleConnectionUpdateForAPerson): AddConnectionResult {
    const sourceThoughtId = connectionObject.sourceId
    const targetThoughtId = connectionObject.targetThoughtId
    const linkKeyName = "connections"

    const postListRef = child(this.databaseRef, "nodes")

    const firstConnectionParentRef = child(
      postListRef,
      `${sourceThoughtId}/${linkKeyName}/outbound/${targetThoughtId}`
    )

    const secondConnectionParentRef = child(
      postListRef,
      `${targetThoughtId}/${linkKeyName}/inbound/${sourceThoughtId}`
    )
    //get the reply edge id
    const firstConnectionRef = push(firstConnectionParentRef)
    const secondConnectionRef = child(secondConnectionParentRef, firstConnectionRef.key)

    //actually add
    const link1Promise = set(firstConnectionRef, connectionObject).catch((e) => console.warn(e))

    //other way

    const link2Promise = set(secondConnectionRef, connectionObject).catch((e) => console.warn(e))

    //then also, every time a connection is made, record that connection in an index for the person who was connected to, and probably also an outgoing index

    this.recordConnectionForPartiesInvolved({
      ...connectionObject,
    })

    return {
      promise: Promise.all([link1Promise, link2Promise]).catch((e) => console.warn(e)),
      edgeId: firstConnectionRef.key,
    }
  }

  /**
   * Record a connection made between two people's thoughts, in both people's firebase buckets
   * This is weird, because the source thought of a reply connection is the parent thought, but the source author of a connection is the child
   * @param sourceThoughtId
   * @param targetThoughtId
   * @param sourceAuthorId
   * @param targetAuthorId
   * @param connectionKind
   */
  recordConnectionForPartiesInvolved(
    connectionUpdate: SingleConnectionUpdateForAPerson
  ): Promise<any> {
    //get poeple location in firebase
    const peopleLocation = child(this.databaseRef, "people/")

    //1. record for sourceThoughtAuthor)
    const sourceThoughtAuthorRef = child(
      peopleLocation,
      connectionUpdate.authorId + "/connections/outbound"
    )
    const sourceAuthorBucketUpdate = push(sourceThoughtAuthorRef, connectionUpdate)
    const connectionUpdateKey = sourceAuthorBucketUpdate.key

    //2. then record for TargetThoughtAuthor
    //use the same key as above
    const targetThoughtAuthorRef = child(
      peopleLocation,
      connectionUpdate.targetAuthorId + "/connections/inbound"
    )
    const targetAuthorBucketUpdate = set(
      child(targetThoughtAuthorRef, connectionUpdateKey),
      connectionUpdate
    )

    //3. then, if sourceThoughtAuthor is different than the edge author id, record it in thirdbound for the edge author
    //QUESTION: should we keep this condition? or just record regardless always?
    //  for now: I think only if different is more efficient for now.
    if (getEdgeAuthor(connectionUpdate) !== connectionUpdate.authorId) {
      const edgeAuthorRef = child(
        peopleLocation,
        getEdgeAuthor(connectionUpdate) + "/connections/thirdbound"
      )
      set(child(edgeAuthorRef, connectionUpdateKey), connectionUpdate)
      //
    }

    //don't include conditional edge author promise, for now
    return Promise.all([sourceAuthorBucketUpdate, targetAuthorBucketUpdate])
  }

  /**
   * Deletes all directed connectionsbetween two thoughts, no matter the type
   *  only deletes outbound edges from source to target, keeps stuff from target to source if there
   * and doesn't delete from author buckets, only from thought buckets
   * @param sourceThoughtId
   * @param targetThoughtId
   */
  deleteConnection(sourceThoughtId: string, targetThoughtId: string) {
    const postListRef = child(this.databaseRef, "nodes")

    //Get references to right spots in the db
    const firstConnectionRef = child(
      postListRef,
      `${sourceThoughtId}/connections/outbound/${targetThoughtId}`
    )

    //other way
    const secondConnectionRef = child(
      postListRef,
      `${targetThoughtId}/connections/inbound/${sourceThoughtId}`
    )

    //Remove edge info from both locations
    const link1Promise = remove(firstConnectionRef)
      .then(() =>
        console.log(
          "successfully removed firebase connection (in first direction) from " +
            sourceThoughtId +
            " to " +
            targetThoughtId
        )
      )
      .catch((e) => console.warn(e))
    const link2Promise = remove(secondConnectionRef)
      .then(() =>
        console.log(
          "removed firebase connection (in second direction) from " +
            sourceThoughtId +
            " to " +
            targetThoughtId
        )
      )
      .catch((e) => console.warn(e))

    return Promise.all([link1Promise, link2Promise]).catch((e) => console.warn(e))
  }

  //this is outdated, only for replies and previous links
  deleteLink(id1: string, id2: string, anti: boolean = false) {
    const linkKeyName = anti ? "antiLinks" : "links"

    const postListRef = child(this.databaseRef, "nodes")
    const newLinkRef = child(postListRef, id1 + "/" + linkKeyName + "/" + id2)
    set(newLinkRef, null)
      .then(() => console.log("success link1"))
      .catch((e) => console.warn(e))

    //other way
    const secondLinkRef = child(postListRef, id2 + "/" + linkKeyName + "/" + id1)
    set(secondLinkRef, null)
      .then(() => console.log("success link2"))
      .catch((e) => console.warn(e))
  }
  /**
   * Delete a post and all its replies
   * @param post
   */
  deletePostAndReplyThoughts(post: TextPost, posts: PostMap, placeId: string) {
    //save the connections first, before any deletion stuff happens
    //this was the old reply type, almost totally defunct. can delete this logic soon.
    //this was the way connections were implemented in August 2022
    const oldReplies = getReplies(post)
      .map((e) => (e[0] in posts ? posts[e[0]] : undefined))
      .filter((e) => e)

    //get the new replies
    //THIS NEEDS TESTING
    const newReplies = getReplyThoughtsFromParent(post, posts)

    //delete this one and all its connections
    this.deletePost(post, placeId)

    //delete all reply thoughts--delete these ones recursively!
    oldReplies.forEach((replyPost) => this.deletePostAndReplyThoughts(replyPost, posts, placeId))
    newReplies.forEach((replyPost) => this.deletePostAndReplyThoughts(replyPost, posts, placeId))

    // delete all other kinds of edges, non recursively (connections) in the delete post function
  }

  deletePost(post: TextPost, placeId: string) {
    //delete from post list
    const postListRef = child(this.databaseRef, "nodes")
    const newPostRef = child(postListRef, post.id)
    set(newPostRef, null).then(() => {
      // After removing, update the thought count
      this.getTotalThoughts(post.authorId).then((voiceThoughts) => {
        this.setThoughtCount(post.authorId, voiceThoughts.length)
      })
    })

    //delete all Edges

    //delete obsolete links (obsolete, used for replies)
    const links = post.links ? Object.keys(post.links) : []
    links.forEach((linkId) => {
      this.deleteLink(linkId, post.id)
    })

    //delete almost-obsolete kind of connections (edgeList connections)
    const edgeList = post?.edgeList ?? {}
    if ("edgeList" in post) {
      for (let key of Object.keys(edgeList)) {
        this.deleteInboundAndOutboundEdgesOfThought(post.id, key)
      }
    }

    //delete the in-use links for new replies
    this.deleteAllEdgesForDefunctThought(post)

    //OBSOLETE: delete each word from the dictionary
    const updates: { [word: string]: null } = {}
    if (post.slateValue) {
      const words = tokenizeSentence(getTextFromChildren(post.slateValue))
      words.forEach((word) => {
        //delete from dictionary
        updates[`${word}/${post.id}`] = null
      })
    }
    const dictionaryRef = child(this.databaseRef, "dictionary")
    update(dictionaryRef, updates)

    //delete embeddings for pinecone
    deleteThoughtById([post.id], placeId)
  }

  /**
   * Deletes all Connection edges between this post and all others
   * @param post
   */
  deleteAllEdgesForDefunctThought(post: TextPost) {
    const connections: BidirectionalEdgeMap = post.connections

    if (connections) {
      Object.values(connections).forEach((oneDirectionEdges: DirectionalEdgeMap) => {
        //delete all the edges for this direction
        Object.entries(oneDirectionEdges).forEach(
          ([otherThoughtId, _]: [string, ConnectionMap]) => {
            //for each of the thought pair edges, delete em
            //delete all edges between these two thoughts
            //delete in both directions, will be redundant but whatever
            this.deleteConnection(post.id, otherThoughtId)
            this.deleteConnection(otherThoughtId, post.id)
          }
        )
      })
    }
  }

  // Used for deleting inbound and outbound edges by passing in an id
  // This is a clean up function that is used for removing latent edges
  // After somebody deletes their thought.
  deleteInboundAndOutboundEdgesOfThought(deletedThoughtId: string, targetThoughtId: string) {
    // Getting the path of edgelist for the thought that we are NOT deleting so that we can delete the node that we ARE deleting
    const targetThoughtRef = child(
      this.databaseRef,
      `nodes/${targetThoughtId}/edgeList/${deletedThoughtId}`
    )
    // Setting to null deletes the node
    set(targetThoughtRef, null)
  }

  //for rabbit hole line version
  updateLastTraversedByAuthor(thoughtId: string, authorId: string) {
    const lastExpanded = child(this.databaseRef, "nodes/" + thoughtId + "/lastTraversed/")
    const authorData = {
      [authorId]: Date.now(),
    }
    //janky update: thoughts traversed this session
    thoughtIdsTraversedThisSession[thoughtId] = Date.now()
    update(lastExpanded, authorData)
  }

  //write to a people section, saying the person logged in
  markPersonAsOnboarded() {
    // if (!this.databaseRef) return
    const lastExpanded = child(
      this.databaseRef,
      "people/" + this.personId + "/orientation/enteredDoor"
    )
    set(lastExpanded, Date.now())

    //also set their email + password
    if (this.personEmail && this.personName) {
      this.recordPersonName(this.personName)
      this.recordPersonEmail(this.personEmail)
    }
  }

  recordOnboardingStart() {
    if (!this.databaseRef || !this.personId) return
    // if (!this.databaseRef) return
    const lastExpanded = child(
      this.databaseRef,
      "people/" + this.personId + "/orientation/clickedWelcomePage"
    )
    set(lastExpanded, Date.now())
  }
  /**
   *
   */
  recordPersonName(name: string) {
    if (!(this.databaseRef && this.personId)) return
    const ref = child(this.databaseRef, "people/" + this.personId + "/personName/")
    return set(ref, name)
  }

  recordPersonEmail(email: string) {
    const ref = child(this.databaseRef, "people/" + this.personId + "/personEmail/")
    set(ref, email)
  }

  // To be called when people login to save their login timestamp
  recordNotifsPeak(timestamp: number) {
    const ref = child(
      this.databaseRef,
      "people/" + this.personId + "/timestamps/lastPeakedAllUpdates"
    )
    set(ref, timestamp)
  }

  recordFriendMapPeak(timestamp: number) {
    const ref = child(
      this.databaseRef,
      "people/" + this.personId + "/timestamps/lastPeakedFriendMap"
    )
    set(ref, timestamp)
  }

  /**
   * Record that this person has opened a given thought
   *  Initial purpose: keeping track of which thoughts someone has already 'seen' or not, for use in bolding
   * @param thoughtId
   */
  recordPersonThoughtInteraction(thoughtId: string, interactionType: PersonThoughtInteractionType) {
    //get the interaction object
    const interaction: SinglePersonThoughtInteraction = {
      timestamp: Date.now(),
      type: interactionType,
      thoughtId,
      personId: this.personId,
    }
    //record the interaction in two locations
    //in person firebase bucket
    const promise1 = this.recordPersonThoughtInteractionForPerson(interaction)
    //in thought firebase bucket

    const promise2 = this.recordPersonThoughtInteractionForThought(interaction)

    return Promise.all([promise1, promise2])
  }

  //TODO condense these two into a single function
  //part one of recording a person-thought interaction
  recordPersonThoughtInteractionForPerson(interaction: SinglePersonThoughtInteraction) {
    const ref = child(
      this.databaseRef,
      "people/" + interaction.personId + "/personThoughtInteractions/"
    )
    return push(ref, interaction)
  }

  //part one of recording a person-thought interaction
  recordPersonThoughtInteractionForThought(interaction: SinglePersonThoughtInteraction) {
    const ref = child(
      this.databaseRef,
      "nodes/" + interaction.thoughtId + "/personThoughtInteractions/"
    )
    return push(ref, interaction)
  }

  getTotalThoughts(personId: string) {
    const postListRef = child(this.databaseRef, "nodes")
    const thoughtsByAuthor = query(postListRef, orderByChild("authorId"), equalTo(personId))
    return get(thoughtsByAuthor).then((snapshot) => {
      if (snapshot.exists()) {
        const voiceThoughts = Object.values(snapshot.val()).filter(
          (thought: TextPost) => thought.audioUrl
        )
        return voiceThoughts
      }
    })
  }

  // Sets the number of total thoughts a person has posted
  setThoughtCount(personId: string, count: number) {
    const ref = child(this.databaseRef, "people/" + personId + "/thoughtCount")
    return set(ref, count)
  }

  // Updates the status on friendmap for two people
  setFriendMapRecord(sourceAuthorId: string, targetAuthorId: string, status: FRIEND_STATUS) {
    if (!this.databaseRef) return
    if (!sourceAuthorId || !targetAuthorId) return
    if (sourceAuthorId === targetAuthorId) return
    const newFriendInteraction: FriendStatusObject = {
      timestamp: Date.now(),
      status,
    }

    const targetFriendInteraction = {
      ...newFriendInteraction,
      status: status === FRIEND_STATUS.OUTGOING_REQUEST ? FRIEND_STATUS.INCOMING_REQUEST : status,
    }
    const friendMapRef = child(this.databaseRef, "friendmap")
    const sourceFriendMapRef = child(friendMapRef, `${sourceAuthorId}/${targetAuthorId}`)
    const targetFriendMapRef = child(friendMapRef, `${targetAuthorId}/${sourceAuthorId}`)

    const sourcePromise = set(sourceFriendMapRef, newFriendInteraction).catch((e) =>
      console.warn(e)
    )
    // We don't want to notify the other person that you've unlocked them
    const targetPromise =
      targetFriendInteraction.status !== FRIEND_STATUS.UNLOCKED
        ? set(targetFriendMapRef, targetFriendInteraction).catch((e) => console.warn(e))
        : Promise.resolve()
    return (
      Promise.all([sourcePromise, targetPromise])
        .catch((e) => console.warn(e))
        // Updates peak time to avoid being notified when accepting a friend request
        .then(() => this.recordFriendMapPeak(Date.now()))
    )
  }

  /*



  WALK WRITE LOGIC


  */

  async setAndCreateSummaryTitle(text: string, id: string) {
    console.log("Attempting to create title")
    const title = await backendGetSummarization(text)
    return this.setSummaryTitle(title, id)
  }

  setSummaryTitle(text: string, id: string) {
    return set(child(this.databaseRef, `/nodes/${id}/title/`), text)
  }

  //fn to add a new step for a given person / to a given walk
  //TODO
  /**
   * @param walk
   */

  /// this stays relevant
  //
  //
  //
  addStep(
    targetThoughtId: string,
    wasFirstStepInWalk: boolean,
    wasLastStepInWalk: boolean,
    previousThoughtId?: string,
    traversalEdgeId?: string,
    firstStepId?: string
  ) {
    //makec new step, then add it
    const step = this.makeThoughtWalkStep(
      targetThoughtId,
      previousThoughtId,
      traversalEdgeId,
      "step-" + firstStepId,
      wasFirstStepInWalk,
      wasLastStepInWalk
    )
    //add step to this person's walk array, now.
    const addStepResult = this.addStepToPersonSteps(step)

    return addStepResult
  }

  deleteStep(personId: string, walkStepId: string) {
    const newStepLoc = child(this.databaseRef, `/personSteps/${personId}/steps/${walkStepId}`)
    return set(newStepLoc, null)
  }

  /**
   * function to add a step to the step arr
   * also adds to thoughtStep array
   * @param targetThoughtId
   * @param previousThoughtId
   * @param traversalEdgeId
   * @param parentWalkId
   * @param wasFirstStepInWalk
   * @param wasLastStepInWalk
   * @returns
   */
  addStepToPersonSteps(
    step: ThoughtWalkStep,
    givenStepId?: string
  ):
    | undefined
    | {
        promise: Promise<any>
        newStep: ThoughtWalkStep
        thoughtPromise: Promise<any>
      } {
    const newStepLoc = push(child(this.databaseRef, `/personSteps/${this.personId}/steps`))
    const newThoughtStepLoc = push(
      child(this.databaseRef, `thoughtSteps/${step.targetThoughtId}/steps`)
    )
    const newStepId = givenStepId ?? newStepLoc.key
    const newStep: ThoughtWalkStepWithId = { ...step, walkStepId: newStepId }
    const promise = set(newStepLoc, newStep)
    const thoughtPromise = set(newThoughtStepLoc, newStep)
    return { promise: promise, newStep: newStep, thoughtPromise: thoughtPromise }
  }

  makeThoughtWalkStep(
    targetThoughtId: string,
    previousThoughtId: string,
    traversalEdgeId: string,
    parentWalkId: string,
    wasFirstStepInWalk: boolean,
    wasLastStepInWalk: boolean
  ): ThoughtWalkStep {
    const step: ThoughtWalkStep = {
      targetThoughtId,
      previousThoughtId: previousThoughtId ?? null, //for firebase, doesn't like undefined
      parentWalkId,
      traversalEdgeId: traversalEdgeId ?? null, //for firebase
      timestamp: Date.now(),
      stepperId: this.personId,
      wasFirstStepInWalk: wasFirstStepInWalk ?? null,
      wasLastStepInWalk: wasLastStepInWalk ?? null,
    }
    return step
  }

  // GROUP FILTER LOGIC
  addAuthorToCommunity(authorId: string, community: string) {
    console.log(authorId, community, this.databaseRef)
    const communityRef = child(this.databaseRef, `/communities/${community}/${authorId}`)
    set(communityRef, true)
  }

  //special case: functiont hat 's not writing. but it's the mothership
  /**
   * Queries the Firebase database for the objects with the specified IDs and returns their data.
   *
   * @param {string|string[]} ids - The ID(s) of the objects to query. Can be a single ID or an array of IDs.
   * @returns {Promise<TextPost[]>} - A Promise that resolves to an array of objects, where each object represents the data for an object in the database with the specified ID(s).
   * @throws {Error} - If the Firebase query encounters an error, the Promise is rejected with an Error object.
   */
  private static cache: Record<string, TextPost> = {}

  static addToCache(id: string, textPost: TextPost) {
    this.cache[id] = textPost
  }

  static getFromCache(id: string): TextPost | undefined {
    return this.cache[id]
  }

  static queryByIds = async (ids: string | string[], bypassCache = false): Promise<TextPost[]> => {
    ids = typeof ids === "string" ? [ids] : ids
    const db = getDatabase()
    const dbRef = ref(db, "p/forum")

    // Map over the ids and create a promise for each, either from cache or from Firebase.
    const dataPromises = ids.map((id) => {
      const cachedData = FirebaseWriter.getFromCache(id)
      if (!bypassCache && cachedData) {
        // If data is cached and we're not bypassing cache, resolve it immediately.
        return Promise.resolve(cachedData)
      } else {
        // Otherwise, fetch from Firebase.
        const nodeRef = child(dbRef, `nodes/${id}`)
        return get(nodeRef).then((snapshot) => {
          if (snapshot.exists()) {
            const data = snapshot.val() as TextPost
            FirebaseWriter.addToCache(id, data) // Update the cache.
            return data
          }
          return []
        })
      }
    })

    // Resolve all promises and filter out any undefined results.
    const objectsData = await Promise.all(dataPromises)
      .then((results) => results.filter((data): data is TextPost => data !== undefined))
      .catch((error) => {
        console.error(error)
        return []
      })

    return objectsData
  }

  getAuthorInfo = async (authorIds: string[] | string): Promise<{ [key: string]: AuthorInfo }> => {
    // Ensure we're working with an array
    const authorIdList = Array.isArray(authorIds) ? authorIds : [authorIds]
    const authorInfoPromises = authorIdList.map(async (authorId) => {
      const personNameRef = child(this.databaseRef, `people/${authorId}/personName`)
      const personEmailRef = child(this.databaseRef, `people/${authorId}/personEmail`)
      const [personNameSnapshot, personEmailSnapshot] = await Promise.all([
        get(personNameRef),
        get(personEmailRef),
      ])

      // Construct an object with authorId as key and AuthorInfo as value
      return {
        [authorId]: {
          personName: personNameSnapshot.val(),
          personEmail: personEmailSnapshot.val(),
        },
      }
    })

    // Wait for all promises to resolve and merge results into a single object
    const authorInfoList = await Promise.all(authorInfoPromises)
    return Object.assign({}, ...authorInfoList)
  }

  //total function for stepping to an existing thought
  /**
   * adds an existing thought (clicked) to the walk
   * 1. updates last traversed in thought
   * 2. adds traversal edge
   * 3. adds step to path
   */
  async stepToExistingThought(
    thought: TextPost,
    focusedThought: TextPost,
    wasFirstStepInWalk: boolean,
    firstStepId?: string
  ) {
    if (!thought || (thought.id === focusedThought?.id && !wasFirstStepInWalk)) return

    //store the click, relative to the current thought
    //this lastTraversed key probably isn't necessary anymore, but keep for now.
    this.updateLastTraversedByAuthor(thought.id, this.personId)
    let connectionResult: AddConnectionResult
    let connectionPromise: Promise<any> = Promise.resolve(true) //set to dummy thing by default
    let newConnection: EdgeInfoWithConnectionData
    if (focusedThought) {
      //then, record the connection
      newConnection = {
        sourceId: focusedThought.id,
        authorId: focusedThought.authorId,
        edgeAuthorId: this.personId,
        edgeKind: ConnectionKind.TRAVERSAL,
        targetAuthorId: thought.authorId,
        targetThoughtId: thought.id,
        timestamp: Date.now(),
        plexusVersion: PlexusVersion.VOICES,
      }
      connectionResult = this.addConnection(newConnection)
      connectionPromise = connectionResult.promise
    }
    //after connection is added if necessary, add step
    return connectionPromise.then(() => {
      // window.alert("connected " + focusedThought.title + " to " + thought.title)
      // use the function to do it from a thought
      const result = this.addStep(
        thought.id,
        wasFirstStepInWalk,
        false, //can never know it's the last step when you're adding it
        focusedThought?.id ?? undefined,
        connectionResult?.edgeId ?? undefined,
        firstStepId
      )
      return result
    })
  }

  isValidUID(uid: string): boolean {
    return !/[.#$\[\]]/.test(uid)
  }

  async getLastLogins(uids: string[]) {
    let lastLogins: Record<string, number> = {}
    // Filter out UIDs that contain invalid characters
    const validUIDs = uids.filter(this.isValidUID)
    await Promise.all(
      validUIDs.map(async (uid) => {
        let snapshot = await get(child(this?.databaseRef, `people/${uid}/timestamps`))
        if (snapshot.exists()) {
          lastLogins[uid] = snapshot.val()?.lastLogin
        } else {
          console.log(`No data available for this uid: ${uid}`)
        }
      })
    )
    return lastLogins
  }
}

export default FirebaseWriter

/**
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 */
///HELPER FUNCTIONS AND TYPES
//janky version for now
const tokenizeSentence = (sentence: string): string[] => {
  var myTokenizer = new tokenizer()
  const result = myTokenizer
    .tokenize(sentence)
    .filter((token) => token.tag === "word" && !token.value.includes("'"))
    .map(({ value }) => value)
    .map((word) => stemmer(word))
  return result
}

const makePost = (
  authorId: string,
  authorName: string,
  authorEmail: string,
  text: string,
  timestamp: number,
  id?: string,
  providedChildren?: Descendant[],
  prompt?: string,
  isReply?: true,
  lineage?: AncestorThought[],
  audioUrl?: string
): TextPostWithoutId | TextPost => {
  const children: Descendant[] =
    providedChildren ??
    text.split("\n").map((e) => ({ type: "paragraph", children: [{ type: "text", text: e }] }))
  const post: TextPostWithoutId | TextPost = {
    authorId: authorId,
    authorName,
    slateValue: children,
    text,
    timestamp,
    id,
    authorEmail,
    urlOfPageWhereAdded: window.location.href,
    audioUrl: audioUrl ?? null,
  }

  if (isReply) post.isReply = isReply
  if (prompt) post.prompt = prompt
  if (lineage) post.lineage = lineage
  return post
}

type addPostInternalResult = {
  postWithId: TextPost
  newPostRef: DatabaseReference
  addPostPromise: Promise<any>
  embeddingsPromise: Promise<any>
}

//very janky way of storing thought ids traversed this session only, because we don't fetch thoughts frequently enough to have the lastTraversed key update. this is compensation.
export let thoughtIdsTraversedThisSession: { [thoughtId: string]: number } = {}
//number is the timestamp
