import { Stop, ShareFat, Microphone, X } from "@phosphor-icons/react"
import { useState, useRef, useEffect } from "react"
import Button from "../SimplePlexusComponents/Button/Button"
import "./AudioRecorderButton.css"
import { AppendsPost } from "../SimplePlexusComponents/Create/Create"
import { useAudioPlayer } from "../SimplePlexusComponents/AudioPlayerProvider"

const AudioRecorderButton: React.FC<{
  appendPost: AppendsPost
  isReply?: boolean
  isSmall?: boolean
  isDeprioritised?: boolean
}> = ({ appendPost, isReply = false, isSmall = false, isDeprioritised = false }) => {
  const { stopAudio } = useAudioPlayer()
  const [isRecording, setIsRecording] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState(false)
  const MAX_RETRIES = 3
  const RETRY_DELAY = 3000
  const retryCount = useRef(0)
  const abortControllerRef = useRef(new AbortController())
  const mediaRecorderRef = useRef<MediaRecorder | null>(null)
  const wakeLockRef = useRef(null)
  const recordingTimeoutRef = useRef<number | null>(null) // For stopping the recording
  const bigSize = 64
  const smallSize = 16

  useEffect(() => {
    // Clear the timeouts when the component is unmounted to avoid memory leaks
    return () => {
      if (recordingTimeoutRef.current) clearTimeout(recordingTimeoutRef.current)
      if (wakeLockRef.current) {
        wakeLockRef.current.release()
        wakeLockRef.current = null
      }
    }
  }, [])

  const startRecording = async (ev) => {
    ev.stopPropagation()
    stopAudio()

    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true })
      mediaRecorderRef.current = new MediaRecorder(stream)
    } catch (err) {
      console.error("Error in recording:", err)
      throw err
    }
    const audioChunks: Blob[] = []

    mediaRecorderRef.current.ondataavailable = (event) => {
      audioChunks.push(event.data)
    }

    mediaRecorderRef.current.onstop = async () => {
      const audioBlob = new Blob(audioChunks, { type: "audio/mp4" })
      retryCount.current = 0 // Reset the retry count
      abortControllerRef.current = new AbortController()
      sendAudioForTranscription(audioBlob)
    }

    mediaRecorderRef.current.start()
    // Set up a timer for the recording limit (60 seconds)
    recordingTimeoutRef.current = window.setTimeout(() => {
      stopRecording()
    }, 24e4)
    try {
      // @ts-ignore
      if ("wakeLock" in navigator && navigator.wakeLock.request) {
        // @ts-ignore
        wakeLockRef.current = await navigator.wakeLock.request("screen")
        console.log("Wake Lock is active")
      }
    } catch (err) {
      console.error(`Could not obtain wake lock: ${err.message}`)
    }
    setIsRecording(true)
  }
  const cancelRecording = (ev) => {
    ev.stopPropagation()
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop()
      mediaRecorderRef.current = null // Discard the MediaRecorder instance
    }
    setIsLoading(false)
    abortControllerRef.current.abort() // Abort the fetch request
  }
  const stopRecording = (ev?) => {
    ev?.stopPropagation()
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop()
      setIsRecording(false)
      setIsLoading(true)

      // Clear the timeouts as we manually stopped the recording
      if (recordingTimeoutRef.current) clearTimeout(recordingTimeoutRef.current)

      mediaRecorderRef.current.stream.getTracks().forEach((track) => track.stop())
    }
    if (wakeLockRef.current) {
      wakeLockRef.current.release()
      wakeLockRef.current = null
      console.log("Wake Lock has been released")
    }
  }

  const sendAudioForTranscription = async (audioBlob: Blob) => {
    try {
      if (abortControllerRef.current.signal.aborted) {
        throw new Error("Request aborted")
      }
      const formData = new FormData()
      formData.append("audio", audioBlob)

      const response = await fetch(
        "https://us-central1-community-3ffbb.cloudfunctions.net/transcribeAudio-transcribeAudio/transcribeAudio",
        // "http://localhost:8081/transcribeAudio",
        {
          method: "POST",
          body: formData,
          signal: abortControllerRef.current.signal,
        }
      )

      if (!response.ok) {
        throw new Error("Network response was not ok: " + response.statusText)
      }

      const data = await response.json()
      appendPost(data.transcription, data?.audioUrl).then(() => setIsLoading(false))
    } catch (error) {
      if (error.name === "AbortError") {
        console.log("Fetch aborted")
      } else {
        console.error("Error in transcription:", error)

        if (retryCount.current < MAX_RETRIES) {
          // If there are retries left, try again after a delay
          setTimeout(() => {
            console.log(`Retrying... (${retryCount.current} of ${MAX_RETRIES})`)
            retryCount.current += 1
            sendAudioForTranscription(audioBlob)
          }, RETRY_DELAY)
        }
      }
    } finally {
      if (retryCount.current === MAX_RETRIES) {
        setIsLoading(false) // End loading only if there are no retries left
      }
    }
  }

  function getStatus() {
    if (isLoading) {
      return "sending"
    } else if (isRecording) {
      return "recording"
    } else {
      return "record"
    }
  }

  function getOnClickHandler() {
    const status = getStatus()
    switch (status) {
      case "sending":
        return cancelRecording
      case "recording":
        return stopRecording
      case "record":
        return startRecording
    }
  }

  function getTitle() {
    const status = getStatus()
    switch (status) {
      case "sending":
        return "cancel"
      case "recording":
        return "stop"
      case "record":
        return isReply
          ? "voice a reply"
          : "voice anything... maybe something you're feeling or thinking or working through"
    }
  }

  function getIcon() {
    const status = getStatus()
    switch (status) {
      case "sending":
        if (isSmall) {
          return <X size={smallSize} color="black" />
        } else {
          return <X size={bigSize} color="black" />
        }
      case "recording":
        if (isSmall) {
          return <Stop size={smallSize} color="red" weight="fill" className="pulse" />
        } else {
          return <Stop size={bigSize} color="black" weight="fill" className="pulse" />
        }
      case "record":
        if (isSmall) {
          return <ShareFat className="record-small" size={smallSize} color="black" weight="fill" />
        } else {
          return <Microphone size={bigSize} color="black" weight="fill" />
        }
    }
  }

  return (
    <>
      <Button
        isDeprioritised={isDeprioritised}
        className={
          "audio-recorder-button--" + getStatus() + (!isSmall ? " audio-recorder-button" : "")
        }
        onClick={getOnClickHandler()}
        title={getTitle()}
      >
        {getIcon()}
      </Button>
      {!(isRecording || isLoading || isSmall) ? (
        <div
          style={{
            textAlign: "center",
            cursor: "pointer",
            fontStyle: "italic",
          }}
          onClick={() => {
            window.open("https://www.loom.com/share/699b43381eab4cde855c185a11962d23", "_blank")
          }}
        >
          <div style={{ opacity: 1 }}>what do I feel?</div>
          <br></br>
          <div style={{ opacity: 0.4 }}>
            no need to plan<br></br> just tap the mic, <br></br>& start to speak!
          </div>
        </div>
      ) : (
        <></>
      )}

      {/* block out everything */}
      {isRecording && !isSmall ? (
        <div
          style={{
            position: "fixed",
            width: "100vw",
            height: "100vh",
            background: "white",
            zIndex: 1,
            top: 0,
            left: 0,
          }}
        ></div>
      ) : (
        <></>
      )}
    </>
  )
}

export default AudioRecorderButton
