import { useState, useEffect } from "react"
import { get, getDatabase, push, ref, set } from "firebase/database"

// Modify the DailyPrompt type definition to include 'id'
type DailyPrompt = {
  id: string
  date: number
  prompt: string
}

const getAllDailyPrompts = async (): Promise<DailyPrompt[]> => {
  const db = getDatabase()
  const promptsRef = ref(db, "/p/forum/dailyPrompts/")
  const snapshot = await get(promptsRef)
  if (snapshot.exists()) {
    const promptsObject = snapshot.val()
    const promptsArray: DailyPrompt[] = Object.keys(promptsObject).map((key) => ({
      id: key,
      ...promptsObject[key],
    }))
    return promptsArray
  } else {
    console.log("No daily prompts available.")
    return []
  }
}

const AdminDailyPrompts = () => {
  const [dailyPrompts, setDailyPrompts] = useState<DailyPrompt[]>([])
  const [loading, setLoading] = useState(true)
  const [newPrompt, setNewPrompt] = useState("")
  const [newDate, setNewDate] = useState("")
  const [autoFillDate, setAutoFillDate] = useState(false)
  const db = getDatabase()

  const updateDailyPrompt = async (id: string, newPrompt: string): Promise<void> => {
    const updatedPrompt = {
      ...dailyPrompts.find((p) => p.id === id),
      prompt: newPrompt,
    }
    const promptRef = ref(db, `/p/forum/dailyPrompts/${id}`)
    await set(promptRef, updatedPrompt)
  }

  const addDailyPrompt = async (date: number, prompt: string): Promise<any> => {
    // Create a new unique ID for this daily prompt
    const newPromptId = push(ref(db, "/p/forum/dailyPrompts/")).key
    // Check if key generation was successful
    if (!newPromptId) {
      console.log("Could not generate a unique key for the prompt.")
      return
    }
    // Create the object you want to save
    const dailyPromptData = {
      date: date,
      prompt: prompt,
    }
    const newPromptLoc = ref(db, `/p/forum/dailyPrompts/${newPromptId}`)
    const promise = set(newPromptLoc, dailyPromptData)
    return { promise: promise }
  }

  const deleteDailyPrompt = async (id: string): Promise<void> => {
    const promptRef = ref(db, `/p/forum/dailyPrompts/${id}`)
    await set(promptRef, null)
  }

  useEffect(() => {
    getAllDailyPrompts()
      .then((prompts) => {
        const sortedPrompts = prompts.sort((a, b) => b.date - a.date)
        setDailyPrompts(sortedPrompts)
        setLoading(false)
      })
      .catch((error) => {
        console.error("Error fetching daily prompts:", error)
        setLoading(false)
      })
  }, [])

  const handleSubmit = () => {
    const timestamp = Number(newDate) // Convert string to number

    if (isNaN(timestamp)) {
      console.error("Invalid timestamp")
      return
    }

    addDailyPrompt(timestamp, newPrompt)
      .then(() => {
        setNewPrompt("")
        setNewDate("") // Clear this if needed
        // Refresh the list of daily prompts
        return getAllDailyPrompts()
      })
      .then((prompts) => {
        const sortedPrompts = prompts.sort((a, b) => b.date - a.date)
        setDailyPrompts(sortedPrompts)
        if (autoFillDate && sortedPrompts.length > 0) {
          // Calculate the next day directly from sortedPrompts
          const mostRecentDate = sortedPrompts[0].date
          const nextDay = mostRecentDate + 86400000 // Add 24 hours (in milliseconds)
          setNewDate(nextDay.toString())
        }
      })
      .catch((error) => {
        console.error("Error adding daily prompt:", error)
      })
  }

  const handleAutoFill = () => {
    if (dailyPrompts.length > 0) {
      const mostRecentDate = dailyPrompts[0].date
      const nextDay = mostRecentDate + 86400000 // Add 24 hours (in milliseconds)
      setNewDate(nextDay.toString())
    }
  }

  const setToMorning = () => {
    const currentDate = new Date(Number(newDate))
    currentDate.setHours(6, 30, 0, 0)
    setNewDate(currentDate.getTime().toString())
  }

  if (loading) {
    return <p>Loading daily prompts...</p>
  }

  return (
    <div>
      <h1>Daily Prompts</h1>
      <h3>today's prompt: {getPromptToday(dailyPrompts)?.prompt}</h3>
      <div>
        <div>
          <input
            type="text"
            placeholder="New Prompt"
            value={newPrompt}
            onChange={(e) => setNewPrompt(e.target.value)}
            style={{ width: "80vh" }}
          />
        </div>
        <div>
          <input
            type="text"
            placeholder="Timestamp"
            value={newDate}
            onChange={(e) => setNewDate(e.target.value)}
          />
        </div>
        <button onClick={handleSubmit}>Add New Prompt</button>
        <br />
        <label>
          <input
            type="checkbox"
            checked={autoFillDate}
            onChange={(e) => {
              setAutoFillDate(e.target.checked)
              if (e.target.checked) {
                handleAutoFill()
              }
            }}
          />
          Auto-fill Date
        </label>
        <button onClick={setToMorning}>Set to 6:30 AM</button>
        <div>Human Readable Date: {new Date(Number(newDate)).toLocaleString()}</div>
      </div>
      <ul>
        {dailyPrompts.map((prompt, index) => (
          <li key={index} style={{ opacity: Date.now() > prompt.date ? ".5" : "1" }}>
            <strong>Date: </strong>
            {new Date(prompt.date).toLocaleDateString()}
            <br />
            <strong>Prompt: </strong>
            <span
              onClick={() => {
                const newPrompt = window.prompt("Edit the prompt:", prompt.prompt)
                if (newPrompt !== null) {
                  // Check if user clicked "Cancel"
                  updateDailyPrompt(prompt.id, newPrompt).then(() => {
                    // Update the UI here, e.g., by calling `getAllDailyPrompts` and setting `dailyPrompts`
                    getAllDailyPrompts().then((prompts) => {
                      const sortedPrompts = prompts.sort((a, b) => b.date - a.date) // Sorting
                      setDailyPrompts(sortedPrompts)
                    })
                  })
                }
              }}
            >
              {prompt.prompt}
            </span>
            <div style={{ marginBottom: "10px" }}>
              <button
                onClick={() =>
                  deleteDailyPrompt(prompt.id).then(() => {
                    getAllDailyPrompts().then((prompts) => {
                      const sortedPrompts = prompts.sort((a, b) => b.date - a.date) // Sorting
                      setDailyPrompts(sortedPrompts)
                    })
                  })
                }
              >
                Delete
              </button>
            </div>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default AdminDailyPrompts

const getPromptToday = (prompts: DailyPrompt[]): DailyPrompt | undefined => {
  const todayPrompts = (prompts ?? []).filter(isPromptFromToday)
  return todayPrompts.length > 0 ? todayPrompts[0] : undefined
}

const isPromptFromToday = (prompt: DailyPrompt) => {
  const todaysDate = getDateString(Date.now())
  return getDateString(prompt.date) === todaysDate
}

const getDateString = (timestamp: number): string => {
  const date = new Date(timestamp)
  const year = date.getFullYear()
  const month = date.getMonth() + 1 // JavaScript months are 0-11, so we add 1 to get 1-12
  const day = date.getDate()
  return `${month}-${day}-${year}`
}
