// const sgMail = require("@sendgrid/mail")
import emailjs from "@emailjs/browser"
import { logEvent } from "firebase/analytics"
import { analytics } from "./Components/App"
import { getAllReplies } from "./Components/Feed/checkIfMutual"
import { abbreviateByWords } from "./Components/SimplePlexusComponents/PostList/FeedItem"
import { thoughtIsReply } from "./Firebase/ReplyUtilities"
import { TextPost } from "./ReactContexts/PostContext"
import { AuthorInfo } from "./Types/types"

export const sendEmailIfFirstReply = (parentThought: TextPost, replyThought: TextPost) => {
  // Don't allow get notifications from yourself
  if (parentThought.authorId === replyThought.authorId) return

  //check that it's the first reply and that the parent thought is a root thought
  //send more emails, don't check for this condition anymore.
  if (getAllReplies(parentThought).length > 0 || thoughtIsReply(parentThought)) return

  //if it is the first reply, send email
  return sendFirstReplyEmail(
    parentThought.authorName,
    replyThought.authorName,
    parentThought.text,
    replyThought.text,
    parentThought.authorEmail,
    replyThought.authorEmail,
    parentThought.id,
    replyThought.id,
    parentThought.title,
    replyThought.title
  )
}

function sendFirstReplyEmail(
  toName: string = "anonymous",
  fromName: string = "anonymous",
  toThought: string = "text hidden",
  fromThought: string = "text hidden",
  toEmail: string,
  fromEmail: string = "forum",
  toIDthought: string,
  fromIDthought: string,
  toThoughtTitle: string,
  fromThoughtTitle
) {
  if (toEmail && fromEmail)
    emailjs.send(
      "default_service",
      // Need to change template!
      "template_icmpkas",
      {
        toName,
        fromName,
        toThought,
        fromThought,
        toEmail,
        fromEmail,
        toIDthought,
        fromIDthought,
        toThoughtTitle,
        fromThoughtTitle: fromThoughtTitle ?? abbreviateByWords(fromThought, 10),
      },
      "VFXAIIMaqEenjBrC9"
    )

  logEvent(analytics, "send-reply-email", {
    timestamp: Date.now(),
    nameA: toName,
    nameB: fromName,
    thoughtA: toThought,
    thoughtB: fromThought,
    emailA: toEmail,
    emailB: fromEmail,
    IDthoughtA: toIDthought,
    IDthoughtB: fromIDthought,
  })
}

export const sendEmailFriendsConnected = (person1: AuthorInfo, person2: AuthorInfo) => {
  if (!person1.personEmail || !person2.personEmail) return
  if (person1.personEmail === person2.personEmail) return

  emailjs.send(
    "default_service",
    "template_yz328ws",
    {
      nameA: person1.personName,
      nameB: person2.personName,
      emailA: person1.personEmail,
      emailB: person2.personEmail,
    },
    "VFXAIIMaqEenjBrC9"
  )

  logEvent(analytics, "send-friend-email", {
    timestamp: Date.now(),
    nameA: person1.personName,
    nameB: person2.personName,
    emailA: person1.personEmail,
    emailB: person2.personEmail,
  })
}

export const sendFriendRequestEmail = (toPerson: AuthorInfo, fromPerson: AuthorInfo) => {
  debugger
  if (!fromPerson.personEmail || !toPerson.personEmail) return
  if (fromPerson.personEmail === toPerson.personEmail) return

  emailjs
    .send(
      "default_service",
      "template_h253pz4",
      {
        fromName: fromPerson.personName,
        toName: toPerson.personName,
        fromEmail: fromPerson.personEmail,
        toEmail: toPerson.personEmail,
      },
      "VFXAIIMaqEenjBrC9"
    )
    .catch((e) => console.error(e))
    .then(() => console.log("successfully sent friend req email"))
}
