import { useContext, useEffect, useMemo, useState } from "react"
import FirebaseWriter from "../../../Firebase/FirebaseWriter"
import AuthContext from "../../../ReactContexts/AuthContext"
import {
  EdgeInfoWithUpdateListened,
  SingleConnectionUpdateForAPerson,
  TextPost,
} from "../../../ReactContexts/PostContext"
import SimpleAppPostContext from "../SimpleAppPostContext"
import FeedItem from "../PostList/FeedItem"
import { postIsListenedTo } from "../../CustomReactHooks/usePostIsListened"
import { User } from "firebase/auth"
import { useAudioPlayer } from "../AudioPlayerProvider"
import Button from "../Button/Button"
import { FunnelSimple, Microphone } from "@phosphor-icons/react"
import Create from "../Create/Create"
import "./PostList.css"

const STORAGE_ORDER_BY_UPDATES = "plexus-order-by-updates"

/**
 * This is where we query for the connections
 * @returns
 */
const PostList = () => {
  const { inboundEdges, allMyPosts, postsLoading, setAppView } = useContext(SimpleAppPostContext)
  const { listenedPosts } = useAudioPlayer()
  const postArr: TextPost[] = (allMyPosts ?? []).filter((e) => e.audioUrl.includes("."))
  const { person } = useContext(AuthContext)
  const [postsOrderedByActivity, setPostsOrderedByActivity] = useState<TextPost[]>([])
  const [incomingUpdates, setIncomingUpdates] = useState<EdgeInfoWithUpdateListened[]>([])
  const [allLatestUpdates, setAllLatestUpdates] = useState<
    Map<string, EdgeInfoWithUpdateListened[]>
  >(new Map())
  const [orderByUpdates, setOrderByUpdates] = useState<boolean>(false)

  // useEffect(() => {
  //   const orderByUpdates = JSON.parse(localStorage.getItem(STORAGE_ORDER_BY_UPDATES) ?? "false")
  //   setOrderByUpdates(orderByUpdates)
  // }, [])

  //causing lag on Davey's big account commenting out for now
  // useEffect(() => {
  //   const incomingReplies = Object.values(inboundEdges?.REPLY ?? {})
  //   const incomingRelated = Object.values(inboundEdges?.RELATED ?? {})

  //   const withoutSelfActions = [...incomingReplies, ...incomingRelated].filter(
  //     (e) => e?.edgeAuthorId !== person?.uid
  //   )

  //   const allIncoming = filterArrayToJustHaveUniqueItems(
  //     withoutSelfActions,
  //     (a, b) => a?.sourceId === b?.sourceId && a?.targetThoughtId === b?.targetThoughtId
  //   )

  //   // Create arrays of source thought IDs
  //   const sourceThoughtIds = allIncoming.map((edge) => edge.sourceId)

  //   // Filters out the target thoughts that are already in postArr to fetch them
  //   const missingTargetThoughtIds = allIncoming
  //     .map((edge) => edge.targetThoughtId)
  //     .filter((id) => !postArr.find((post) => post.id === id))

  //   const sourceThoughtsPromise = FirebaseWriter.queryByIds(sourceThoughtIds)
  //   const targetThoughtsPromise = FirebaseWriter.queryByIds(missingTargetThoughtIds)

  //   Promise.all([sourceThoughtsPromise, targetThoughtsPromise]).then(
  //     ([sourceThoughts, missingTargetThoughts]) => {
  //       // Some of the fetched missingTargetThoughts are empty arrays, so we filter them out
  //       missingTargetThoughts
  //         .filter((thought) => thought?.id)
  //         .forEach((thought) => {
  //           if (!postArr.find((post) => post.id === thought.id)) {
  //             postArr.push(thought)
  //           }
  //         })
  //       const allLatestUpdates = getLatestUpdatesPerThought(
  //         allIncoming,
  //         sourceThoughts,
  //         person,
  //         listenedPosts
  //       )
  //       setAllLatestUpdates(allLatestUpdates)
  //       const latestIncoming = getLatestUpdatePerThought(allLatestUpdates)

  //       const edgeTimestampComparator = (
  //         edgeA: EdgeInfoWithUpdateListened,
  //         edgeB: EdgeInfoWithUpdateListened
  //       ): number => (edgeB?.timestamp ?? 0) - (edgeA?.timestamp ?? 0)

  //       // Sort the edges by !isUpdateListened + timestamp, so the thought ids end up sorted by the most recent incoming update
  //       const sortedIncoming = latestIncoming.sort(edgeTimestampComparator)
  //       setIncomingUpdates(sortedIncoming)
  //       const targetThoughtIds = sortedIncoming.map((edge) => edge.targetThoughtId)

  //       // Sort all thoughts by the order in sortedIncoming, and leave as is if not found
  //       const sortedAllMyPosts = [...postArr].sort((a, b) => {
  //         const indexA = targetThoughtIds.indexOf(a.id)
  //         const indexB = targetThoughtIds.indexOf(b.id)

  //         if (indexA === -1) {
  //           return indexB === -1 ? 0 : 1
  //         } else {
  //           return indexB === -1 ? -1 : indexA - indexB
  //         }
  //       })
  //       setPostsOrderedByActivity(sortedAllMyPosts)
  //     }
  //   )
  // }, [inboundEdges?.RELATED, inboundEdges?.REPLY, listenedPosts])

  const pendingUpdates = useMemo(() => {
    return incomingUpdates.filter((update) => !update.isUpdateListened).length
  }, [incomingUpdates])

  return (
    <div className="post-list-container">
      <div className="post-list">
        {/* {!postsLoading && postArr.length > 0 ? (
          <Button
            onClick={() => {
              setOrderByUpdates(!orderByUpdates)
              localStorage.setItem(STORAGE_ORDER_BY_UPDATES, JSON.stringify(!orderByUpdates))
            }}
            title={
              !orderByUpdates
                ? "order by when thoughts were replied to"
                : "order by when thoughts received new activity"
            }
            isUnstyled={true}
            style={{
              width: "100%",
              alignSelf: "center",
              fontFamily: "monospace",
              color: "rgb(180,180,180)",
              margin: "2ex",
            }}
          >
            <FunnelSimple size={16} style={{ marginRight: "4pt" }} />
            <span style={{ fontWeight: !orderByUpdates ? "bold" : "normal" }}>created</span>
            <span>/</span>
            <span style={{ fontWeight: orderByUpdates ? "bold" : "normal" }}>
              activity {pendingUpdates > 0 ? ` (${pendingUpdates})` : ""}
            </span>
          </Button>
        ) : (
          <></>
        )} */}
        {(orderByUpdates ? postsOrderedByActivity : postArr).map((post) => (
          <FeedItem
            post={post as TextPost}
            key={post.id}
            id={post.id}
            timestamp={post.timestamp}
            dontIncludeButtons={true}
            updates={
              allLatestUpdates.get(post.id)?.filter((update) => !update.isUpdateListened).length
            }
            {...{ slateValue: post.slateValue, text: post.text, audioUrl: post.audioUrl }}
          />
        ))}
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
      </div>

      {postsLoading ? <div>loading...</div> : null}
      {!postsLoading && postArr.length == 0 ? (
        <div style={{ color: "gray", textAlign: "center", margin: "10%" }}>
          Here, you'll see Voices Playlists.
          <br></br>
          <br></br>A Voices Playlist is a mix of real people's Voices, compiled for you, based on
          Voices. You'll get a playlist every time you record a Voice of your own.
          <br></br>
          <br></br>A Voice can be anything — usually a question you care about. People are
          vulnerable, unpolished, spontaneous. Think: all the thoughts you'd never share on Twitter.
          <br></br>
          <br></br>
          To begin, tap <Microphone onClick={() => setAppView("write")} />.
        </div>
      ) : null}
    </div>
  )
}

export default PostList

export function filterArrayToJustHaveUniqueItems(array: any[], isEqual: Function) {
  return array.filter((value, index, self) => {
    return self.findIndex((item) => isEqual(item, value)) === index
  })
}

function getLatestUpdatesPerThought(
  incomingEdges: SingleConnectionUpdateForAPerson[],
  sourceThoughts: TextPost[],
  person: User,
  listenedPosts?: Set<string>
): Map<string, EdgeInfoWithUpdateListened[]> {
  const itemsMap = new Map()

  incomingEdges.forEach((edge) => {
    if (!edge || edge.authorId === person.uid) return

    const sourceThought = sourceThoughts.find((thought) => thought.id === edge.sourceId)
    if (!sourceThought || !sourceThought.audioUrl || sourceThought.authorId === person.uid) return

    const isUpdateListened =
      listenedPosts?.has(sourceThought.id) ||
      postIsListenedTo(
        sourceThought.connections?.inbound,
        person?.uid,
        sourceThought.audioUrl,
        sourceThought.authorId
      )
    const updateWithListened = { ...edge, isUpdateListened }

    const key = edge.targetThoughtId
    const existingItem = itemsMap.get(key)
    if (!existingItem) {
      itemsMap.set(key, [updateWithListened])
    } else if (!isUpdateListened) {
      const newUpdatesList = [...existingItem, updateWithListened].sort(
        (a, b) => (b.timestamp ?? 0) - (a.timestamp ?? 0)
      )
      itemsMap.set(key, newUpdatesList)
    }
  })

  return itemsMap
}

// For every possible targetThoughtId, find the lastest incomingEdge
// That's the most recent that hasn't been listened to checking with `postIsListenedTo` and if they have all been listened to, return the most recent one
function getLatestUpdatePerThought(
  allLatestUpdates: Map<string, EdgeInfoWithUpdateListened[]>
): EdgeInfoWithUpdateListened[] {
  const itemsMap = new Map()

  const myThoughtsIds = Array.from(allLatestUpdates.keys())
  myThoughtsIds.forEach((thoughtId) => {
    const updates = allLatestUpdates.get(thoughtId)
    const updatesOrdered = updates?.sort((a, b) => (b.timestamp ?? 0) - (a.timestamp ?? 0))
    itemsMap.set(thoughtId, updatesOrdered[0])
  })

  return Array.from(itemsMap.values())
}
